import ApiService from '../../../../services/api.service';

class CompanyService extends ApiService {
  name  = 'company';
  path  = 'master/company';
  constructor() {
    super();
    this.init()
  }

  api = {
    lookup: async () => {
      var res = await this.http.get(`${this.apiLocation}/master/company-look-up-all`)
      console.log(res)
      return {
        data: res
      }
    },
    sync: async () => {
      var res = await this.http.get(`${this.apiLocation}/master/company-sync`)
      return res;
    },
    import: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-import`, data);
      return res;
    },
    getPenomoran: async (data) => {
      let res = await this.http.get(`${this.apiLocation}/master/company-penomoran/${data.id}`, {
        params:{page: 1, sortBy: 'name', size: 90000000, sort: 1}
      });
      return res;
    },
    bulkUpdatePenomoran: async (companyId, data) => {
      let res = await this.http.post(`${this.apiLocation}/master/company-penomoran/${companyId}`, data);
      return res;
    },
    init:async (data) => {
      let res = await this.http.post(`${this.apiLocation}/master/company-init`, data);
      return res;
    },
    getLimitation:async () => {
      let res = await this.http.get(`${this.apiLocation}/dashboard/core-limitation`);
      return res;
    }
  }
}


export default new CompanyService();
