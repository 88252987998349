import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './Employee.dialog.view';
import EmployeeService from './Employee.service';
import ListView from '../../../../../components/entity/listView';
import DepartmentService from '../../Master/Department/Department.service';
import CompanyService from '../../../Admin/Company/Company.service';

@connect(EmployeeService.stateConnectSetting(), EmployeeService.actionConnectSetting())
export default class EmployeeView extends ListView {
  showDeleteAll = true;
  service     = EmployeeService
  editDialog  = false;
  addDialog   = false;
  // FormDialog  = EmployeeDialog

  columns=[
    {isSortable: true,  show: true, style: {minWidth: 200},  resizable: true,  isSearchable:false,  label: "entities.company.title",                    value: "companyInfo.name"},
    {isSortable: true, show: true,   isSearchable:true, label: "entities.employee.basicInfo.name",          value: "basicInfo.name"},
    {isSortable: true, show: true,   isSearchable:true, label: "entities.employee.basicInfo.npwp",          value: "basicInfo.npwp"},
    {isSortable: false, show: false,   isSearchable:true, label: "entities.employee.basicInfo.identityNo",    value: "basicInfo.identityNo"},
    {isSortable: false, show: true,   isSearchable:true, label: "entities.employee.jobInfo.employeeNo",    value: "jobInfo.employeeNo"},
    {isSortable: false, show: true,   isSearchable:true, label: "entities.employee.jobInfo.employeeType",    value: (v)=> {
      if(v.otherInfo.tipeKaryawan == "0") {return "KARYAWAN TETAP"} else {return "KARYAWAN TIDAK TETAP/BUKAN KARYAWAN"}
    }, type: 'func'},
    {isSortable: false, show: true,   isSearchable:true, label: "entities.employee.jobInfo.designation",    value: "jobInfo.designation"},
    {isSortable: false, show: true,   isSearchable:true, label: "entities.employee.jobInfo.department",    value: "jobInfo.department.name"},

  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Perusahaan/Cabang'
          name='companyNpwp'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          maskFormat    = "##.###.###.#-###-###"
          mask          = "_"
          length        = {15}
          valueField = "npwp"
          service       = {CompanyService}
          itemTemplate  = {function(d) {
            return {
              primaryText: d[this.props.viewField],
              secondaryText: d.npwp+" "+d.alias,
              onClick: () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
        />
        <this.ig.Field
          label='Nama'
          name='name'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp'
          className="md-cell md-cell--12"
          component={this.ig.TextfieldMask}
          maskFormat    = "##.###.###.#-###-###"
          mask          = "_"
          length        = {15}
        />
        <this.ig.Field
          label='NIK'
          name='nik'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NRP'
          name='nip'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
         <this.ig.Field
          label='Jenis Karyawan'
          name='tipeKaryawan'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[{id: '0', name: 'KARYAWAN TETAP'}, {id: '1', name: 'KARYAWAN TIDAK TETAP / BUKAN KARYAWAN'}]}
        />
        <this.ig.Field
          label='Jabatan'
          name='designation'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Departemen'
          name='department'
          className="md-cell md-cell--12"
          service={DepartmentService}
          component={this.ig.Searchfield}
        />
      </this.SearchContainer>
    )
  }
}
