import ApiService from '../../../../../services/api.service';

class EarningService extends ApiService {
  name  = 'earning';
  path  = 'pph21/master/earningType';
  constructor() {
    super();
    this.init()
  }
}


export default new EarningService();
