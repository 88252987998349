import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import PeriodeService from './Periode.service';
import ListView from '../../../../../components/entity/listView';
import PeriodeDialog from './Periode.dialog';
import PPh21DataService from '../PPh21Data/PPh21Data.service';
import counterpart from 'counterpart';
import iziToast from 'izitoast';
import {List, ListItem} from 'react-md';
import DefaultView from '../../../../../components/entity/default.view';
import { permission } from '../../../../../config/constant';
import PPh21FinalService from '../PPh21Final/PPh21Final.service';
import PPh21TidakFinalService from '../PPh21TidakFinal/PPh21TidakFinal.service';
import PPh21A1Service from '../PPh21A1/PPh21A1.service';
import uploadService from '../../../Admin/upload/upload.service';

import {Field, reduxForm, getFormValues} from 'redux-form';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield, Fileinput} from '../../../../../components/form';
import CompanyService from '../../../Admin/Company/Company.service';
import { CompanyView } from '../../../Admin/Company/Company.view';

export class PeriodeView extends DefaultView {
  service     = PeriodeService
  FormDialog  = PeriodeDialog
  urlPath     = "periode"

  approvalSettingName     = 'PPh 21 Periode'
  checkerPermissionName   = permission.MelakukanCheckPPh21Periode;
  approvalPermissionName  = permission.MelakukanApprovePPh21Periode;

  async beforeFetch(params) {
    params.type= 'page-search-common';
    params.status=["draft", "checked", "approved", "reject", "TEST_STATUS", "TEST_STATUS_REFERENCE"];
    params.jenisHarta = this.jenisHarta
  }

  columns=[
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.perjanjian.statusWorkflow",              value: (d)=> {
      if(d.workflow.status == 'draft') return <span style={{backgroundColor: '#AAA', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'rejected') return <span style={{backgroundColor: '#ffcdd2', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'approved') return <span style={{backgroundColor: '#c8e6c9', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'checked') return <span style={{backgroundColor: '#ffe0b2', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
    }, type: 'func'},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.periode.status",    value: (v) => {
      var d = v.status.statusSatuMasa+v.status.statusFinal+v.status.statusTidakFinal

      if(d.search("gagal hitung") >= 0) return "gagal hitung"
      if(d.search("belum hitung")  >= 0) return "belum hitung"
      if(d.search("sedang hitung") >= 0) return "sedang hitung"
      if(d.search("sudah hitung") >= 0) return "sudah hitung"

      if(d.search("gagal dikirim") >= 0) return "gagal dikirim"
      if(d.search("belum dikirim") >= 0) return "belum dikirim"
      if(d.search("sedang dikirim") >= 0) return "sedang dikirim"
      if(d.search("sudah dikirim") >= 0) return "sudah dikirim"

    }, type: 'func'},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.company.title",    value: 'company.name'},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.company.npwp",    value: 'company.npwp'},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.periode.year",    value: (v) => (v.year),    type: "func", searchField: 'year'},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.periode.month",    value: (v) => (v.month),    type: "func",  searchField: 'month'},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.periode.note",    value: 'note'}
  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama Cabang'
          name='name'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP Cabang'
          name='npwp'
          className="md-cell md-cell--12"
          component={this.ig.TextfieldMask}
          maskFormat    = "##.###.###.#-###-###"
          mask          = "_"
          length        = {15}
        />
        <this.ig.Field
          label='Bulan'
          name='month'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options       = {[
            {id: '1', name: 'Januari'},
            {id: '2', name: 'Februari'},
            {id: '3', name: 'Maret'},
            {id: '4', name: 'April'},
            {id: '5', name: 'Mei'},
            {id: '6', name: 'Juni'},
            {id: '7', name: 'Juli'},
            {id: '8', name: 'Agustus'},
            {id: '9', name: 'September'},
            {id: '10', name: 'Oktober'},
            {id: '11', name: 'November'},
            {id: '12', name: 'Desember'}
          ]}
        />
        <this.ig.Field
          label='Tahun'
          name='year'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  barActions = [

    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    {
      label:'word.createBulk',
      iconClassName:'mdi mdi-playlist-plus',
      onClick:() => this.addItemBulk()
    },
    // {
    //   label:'word.import',
    //   iconClassName:'mdi mdi-import',
    //   onClick:() => this.handleUpload()
    // },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
    {
      label:'word.approval',
      iconClassName:'mdi mdi-clipboard-check-outline',
      onClick:() => this.handleBatchApproval(this.props.table.selected),
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'entities.periode.hitungAll',
      iconClassName:'mdi mdi-calculator',
      onClick:() => this.hitungAll(this.props.table.selected),
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'entities.periode.sendAll',
      iconClassName:'mdi mdi-export',
      onClick:() => this.sendAll(this.props.table.selected),
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'entities.periode.sendA1',
      iconClassName:'mdi mdi-file-export',
      onClick:() => this.sendA1(this.props.table.selected),
      disabledFunc:() => this.props.table.selected.filter(x => x.month == 12).length === 0
    },
    {
      label:'entities.periode.emailAll',
      iconClassName:'mdi mdi-email',
      onClick:() => this.emailAll(this.props.table.selected),
      disabledFunc:() => this.props.table.selected.length === 0
    },
    // {
    //   label:'entities.periode.sendSatuMasa',
    //   iconClassName:'mdi mdi-export',
    //   onClick:() => this.sendSatuMasa(this.props.table.selected),
    //   disabledFunc:() => this.props.table.selected.length === 0
    // },
    // {
    //   label:'entities.periode.sendFinal',
    //   iconClassName:'mdi mdi-export',
    //   onClick:() => this.sendFinal(this.props.table.selected),
    //   disabledFunc:() => this.props.table.selected.length === 0
    // },
    // {
    //   label:'entities.periode.sendTidakFinal',
    //   iconClassName:'mdi mdi-export',
    //   onClick:() => this.sendTidakFinal(this.props.table.selected),
    //   disabledFunc:() => this.props.table.selected.length === 0
    // },

    // {
    //   label:'entities.periode.hitungSatuMasa',
    //   iconClassName:'mdi mdi-calculator',
    //   onClick:() => this.hitungSatuMasa(this.props.table.selected),
    //   disabledFunc:() => this.props.table.selected.length === 0
    // },
    // {
    //   label:'entities.periode.hitungFinal',
    //   iconClassName:'mdi mdi-calculator',
    //   onClick:() => this.hitungFinal(this.props.table.selected),
    //   disabledFunc:() => this.props.table.selected.length === 0
    // },
    // {
    //   label:'entities.periode.hitungTidakFinal',
    //   iconClassName:'mdi mdi-calculator',
    //   onClick:() => this.hitungTidakFinal(this.props.table.selected),
    //   disabledFunc:() => this.props.table.selected.length === 0
    // },
    // {
    //   label:'word.delete',
    //   iconClassName:'mdi mdi-delete',
    //   onClick:() => {
    //     this.setState({showDialogConfirmDeleteSelected:true})
    //   },
    //   disabledFunc:() => this.props.table.selected.length === 0
    // },
    ,
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },

  ]



  tableActions = (d) => {
    var menus = [
      {
        label:"Buka Periode",
        iconClassName:"mdi mdi-book-open",
        onClick: (item) => {
          this.props.history.push(`${this.props.history.location.pathname}/${item.id}/open`)
        }
      },
      // {
      //   label:"More Info",
      //   iconClassName:"mdi mdi-bell",
      //   onClick: (item) => this.editItem([item])
      // },

      // {
      //   label:"Hitung Satu Masa",
      //   iconClassName:"mdi mdi-calculator",
      //   onClick: (item) => this.hitungSatuMasa([item])
      // },
      // {
      //   label:"Hitung PPh 21 Final",
      //   iconClassName:"mdi mdi-calculator",
      //   onClick: (item) => this.hitungFinal([item])
      // },
      // {
      //   label:"Hitung PPh 21 Tidak Final",
      //   iconClassName:"mdi mdi-calculator",
      //   onClick: (item) => this.hitungTidakFinal([item])
      // },
      // {label:"divider", iconClassName:"-"},
      // {
      //   label:"Hitung PPh 21",
      //   iconClassName:"mdi mdi-calculator",
      //   onClick: (item) => this.hitungAll([item])
      // },
      // {
      //   label:"Kirim PPh 21 Ke ePPT",
      //   iconClassName:"mdi mdi-export",
      //   onClick: (item) => this.sendAll([item])
      // },
      // {
      //   label:"Kirim A1 Ke ePPT",
      //   iconClassName:"mdi mdi-export",
      //   onClick: (item) => this.sendA1([item])
      // },
      // {
      //   label:"Kirim Satu Masa Ke ePPT",
      //   iconClassName:"mdi mdi-export",
      //   onClick: (item) => this.sendSatuMasa([item])
      // },
      // {
      //   label:"Kirim PPh 21 Final",
      //   iconClassName:"mdi mdi-export",
      //   onClick: (item) => this.sendFinal([item])
      // },
      // {
      //   label:"Kirim PPh 21 Tidak Final",
      //   iconClassName:"mdi mdi-export",
      //   onClick: (item) => this.sendTidakFinal([item])
      // }

    ]

    if(d.month == 12) {
      // menus.push({
      //   label:"Kirim PPh 21 A1",
      //   iconClassName:"mdi mdi-export",
      //   onClick: (item) => this.sendA1([item])
      // })
    }

    menus.push({label:"divider", iconClassName:"-"},
      {
        label:"Delete",
        iconClassName:"mdi mdi-delete",
        onClick:(item, callback) => this.deleteItem(item, callback),
        confirmation:{
          title:"sentence.custom.deleteItem",
          message:"sentence.custom.deleteItemConfirmation"
        }
      },
      {label:"divider", iconClassName:"-"})

    if(this.props.approvalSetting[this.approvalSettingName]) {

      if(d.workflow.status == 'draft' && this.props.user.permissionMap[this.checkerPermissionName]) {
        menus.push({
          label:"Proses Check",
          iconClassName:"mdi mdi-format-list-checks",
          onClick: (item) => this.handleCheck(item),
          disabled: true
        })
      }
      if(d.workflow.status == 'checked' && this.props.user.permissionMap[this.approvalPermissionName]) {
        menus.push({
          label:"Proses Approval",
          iconClassName:"mdi mdi-clipboard-check-outline",
          onClick: (item) => this.handleApproval(item)
        })
      }
      if((d.workflow.status == 'approved' || d.workflow.status == 'rejected' )&& this.props.user.permissionMap[this.approvalPermissionName]) {
        menus.push({
          label:"Batalkan Approval",
          iconClassName:"mdi mdi-undo",
          onClick: (item) => this.unApprove(item)
        })
      }

    } else {
      // menus.push({
      //   label:"Proses Approval",
      //   iconClassName:"mdi mdi-clipboard-check-outline",
      //   onClick: (item) => this.handleApproval(item)
      // })
    }

    return menus;
  }

  handleBatchApproval = async (arr)=> {

    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Pilih Proses',
      initialValue: {proses:'Proses Cek'},
      height: 'auto',
      width: 300,
      contentStyle: {overflow: 'inherit'},
      okText: 'Lanjutkan',
      text: <div className="md-grid" >
            <Field
              label         = {'Proses'}
              name          = 'proses'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              options       = {[
              //   {
              //   id: 'Proses Cek',
              //   name: 'Proses Cek'
              // },
              {
                id: 'Proses Approval',
                name: 'Proses Approval'
              }, {
                id: 'Proses Pembatalan Approval',
                name: 'Proses Pembatalan Approval'
              }]}
              validate      = {validation.required}
            />
      </div>
    }))

    if(p) {
    var filtered = arr;
    var formInput = () => <div/>
    switch (p.proses) {
      case 'Proses Approval':
        filtered = arr.filter((x) => x.workflow.status == 'checked' ||  x.workflow.status == 'draft')
        formInput = () => <Field
          label         = {counterpart('entities.workflow.action')}
          name          = 'status'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          options       = {[{
            id: 'approved',
            name: 'Approve'
          }, {
            id: 'rejected',
            name: 'Reject'
          }]}
          validate      = {validation.required}
        />
        break;
      case 'Proses Pembatalan Approval':
        filtered = arr.filter((x) => x.workflow.status == 'approved' ||  x.workflow.status == 'rejected')
        formInput = () => <div/>
        break;
      default:
        filtered = arr.filter((x) => x.workflow.status == 'draft')
        formInput = () => <Field
          label         = {counterpart('entities.workflow.action')}
          name          = 'status'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          options       = {[{
            id: 'checked',
            name: 'Approve'
          }, {
            id: 'rejected',
            name: 'Reject'
          }]}
          validate      = {validation.required}
        />
        break;
    }
    var dialog = await this.context.showDialog((props, res, rej) =>({
      initialValue: {status: 'draft'},
      height: 'auto',
      width: 300,
      contentStyle: {padding:0, overflowY: 'hidden'},
      okText: 'Submit',
      text: (
        <div >
          <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
            <div className='mpk-font-size-M flex mpk-layout align-center'>{p.proses}</div>
          </div>

          <div className="md-grid" >
            {formInput()}
            <Field
              label         = {counterpart('entities.workflow.message')}
              name          = 'message'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}

              />
          </div>

          <div style={{width: '100%', height: 300, overflow: 'auto'}}>
        <p style={{padding: '0px 24px'}}>Apakah anda yakin akan memproses data dibawah ini?</p>
        <List style={{width: '100%'}}>
        {filtered.map((v, i)=> {
          return <ListItem primaryText={`${v.company.name} (${v.company.npwp})`}  key={i} secondaryText={`Masa ${v.month} - ${v.workflow.status}`} />
          })}
        </List>
      </div>)
        </div>
      )
    }));

    if(dialog) {
      await this.service.api.updateWorkflowBulk({...dialog,ids: filtered.map(x => x.id)});
      iziToast.success({title: 'Success', message: 'Proses check telah berhasil dilakukan'})
      this.fetchData()
    }
  }
  }

  async hitungAll(arr) {
    // var v = item;
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Batch hitung',
      width: 500,
      initialValue: {komponenDihitung: 'Gaji, Tunjangan & THR'},
      okText: 'Submit',
      text: (<div style={{width: '100%'}}>
        <p style={{padding: '0px 24px'}}>Apakah anda yakin akan menghitung data dibawah ini?</p>
        <List style={{width: '100%'}}>
        {arr.map((v, i)=> {
          return <ListItem primaryText={`${v.company.name}`}  key={i} secondaryText={`(${v.company.npwp})`} />
          })}
        </List>

        <div className="md-grid">
        <Field
        label         = 'Jenis Karyawan'
        name          = 'employeeType'
        className     = 'md-cell md-cell--12'
        component     = {Searchfield}
        // disabled
        options       = {[{
          id: '3',
          name: 'Karyawan Tetap & Karyawan Tidak Tetap'
        },{
          id: '0',
          name: 'Karyawan Tetap'
        }, {
          id: '1',
          name: 'Karyawan Tidak Tetap'
        }]}
        validate      = {validation.required}
      />
          <Field
            label         = 'Metode Pembuatan Slip'
            name          = 'slipMethod'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            // disabled
            options       = {[{
              id: 'Hanya Hitung',
              name: 'Hitung dari Daftar Slip'
            }, {
              id: 'Ambil dari master karyawan',
              name: 'Ambil dari master karyawan'
            }]}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.salarySlip.paymentDate')}
            name          = 'paymentDate'
            className     = 'md-cell md-cell--12'
            component     = {Datepicker}
          />
          <Field
            label         = {'Note'}
            name          = 'note'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
        </div>
      </div>)
    }));

    if(d) {
      var data = {
        ...d,
        ids: arr.map(v => v.id),
        note: ''
      }

      await this.service.api.hitungBulk(data)

      iziToast.success({title: 'Success', message: 'Data sedang di hitung, mohon cek status di Log Proses'})
    }
  }


  async hitungSatuMasa(arr) {
    // var v = item;
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Batch hitung satu masa',
      width: 500,
      initialValue: {},
      okText: 'Submit',
      text: (<div style={{width: '100%'}}>
        <p style={{padding: '0px 24px'}}>Apakah anda yakin akan menghitung data dibawah ini?</p>
        <List style={{width: '100%'}}>
        {arr.map((v, i)=> {
          return <ListItem primaryText={`${v.company.name}`}  key={i} secondaryText={`(${v.company.npwp})`} />
          })}
        </List>
      </div>)
    }));

    if(d) {
      var data = {
        ids: arr.map(v => v.id),
        note: ''
      }
      await PPh21DataService.api.calculateBatch(data)
      iziToast.success({title: 'Success', message: 'Data sedang di hitung, mohon cek status di Log Proses'})
    }
  }

  async hitungFinal(arr) {
    // var v = item;
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Batch hitung PPh 21 final',
      width: 500,
      initialValue: {},
      okText: 'Submit',
      text: (<div style={{width: '100%'}}>
        <p style={{padding: '0px 24px'}}>Apakah anda yakin akan menghitung data dibawah ini?</p>
        <List style={{width: '100%'}}>
        {arr.map((v, i)=> {
          return <ListItem primaryText={`${v.company.name}`}  key={i} secondaryText={`(${v.company.npwp})`} />
          })}
        </List>
      </div>)
    }));

    if(d) {
      var data = {
        ids: arr.map(v=> v.id),
        note: ''
      }
      await PPh21FinalService.api.calculateBatch(data)
      iziToast.success({title: 'Success', message: 'Data sedang di hitung, mohon cek status di Log Proses'})
    }
  }

  async hitungTidakFinal(arr) {
    // var v = item;
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Batch hitung PPh 21 tidak final',
      width: 500,
      initialValue: {},
      okText: 'Submit',
      text: (<div style={{width: '100%'}}>
        <p style={{padding: '0px 24px'}}>Apakah anda yakin akan menghitung data dibawah ini?</p>
        <List style={{width: '100%'}}>
        {arr.map((v, i)=> {
          return <ListItem primaryText={`${v.company.name}`}  key={i} secondaryText={`(${v.company.npwp})`} />
          })}
        </List>
      </div>)
    }));

    if(d) {
      var data = {
        ids: arr.map(v => v.id),
        note: ''
      }
      await PPh21TidakFinalService.api.calculateBatch(data)
      iziToast.success({title: 'Success', message: 'Data sedang di hitung, mohon cek status di Log Proses'})
    }
  }

  async sendAll(arr) {
    // var v = item;
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Kirim data PPh 21 ke ePPT',
      width: 500,
      initialValue: {},
      okText: 'Submit',
      text: (<div style={{width: '100%'}}>
        <p style={{padding: '0px 24px'}}>Apakah anda yakin akan mengirim data dibawah ini?</p>
        <List style={{width: '100%'}}>
          {arr.filter((x) => x.workflow.status == 'approved').map((v, i)=> {
          return <ListItem primaryText={`${v.company.name}`}  key={i} secondaryText={`(${v.company.npwp})`} />
          })}
        </List>
      </div>)
    }));

    if(d) {
      var data = {
        ids: arr.map(v => v.id),
        note: ''
      }

      await PeriodeService.api.sendEppt(data)
      iziToast.success({title: 'Success', message: 'Data sedang di dikirim, mohon cek status di Log Proses'})
    }
  }

  async emailAll(arr) {
    // var v = item;
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Kirim data slip ke alamat email karyawan',
      width: 500,
      initialValue: {},
      okText: 'Submit',
      text: (<div style={{width: '100%'}}>
        <p style={{padding: '0px 24px'}}>Apakah anda yakin akan mengirim data dibawah ini?</p>
        <List style={{width: '100%'}}>
          {arr.filter((x) => x.workflow.status == 'approved').map((v, i)=> {
          return <ListItem primaryText={`${v.company.name}`}  key={i} secondaryText={`(${v.company.npwp})`} />
          })}
        </List>
      </div>)
    }));

    if(d) {
      var data = {
        ids: arr.map(v => v.id),
        note: ''
      }

      await PeriodeService.api.sendEmail(data)
      iziToast.success({title: 'Success', message: 'Slip sedang di dikirim, mohon cek status di Log Proses'})
    }
  }

  async sendSatuMasa(arr) {
    // var v = item;
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Kirim data satu masa ke ePPT',
      width: 500,
      initialValue: {},
      okText: 'Submit',
      text: (<div style={{width: '100%'}}>
        <p style={{padding: '0px 24px'}}>Apakah anda yakin akan mengirim data dibawah ini?</p>
        <List style={{width: '100%'}}>
          {arr.map((v, i)=> {
          return <ListItem primaryText={`${v.company.name}`}  key={i} secondaryText={`(${v.company.npwp})`} />
          })}
        </List>
      </div>)
    }));

    if(d) {
      var data = {
        ids: arr.map(v => v.id),
        note: ''
      }
       await PeriodeService.api.sendEppt(data)
      iziToast.success({title: 'Success', message: 'Data sedang di dikirim, mohon cek status di Log Proses'})
    }
  }

  async sendA1(arr) {
    // var v = item;
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Kirim data A1 ke ePPT',
      width: 500,
      initialValue: {regenerateNomorBp: false},
      okText: 'Submit',
      text: (<div style={{width: '100%'}}>
        <p style={{padding: '0px 24px'}}>Apakah anda yakin akan mengirim data dibawah ini?</p>
        <div className='md-grid'>
          <Field
            label         = 'Regenerate Nomor BP A1'
            name          = 'regenerateNomorBp'
            className     = 'md-cell md-cell--12'
            component     = {Switch}
          />
      </div>
        <List style={{width: '100%'}}>
          {arr.filter(v=> v.month === 12).map((v, i)=> {
          return <ListItem primaryText={`${v.company.name}`}  key={i} secondaryText={`(${v.company.npwp})`} />
          })}
        </List>
      </div>)
    }));

    console.log(d)

    if(d) {
      var data = {
        ids: arr.map(v => v.id),
        npwps: arr.map(v => v.company.npwp),
        regenerateNomorBp: d.regenerateNomorBp,
        tipeSummary: 'A1',
        note: ''
      }
      await PeriodeService.api.sendEppt(data)
      iziToast.success({title: 'Success', message: 'Data sedang di dikirim, mohon cek status di Log Proses'})
    }
  }

  async sendFinal(arr) {
    // var v = item;
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Kirim data Final ke ePPT',
      width: 500,
      initialValue: {},
      okText: 'Submit',
      text: (<div style={{width: '100%'}}>
        <p style={{padding: '0px 24px'}}>Apakah anda yakin akan mengirim data dibawah ini?</p>
        <List style={{width: '100%'}}>
        {arr.map((v, i)=> {
          return <ListItem primaryText={`${v.company.name}`}  key={i} secondaryText={`(${v.company.npwp})`} />
          })}
        </List>
      </div>)
    }));

    if(d) {
      var data = {
        ids: arr.map(v => v.id),
        note: ''
      }
      await PPh21FinalService.api.sendEppt(data)
      iziToast.success({title: 'Success', message: 'Data sedang di dikirim, mohon cek status di Log Proses'})
    }
  }

  async sendTidakFinal(arr) {
    // var v = item;
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Kirim data Tidak Final ke ePPT',
      width: 500,
      initialValue: {},
      okText: 'Submit',
      text: (<div style={{width: '100%'}}>
        <p style={{padding: '0px 24px'}}>Apakah anda yakin akan mengirim data dibawah ini?</p>
        <List style={{width: '100%'}}>
        {arr.map((v, i)=> {
          return <ListItem primaryText={`${v.company.name}`}  key={i} secondaryText={`(${v.company.npwp})`} />
          })}
        </List>
      </div>)
    }));

    if(d) {
      var data = {
        ids: arr.map(v => v.id),
        note: ''
      }
      await PPh21TidakFinalService.api.sendEppt(data)
      iziToast.success({title: 'Success', message: 'Data sedang di dikirim, mohon cek status di Log Proses'})
    }
  }

  async handleUpload() {
    var value = await this.context.showDialog((props, res, rej) =>({
      title: 'Import Raw PPh 21',
      width: 500,
      initialValue: {},
      actions: [{
        children: 'Download Template',
        onClick: ()=> {window.open(`/images/templates/pph21_import.xlsx`,'_blank'); res(false)}
      },{
        children: 'Cancel',
        onClick: ()=> {res(false)}
      },{
        secondary: true,
        children: 'Import',
        onClick: ()=> {
          var result = props.props.formValue;
          if(!result) result = {}
          res(result)
        }
      }],
      okText: 'Import',
      text:
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.upload.file')}
            name          = 'file'
            className     = 'md-cell md-cell--12'
            component     = {Fileinput}
            // twoLines
            validate      = {validation.required}
          />
      </div>
    }));

    if(value) {
      var uploadData = new FormData();
      uploadData.append('file', value.file)
      var res = await uploadService.api.upload(uploadData);

      if(res.data.name) {
        value = {
          ...value,
          ...res.data,
          companyId: '99a16478-9b69-4df6-a350-e5adaec6cbb5',
          delimiter: ';'
        }

        var uploadRes = PPh21DataService.api.import(value)

        iziToast.success({
          title: 'success',
          message: counterpart('entities.upload.importSuccess')
        })
      }
    }
  }

  deleteItem = async (item, callback) => {
    try {
      await this.service.api.deleteAll([item.id]);
      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

  deleteSelected = async (items, callback) => {
    try {
      let ids = []

      items.filter((x) => x.workflow.status == 'draft' || x.workflow.status == 'rejected'|| x.workflow.status == 'cancelled').forEach(item => {
        ids.push(item.id)
      })

      if(this.service.api.deleteSelected) {
        await this.service.api.deleteSelected(ids)
      } else if(this.service.api.deleteAll) {
        await this.service.api.deleteAll(ids)
      }

      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

  addItemBulk = async () => {
    var p = await this.context.showDialog((props, res, rej) =>({
      title: <div style={{marginTop: 10}}>Bulk Create</div>,
      initialValue: {proses:'Proses Cek'},
      height: 'auto',
      width: 800,
      contentStyle: {overflow: 'auto'},
      okText: 'Save',
      text: <div className="md-grid" >
            <Field
              label         = {counterpart('entities.periode.month')}
              name          = 'month'
              className     = 'md-cell md-cell--6'
              component     = {Searchfield}
              options       = {[
                {id: '1', name: 'Januari'},
                {id: '2', name: 'Februari'},
                {id: '3', name: 'Maret'},
                {id: '4', name: 'April'},
                {id: '5', name: 'Mei'},
                {id: '6', name: 'Juni'},
                {id: '7', name: 'Juli'},
                {id: '8', name: 'Agustus'},
                {id: '9', name: 'September'},
                {id: '10', name: 'Oktober'},
                {id: '11', name: 'November'},
                {id: '12', name: 'Desember'}
              ]}
              validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.periode.year')}
              name          = 'year'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.periode.note')}
              name          = 'note'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
              // validate      = {validation.required}
            />

            <div className="md-cell md-cell--12">
              <CompanyPicker />
            </div>
          </div>
    }))

    if(p) {
      p.revisi = 0
      p.month = parseInt(p.month)
      p.year = parseInt(p.year)
      p.companies = this.props.companyTable.selected.map(x=> x.id)
      await this.service.api.createBulk(p)
      await this.fetchData()
    }
  }
}

@connect((state) => ({
  ...CompanyService.stateConnectSetting()(state)
}), CompanyService.actionConnectSetting())
class CompanyPicker extends CompanyView {
  viewType=2;
  service=CompanyService
  translate=false


  titleHeader() {
    return ""
  }


  beforeFetch(params) {
    params.id = this.props.lphId
  }

  _barActions = () =>[
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }
}


export default connect((state) => ({
  ...PeriodeService.stateConnectSetting()(state),
  companyTable: state.entity.company.table,
  approvalSetting: state.authHalona.approvalSetting,
  user: state.authHalona.user
}), PeriodeService.actionConnectSetting())(PeriodeView)
