import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import FormView from '../../../../../components/entity/form.view';
import iziToast from 'izitoast';
import KalkulatorService from './Kalkulator.service';
import utilsService from '../../../../../services/utils.service';


@reduxForm({form: 'MasaForm', destroyOnUnmount: true, initialValues: {
  masaPerolehanAwal: 1,
  masaPerolehanAkhir: 12,
  isGrossUp: false,
  statusPtkp: 'TK',
  tanggunganPtkp: 0,
  tanpaNpwp: false,
  kodeKop:'21-100-01',
  b01: 0,
  b02: 0,
  b03: 0,
  b04: 0,
  b05: 0,
  b06: 0,
  b07: 0,
  b10: 0,
  b13: 0,
  b18: 0,
  tahun: (new Date()).getFullYear(),
  masa: 1,
  penghasilanMasaSebelumnya: {
    b01: 0,
    b02: 0,
    b03: 0,
    b04: 0,
    b05: 0,
    b06: 0,
    b07: 0,
    pph: 0,
    b10: 0
  }
}})
@connect((state) => ({
  data: getFormValues('MasaForm')(state)
}))
export default class MasaForm extends FormView {
  service     = KalkulatorService
  viewType    = 2;
  translate   = false;

  titleHeader() {
    return "Kalkulator PPh 21 Masa"
  }

  constructor(props) {
    super(props)
    this.state = {
      result: {"b00":"21-100-01","b01":0,"b02":0,"b03":0,"b04":0,"b05":0,"b06":0,"b07":0,"b08":0,"b08Setahun":0,"b09":0,"b09Setahun":0,"b10":0,"b10Setahun":0,"b11":0,"b11Setahun":0,"b12":0,"b12Setahun":0,"b13":0,"b14":0,"b15":0,"b16":0,"b16a":0,"b16b":0,"b17":0,"b18":0,"b19":0,"b19a":0,"b19b":0,"b20":0,"brutoPayroll":0,"pphPayroll":0,"slipTunjanganPph":0,"slipPotonganPph":0}
    }
  }

  async initData() {
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Hitung</Button>
      </div>
    )
  }


  formView() {
    return (<div>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={"INFO DASAR"} />
        <Divider/>
        <div className='md-grid'>
          <Field
          label         = {"Kode Objek Pajak"}
          name          = 'kodeKop'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          options={[
            {id: '21-100-01', name:'21-100-01', detail: 'Pegawai Tetap'},
            {id: '21-100-02', name:'21-100-02', detail: 'Penerima Pensiun secara teratur'}
          ]}/>
          <Field
            label         = 'Tahun'
            name          = 'tahun'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = 'Masa'
            name          = 'masa'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <div className="md-cell md-cell--6">
            <Field
              label         = 'Masa perolehan awal'
              name          = 'masaPerolehanAwal'
              component     = {Textfield}
            />
            <em>*Di isi bulan join wp jika join di tahun yang sama selain itu di isi 1 </em>
          </div>

          <div className="md-cell md-cell--6">
            <Field
              label         = 'Masa perolehan akhir'
              name          = 'masaPerolehanAkhir'
              component     = {Textfield}
            />
            <em>*Jika wp keluar, 'Masa' & 'Masa perolehan akhir' di isi bulan keluar wp selain itu di isi 12 </em>
          </div>
          <Field
            label         = {"Status Pindahan"}
            name          = 'statusPindahan'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: '00',
              name: 'Normal'
            }, {
              id: '01',
              name: 'Dipindahkan ke cabang lain'
            }, {
              id: '02',
              name: 'Berhenti ke perusahaan lain/pensiun'
            }, {
              id: '03',
              name: 'Berhenti meninggal/pindah ke LN/dari LN'
            }, {
              id: '04',
              name: 'Pegawai baru'
            }, {
              id: '05',
              name: 'Pindahan'
            }]}
          />
          <Field
            label         = {"Status PTKP"}
            name          = 'statusPtkp'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: 'TK',
              name: 'Tidak Kawin'
            }, {
              id: 'K',
              name: 'Kawin'
            }]}
          />
          <Field
            label         = {"Tanggungan PTKP"}
            name          = 'tanggunganPtkp'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: 0,
              name: '0'
            },{
              id: 1,
              name: '1'
            }, {
              id: 2,
              name: '2'
            }, {
              id: 3,
              name: '3'
            }]}
          />
          <Field
            label         = 'Apakah Gross Up'
            name          = 'isGrossUp'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          />
          <Field
            label         = 'Tanpa Npwp'
            name          = 'tanpaNpwp'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          />
        </div>
      </Card>
      <br/>

      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle='PENGHASILAN' />
        <Divider/>
        <DataTable plain>
          <TableBody>
            <TableRow>
              <TableColumn style={{width: 20}}>1.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b01')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'b01'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>2.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b02')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'b02'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>3.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b03')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'b03'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>4.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b04')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'b04'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>5.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b05')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'b05'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>6.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b06')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'b06'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>7.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b07')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'b07'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>10.</TableColumn>
              <TableColumn style={{borderTop: '3px solid black'}}>{counterpart('entities.a1.sectionB.b10')}</TableColumn>
              <TableColumn style={{borderTop: '3px solid black'}}>
                <Field
                  label         = {'\u00A0'}
                  name          = 'b10'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>13.</TableColumn>
              <TableColumn style={{borderTop: '3px solid black'}}>{counterpart('entities.a1.sectionB.b13')}</TableColumn>
              <TableColumn style={{borderTop: '3px solid black'}}>
                <Field
                  label         = {'\u00A0'}
                  name          = 'b13'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>
            <TableRow>
            <TableColumn>18.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b18')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'b18'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>
          </TableBody>
        </DataTable>
      </Card>

      {this.props.data && this.props.data.masaPerolehanAkhir && (parseInt(this.props.data.masaPerolehanAkhir) == parseInt(this.props.data.masa)) && <div>
      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={'JUMLAH PENGHASILAN DARI MASA '+this.props.data.masaPerolehanAwal+' - '+(parseInt(this.props.data.masaPerolehanAkhir)-1)} />
        <Divider/>
        <DataTable plain>
          <TableBody>
            <TableRow>
              <TableColumn style={{width: 20}}>1.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b01')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'penghasilanMasaSebelumnya.b01'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>2.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b02')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'penghasilanMasaSebelumnya.b02'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>3.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b03')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'penghasilanMasaSebelumnya.b03'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>4.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b04')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'penghasilanMasaSebelumnya.b04'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>5.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b05')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'penghasilanMasaSebelumnya.b05'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>6.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b06')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'penghasilanMasaSebelumnya.b06'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>7.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b07')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'penghasilanMasaSebelumnya.b07'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>10.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b10')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'penghasilanMasaSebelumnya.b10'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn></TableColumn>
              <TableColumn>PPh</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'penghasilanMasaSebelumnya.pph'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

          </TableBody>
        </DataTable>
      </Card>
      </div>}
      <br />
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle='HASIL PERHITUNGAN' />
        <Divider/>
        <DataTable plain>
          <TableBody>
            <TableRow>
              <TableColumn style={{width: 20}}>1.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b01')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
                {utilsService.number.format(this.state.result.b01)}
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>2.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b02')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b02)}
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>3.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b03')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b03)}
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>4.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b04')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b04)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>5.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b05')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b05)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>6.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b06')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b06)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>7.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b07')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b07)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>8a.</TableColumn>
              <TableColumn style={{borderTop: '3px solid black'}}>{counterpart('entities.a1.sectionB.b08')}</TableColumn>
              <TableColumn style={{borderTop: '3px solid black', textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b08)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>8b.</TableColumn>
              <TableColumn style={{borderBottom: '3px solid black'}}>{counterpart('entities.a1.sectionB.b08')} (SETAHUN)</TableColumn>
              <TableColumn style={{borderBottom: '3px solid black', textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b08Setahun)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>9a.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b09')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b09)}
              </TableColumn>
              </TableRow>

              <TableRow>
            <TableColumn>9b.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b09')} (SETAHUN)</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b09Setahun)}
              </TableColumn>
              </TableRow>

              <TableRow>
              <TableColumn>10a.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b10')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b10)}
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>10b.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b10')} (SETAHUN)</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b10Setahun)}
              </TableColumn>
            </TableRow>



            <TableRow>
            <TableColumn>11a.</TableColumn>
              <TableColumn style={{borderTop: '3px solid black'}}>{counterpart('entities.a1.sectionB.b11')}</TableColumn>
              <TableColumn style={{borderTop: '3px solid black', textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b11)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>11b.</TableColumn>
              <TableColumn >{counterpart('entities.a1.sectionB.b11')} (SETAHUN)</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b11Setahun)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>12a.</TableColumn>
              <TableColumn style={{borderTop: '3px solid black'}}>{counterpart('entities.a1.sectionB.b12')}</TableColumn>
              <TableColumn style={{borderTop: '3px solid black', textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b12)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>12b.</TableColumn>
              <TableColumn style={{borderBottom: '3px solid black'}}>{counterpart('entities.a1.sectionB.b12')} (SETAHUN)</TableColumn>
              <TableColumn style={{borderBottom: '3px solid black', textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b12Setahun)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>13.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b13')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b13)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>14.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b14')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b14)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>15.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b15')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b15)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>16.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b16')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b16)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>16a.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b16')} (NON BONUS)</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b16a)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>16b.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b16')} (BONUS)</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b16b)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>17.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b17')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b17)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>18.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b18')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b18)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>19.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b19')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b19)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>19a.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b19')} (NON BONUS)</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b19a)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>19b.</TableColumn>
              <TableColumn>{counterpart('entities.a1.sectionB.b19')} (BONUS)</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b19b)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>20.</TableColumn>
              <TableColumn >{counterpart('entities.a1.sectionB.b20')}</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.b20)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>21.</TableColumn>
              <TableColumn style={{borderTop: '3px solid black'}}>BRUTO</TableColumn>
              <TableColumn style={{borderTop: '3px solid black', textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.brutoPayroll)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>22.</TableColumn>
              <TableColumn >PPh</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.pphPayroll)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>23.</TableColumn>
              <TableColumn >POTONGAN PPh DI SLIP</TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.slipPotonganPph)}
              </TableColumn>
            </TableRow>

            <TableRow>
            <TableColumn>24.</TableColumn>
              <TableColumn >TUNJANGAN PPh DI SLIP </TableColumn>
              <TableColumn style={{textAlign: 'right'}}>
              {utilsService.number.format(this.state.result.slipTunjanganPph)}
              </TableColumn>
            </TableRow>
          </TableBody>
        </DataTable>
      </Card>
    </div>)
  }


  async handleSave(value) {
    var beforeSave = await this.beforeSave(value);
    value.tahun = parseInt(value.tahun)
    value.masa = parseInt(value.masa)
    value.masaPerolehanAwal = parseInt(value.masaPerolehanAwal)
    value.masaPerolehanAkhir = parseInt(value.masaPerolehanAkhir)
    value.tanggunganPtkp = parseInt(value.tanggunganPtkp)
    var res = null;
    if(beforeSave) {
      if(typeof beforeSave === 'object') value = beforeSave;
      try {
        this.setState({onProgress: true})

        res = await KalkulatorService.api.masa(value);
        this.setState({result: res.data})

        iziToast.success({title: 'Info', message: 'Data sudah di hitung'})
        this.setState({onProgress: false})
        this.afterSave(res, value)
      } catch(e) {
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      }
    }
  }
}
