import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ObjekPajak.dialog.view';
import ObjekPajakService from './ObjekPajak.service';
import ListView from '../../../../../components/entity/listView';
import ObjekPajakDialog from './ObjekPajak.dialog';

@connect(ObjekPajakService.stateConnectSetting(), ObjekPajakService.actionConnectSetting())
export default class ObjekPajakView extends ListView {
  service     = ObjekPajakService
  FormDialog  = ObjekPajakDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.objekPajak.name",                    value: "name"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.objekPajak.code",                    value: "code"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.objekPajak.description",             value: "description",                  className: "mpk-font-size-S"}
  ]

  _tableActions = [
  ]

  barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    }
  ]
}
