import ApiService from '../../../../../services/api.service';

class NonEmployeeService extends ApiService {
  name  = 'nonEmployee';
  path  = 'pph21/master/non-employee';

  constructor() {
    super();
    this.init()
  }
}


export default new NonEmployeeService();
