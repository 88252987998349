import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import DialogView from '../../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import DeductionService from './Deduction.service';
import ComponentTypeService from '../ComponentType/ComponentType.service';
import CompanyService from '../../../Admin/Company/Company.service';

@reduxForm({form: 'DeductionForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('DeductionForm')(state)
}))
export default class DeductionDialog extends DialogView {
  service     = DeductionService
  initialData = {
    hideSlipPrintOut: false,
    isDynamic: false
  }

  constructor(props){
    super(props);
    this.state = {
      onProgress: false,
      componentTypes: []
    };
  }

  async componentDidMount() {
    var res = await ComponentTypeService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    this.setState({
      componentTypes: res.data.data.filter((d, e) => d.category == "DEDUCTION")
    })

    this.initData(this.props);
  }

  formView() {
    return (
      <div className='md-grid'>
        {/* <Field
          label         = {counterpart('entities.company.title')}
          name          = 'company'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          validate      = {validation.required}
          service       = {CompanyService}
          valueField    = 'parent'
        /> */}
        <Field
          label         = {counterpart('entities.deduction.name')}
          name          = 'name'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.deduction.alias')}
          name          = 'alias'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          // validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.deduction.componentType')}
          name          = 'componentType'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          // service       = {ComponentTypeService}
          options       = {this.state.componentTypes}
          valueField    = "parent"
          validate      = {validation.required}
        />
        {/* <Field
          label         = {counterpart('entities.deduction.hideSlipPrintOut')}
          name          = 'hideSlipPrintOut'
          className     = 'md-cell md-cell--6'
          component     = {Switch}
          // validate      = {validation.required}
        /> */}
        <Field
          label         = {counterpart('entities.deduction.isDynamic')}
          name          = 'isDynamic'
          className     = 'md-cell md-cell--6'
          component     = {Switch}
          // validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.deduction.description')}
          name          = 'description'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          // validate      = {validation.required}
        />
      </div>
    )
  }
}
