import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './NonEmployee.dialog.view';
import NonEmployeeService from './NonEmployee.service';
import ListView from '../../../../../components/entity/listView';

@connect(NonEmployeeService.stateConnectSetting(), NonEmployeeService.actionConnectSetting())
export default class NonEmployeeView extends ListView {
  service     = NonEmployeeService
  editDialog  = false;
  addDialog   = false;
  // FormDialog  = NonEmployeeDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.company.title",                                value: "companyInfo.name"},
    {isSortable: true,  show: true,   isSearchable:false, label: "entities.nonEmployee.basicInfo.name",                   value: "basicInfo.name"},
    {isSortable: true,  show: true,   isSearchable:false, label: "entities.nonEmployee.basicInfo.npwp",                   value: "basicInfo.npwp"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.nonEmployee.jobInfo.employeeNo",               value: "jobInfo.employeeNo"}
  ]
}
