import React from 'react';
import ListView from "./listView";
import { permission } from "../../config/constant";
import {Textfield, Searchfield, validation} from '../form'
import {Field, reduxForm, getFormValues} from 'redux-form';
import counterpart from 'counterpart';
import iziToast from 'izitoast';

export default class DefaultView extends ListView {

  approvalSettingName   = 'Perjanjian'
  checkerPermissionName = permission.MelakukanCheckPerjanjian;
  approvalPermissionName= permission.MelakukanApprovePerjanjian;

  tableActions = (d) => {
    var permissionMap = this.props.user.permissionMap;
    var menus = [
      {
        label:"More Info",
        iconClassName:"mdi mdi-bell",
        onClick: (item) => this.editItem(item)
      },
      {label:"divider", iconClassName:"-"},
      {
        label:"Delete",
        iconClassName:"mdi mdi-delete",
        onClick:(item, callback) => this.deleteItem(item, callback),
        confirmation:{
          title:"sentence.custom.deleteItem",
          message:"sentence.custom.deleteItemConfirmation"
        }
      },
      {label:"divider", iconClassName:"-"}
    ]

    if(this.props.approvalSetting[this.approvalSettingName]) {

      if(d.workflow.status == 'draft' && this.props.user.permissionMap[this.checkerPermissionName]) {
        menus.push({
          label:"Proses Check",
          iconClassName:"mdi mdi-format-list-checks",
          onClick: (item) => this.handleCheck(item),
          disabled: true
        })
      }
      if(d.workflow.status == 'checked' && this.props.user.permissionMap[this.approvalPermissionName]) {
        menus.push({
          label:"Proses Approval",
          iconClassName:"mdi mdi-clipboard-check-outline",
          onClick: (item) => this.handleApproval(item)
        })
      }



      if((d.workflow.status == 'approved' || d.workflow.status == 'rejected') && this.props.user.permissionMap[this.approvalPermissionName]) {

        menus.push({
          label:"Batalkan Approval",
          iconClassName:"mdi mdi-undo",
          onClick: (item) => this.unApprove(item)
        })
      }

    } else {
      menus.push({
        label:"Proses Approval",
        iconClassName:"mdi mdi-clipboard-check-outline",
        onClick: (item) => this.handleApproval(item)
      })
    }

    return menus;
  }

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      approvalDialog: false
    }
  }

  handleCheck = async (item)=> {
    var dialog = await this.context.showDialog((props, res, rej) =>({
      initialValue: {},
      height: 'auto',
      width: 300,
      contentStyle: {padding:0, overflowY: 'hidden'},
      okText: 'Submit',
      text: (
        <div style={{height: 300}}>
          <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
            <div className='mpk-font-size-M flex mpk-layout align-center'>Proses Check</div>
          </div>

          <div className="md-grid" >
            <Field
              label         = {counterpart('entities.workflow.action')}
              name          = 'status'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              options       = {[{
                id: 'checked',
                name: 'Approve'
              }, {
                id: 'rejected',
                name: 'Reject'
              }]}
              validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.workflow.message')}
              name          = 'message'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}

              />
          </div>
        </div>
      )
    }));

    if(dialog) {
      await this.service.api.updateWorkflow(item.id, dialog, item);
      iziToast.success({title: 'Success', message: 'Proses check telah berhasil dilakukan'})
      this.fetchData()
    }
  }

  handleApproval = async (item)=> {
    var dialog = await this.context.showDialog((props, res, rej) =>({
      initialValue: {},
      height: 'auto',
      width: 300,
      contentStyle: {padding:0, overflowY: 'hidden'},
      okText: 'Submit',
      text: (
        <div style={{height: 300}}>
          <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
            <div className='mpk-font-size-M flex mpk-layout align-center'>Proses Approval</div>
          </div>

          <div className="md-grid" >
            <Field
              label         = {counterpart('entities.workflow.action')}
              name          = 'status'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              options       = {[{
                id: 'approved',
                name: 'Approve'
              }, {
                id: 'rejected',
                name: 'Reject'
              }]}
              validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.workflow.message')}
              name          = 'message'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}

              />
          </div>
        </div>
      )
    }));

    if(dialog) {
      await this.service.api.updateWorkflow(item.id, dialog, item);
      iziToast.success({title: 'Success', message: 'Proses check telah berhasil dilakukan'})
      this.fetchData()
    }
  }

  unApprove = async (item)=> {
    var dialog = await this.context.showDialog((props, res, rej) =>({
      initialValue: {status: 'draft', message: '-'},
      height: 'auto',
      width: 300,
      // contentStyle: {padding:0, overflowY: 'hidden'},
      okText: 'Submit',
      text: 'Apakah anda yakin akan membatalkan approval data ini?'
    }));

    if(dialog) {
      await this.service.api.updateWorkflow(item.id, dialog, item);
      iziToast.success({title: 'Success', message: 'Proses membatalkan approval telah berhasil dilakukan'})
      this.fetchData()
    }
  }




}
