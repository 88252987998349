import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import IncomeTypeService from './IncomeType.service';
import ListView from '../../../../components/entity/listView';
import IncomeTypeDialog from './IncomeType.dialog';

@connect(IncomeTypeService.stateConnectSetting(), IncomeTypeService.actionConnectSetting())
export default class IncomeTypeView extends ListView {
  service     = IncomeTypeService
  FormDialog  = IncomeTypeDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.incomeType.name",           value: "name"},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.incomeType.alias",          value: "alias"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.incomeType.pasal",          value: "pasal"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.incomeType.buktiPotong",    value: "buktiPotong"},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.incomeType.kap",            value: "kap"},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.incomeType.kjs",            value: "kjs"},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.incomeType.rate",           value: (v)=> (v.rate), type: 'func', }
  ]
}
