import ApiService from '../../../../../services/api.service';

class SalarySlipService extends ApiService {
  name  = 'salarySlip';
  path  = 'pph21/process/salarySlip';
  constructor() {
    super();
    this.init()
  }

  api = {
    generate: async (param)=> {
      var data = await this.http.post(`${this.apiLocation}/pph21/process/pph21Periode-hitung`, param)
      return data
    },
    import: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-import`, data);
      return res;
    },
    download: async (periodeId, employeeId) => {
      let res = await this.http.get(`${this.apiLocation}/${this.path}-print/${periodeId}/${employeeId}`, {
        responseType: 'blob'
      });
      return res;
    }
  }
}


export default new SalarySlipService();
