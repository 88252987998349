import ApiService from '../../../../../services/api.service';

class EmployeeService extends ApiService {
  name  = 'employee';
  path  = 'pph21/master/employee';
  constructor() {
    super();
    this.init()
  }

  api = {
    summary: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/pph21/master/summary-employee`, data, {
        responseType: 'blob'
      })
      return res;
    },
  }
}


export default new EmployeeService();
