import ApiService from '../../../../services/api.service';

class MenuService extends ApiService {
  name  = 'menu';
  path  = 'master/menu';
  constructor() {
    super();
    this.init()
  }

  api = {
    lookup: async (data) => {
      let res = await this.http.get(`${this.apiLocation}/${this.path}-look-up-all`);
      return res;
    }
  }
}


export default new MenuService();
