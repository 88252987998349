import React, { Component } from 'react';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import download from "downloadjs";
import iziToast from 'izitoast';

// import FormDialog from './Item.dialog.view';
import ArchiveBp4a2Service from '../ArchiveBp4a2/ArchiveBp4a2.service';
import ListView from '../../../../components/entity/listView';
import authService from '../../../../services/auth.service';

@connect(ArchiveBp4a2Service.stateConnectSetting(), ArchiveBp4a2Service.actionConnectSetting())
export default class ArchiveBp4a2View extends ListView {
  service     = ArchiveBp4a2Service
  addDialog   = false
  editDialog  = false

  columns=[
    // {isSortable: true,  show: true,   isSearchable:true,  label: "entities.archiveSpt21.category",       value: "category"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.archiveSpt21.masa",           value: "masa",        type: "number"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.archiveSpt21.tahun",          value: "tahun"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.archiveSpt21.pembetulan",     value: "pembetulan",  type: "number"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.archiveSpt21.npwp",           value: "npwp"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.archiveSpt21.name",           value: "name"},
    // {isSortable: true,  show: true,   isSearchable:true,  label: "entities.archiveSpt21.email",          value: "email"},
    // {isSortable: true,  show: true,   isSearchable:true,  label: "entities.archiveSpt21.date",           value: "date"},
    // {isSortable: true,  show: true,   isSearchable:true,  label: "entities.archiveSpt21.bruto",          value: "bruto",       type: "number"},
    // {isSortable: true,  show: true,   isSearchable:true,  label: "entities.archiveSpt21.pph",            value: "pph",         type: "number"},
    // {isSortable: true,  show: true,   isSearchable:true,  label: "entities.archiveSpt21.nomorBp",        value: "nomorBp"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.archiveSpt21.path",         value: "path"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.archiveSpt21.message",     value: "message"}
  ]

  tableActions = [
    // {
    //   label:"More Info",
    //   iconClassName:"mdi mdi-bell",
    //   onClick: (item) => this.editItem(item)
    // },
    {
      label:"Retry",
      iconClassName:"mdi mdi-refresh",
      onClick: (item) => this.retry(item)
    },
    {
      label:"Download",
      iconClassName:"mdi mdi-download",
      onClick: (item) => this.handleDownload(item)
    },
    {label:"divider", iconClassName:"-"},
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  barActions = [
    // {
    //   label:'word.create',
    //   iconClassName:'mdi mdi-plus',
    //   onClick:() => this.addItem()
    // },
    // {
    //   label:'word.delete',
    //   iconClassName:'mdi mdi-delete',
    //   onClick:() => {
    //     this.setState({showDialogConfirmDeleteSelected:true})
    //   },
    //   disabledFunc:() => this.props.table.selected.length === 0
    // },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  async handleDownload(item) {
    let res = await this.service.api.download(item);
    let filename = res.headers['content-disposition'].split('filename=').pop();
    download(res.data, filename);
  }

  async retry(item) {
    let res = await this.service.api.retry(item);

    iziToast.success({
      title: 'success',
      message: `Data dengan id ${item.id} telah di proses ulang.`
    })
  }
}
