/**
 * Created by dwiargo on 11/29/17.
 */

import auth from './authReducer';
import dialog from './dialogReducer';
import global from './globalReducer';
import table from './tableReducer';
import tmpObject from './tmpObjectReducer';
import toast from './toastReducer';

export {
  auth,
  dialog,
  table,
  global,
  tmpObject,
  toast
}