import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './Department.dialog.view';
import DepartmentService from './Department.service';
import ListView from '../../../../../components/entity/listView';
import DepartmentDialog from './Department.dialog';

@connect(DepartmentService.stateConnectSetting(), DepartmentService.actionConnectSetting())
export default class DepartmentView extends ListView {
  service     = DepartmentService
  FormDialog  = DepartmentDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.department.name",                    value: "name"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.department.description",             value: "description",                  className: "mpk-font-size-S"}
  ]
}
