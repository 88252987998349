import ApiService from '../../../../../services/api.service';

class ReligionService extends ApiService {
  name  = 'religion';
  path  = 'pph21/master/religion';
  constructor() {
    super();
    this.init()
  }
}


export default new ReligionService();
