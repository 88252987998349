/**
 * Created by dwiargo on 11/29/17.
 */

import env from 'env';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EditProfileForm from './EditProfile/EditProfile.form';
import EditPasswordForm from './EditPassword/EditPassword.form';
import PPhSummary from './Payrol/Process/Periode/PPh.summary';

const basePath = function() {
  if(env.devMode) {
    return ''
  } else {
    return '/company/:companyId/:subscriptionId/init'
  }
}()

export default (actions, global) => (
  <Switch>
    {/* Admin */}
    <Route path={basePath+"/company/:id"} component={require('./Admin/Company/Company.form').default}/>
    <Route path={basePath+"/company"} component={require('./Admin/Company/Company.view').default}/>
    <Route path={basePath+"/incomeType"} component={require('./Admin/IncomeType/IncomeType.view').default}/>
    <Route path={basePath+"/currency"} component={require('./Admin/Currency/Currency.view').default}/>
    <Route path={basePath+"/user/:id"} component={require('./Admin/User/User.form').default}/>
    <Route path={basePath+"/user-role/:id"} component={require('./Admin/User/UserRole.form').default}/>
    <Route path={basePath+"/user"} component={require('./Admin/User/User.view').default}/>
    <Route path={basePath+"/role/:id"} component={require('./Admin/Role/Role.form').default}/>
    <Route path={basePath+"/role"} component={require('./Admin/Role/Role.view').default}/>
    <Route path={basePath+"/archiveSPT23/:id"} component={require('./Archive/ArchiveBp23/ArchiveBp23.form').default}/>
    <Route path={basePath+"/archiveSPT23"} component={require('./Archive/ArchiveBp23/ArchiveBp23.view').default}/>
    <Route path={basePath+"/archiveSPT21/:id"} component={require('./Archive/ArchiveBp21/ArchiveBp21.form').default}/>
    <Route path={basePath+"/archiveSPT21"} component={require('./Archive/ArchiveBp21/ArchiveBp21.view').default}/>
    <Route path={basePath+"/archiveSPT4a2/:id"} component={require('./Archive/ArchiveBp4a2/ArchiveBp4a2.form').default}/>
    <Route path={basePath+"/archiveSPT4a2"} component={require('./Archive/ArchiveBp4a2/ArchiveBp4a2.view').default}/>
    <Route path={basePath+"/archiveSPT15"} component={require('./Archive/ArchiveBp15/ArchiveBp15.view').default}/>
    <Route path={basePath+"/tarif"} component={require('./Admin/Tarif/Tarif.view').default}/>
    <Route path={basePath+"/documentType"} component={require('./Admin/DocumentType/DocumentType.view').default}/>
    <Route path={basePath+"/logImport"} component={require('./LogImport/LogImport.view').default}/>
    <Route path={basePath+"/logExport"} component={require('./LogExport/LogExport.view').default}/>
    <Route path={basePath+"/logProcess"} component={require('./LogProcess/LogProcess.view').default}/>
    <Route path={basePath+"/appSchedule"} component={require('./Admin/AppSchedule/AppSchedule.view').default}/>

    <Route path={basePath+"/kalkulator/masa"} component={require('./Payrol/Process/kalkulator/Masa.form').default}/>


    {/* PAYROLL ----------------------------------------------------------- */}
    <Route path={basePath+"/religion"} exact component={require('./Payrol/Master/Religion/Religion.view').default}/>
    <Route path={basePath+"/designation"} exact component={require('./Payrol/Master/Designation/Designation.view').default}/>
    <Route path={basePath+"/bank"} exact component={require('./Payrol/Master/Bank/Bank.view').default}/>
    <Route path={basePath+"/department"} exact component={require('./Payrol/Master/Department/Department.view').default}/>
    <Route path={basePath+"/objekPajak"} exact component={require('./Payrol/Master/ObjekPajak/ObjekPajak.view').default}/>
    <Route path={basePath+"/componentType"} exact component={require('./Payrol/Master/ComponentType/ComponentType.view').default}/>
    <Route path={basePath+"/earning"} exact component={require('./Payrol/Master/Earning/Earning.view').default}/>
    <Route path={basePath+"/deduction"} exact component={require('./Payrol/Master/Deduction/Deduction.view').default}/>
    <Route path={basePath+"/pph21Setting/:id"} exact component={require('./Payrol/Master/PPh21Setting/PPh21Setting.form').default}/>
    <Route path={basePath+"/pph21Setting"} exact component={require('./Payrol/Master/PPh21Setting/PPh21Setting.view').default}/>
    <Route path={basePath+"/statusPTKP"} exact component={require('./Payrol/Master/StatusPtkp/StatusPtkp.view').default}/>
    <Route path={basePath+"/employee/:id"} exact component={require('./Payrol/Process/Employee/Employee.form').default}/>
    <Route path={basePath+"/employee"} exact component={require('./Payrol/Process/Employee/Employee.view').default}/>
    <Route path={basePath+"/nonEmployee/:id"} exact component={require('./Payrol/Process/NonEmployee/NonEmployee.form').default}/>
    <Route path={basePath+"/nonEmployee"} exact component={require('./Payrol/Process/NonEmployee/NonEmployee.view').default}/>
    <Route path={basePath+"/salarySlip/:id"} exact component={require('./Payrol/Process/SalarySlip/SalarySlip.form').default}/>
    <Route path={basePath+"/salarySlip"} exact component={require('./Payrol/Process/SalarySlip/SalarySlip.view').default}/>
    <Route path={basePath+"/summary/:type"} exact component={require('./Payrol/Process/SalarySlip/Summary.form').default}/>
    <Route path={basePath+"/import/:type"} exact component={require('./Payrol/Process/PPh21Data/PPh21.import').default}/>

    <Route path={basePath+"/periode"} exact component={require('./Payrol/Process/Periode/Periode.view').default}/>
    <Route path={basePath+"/periode/:periodeId/open"} component={require('./Payrol/Process/Peiode.main').default}/>
    {/* END OF PAYROLL */}

    <Route path={"/summary/:pasal"} render={(props) => (
      <PPhSummary key={props.match.params.pasal} {...props} />
    )} />
    <Route path={basePath+"/summary-bulanan"} exact component={require('./Payrol/Process/Periode/PPh.summaryBulanan').default}/>
    <Route path={basePath+"/summary-tahunan"} exact component={require('./Payrol/Process/Periode/PPh.summaryTahunan').default}/>
    <Route path={basePath+"/"} component={require('./Dashboard/Dashboard.view').default}/>
  </Switch>
)





