import React, { Component } from 'react';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import {Field, reduxForm, getFormValues} from 'redux-form';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield, Fileinput} from '../../../../components/form';
import counterpart from 'counterpart';
import env from 'env';
// import FormDialog from './Company.dialog.view';
import CompanyService from './Company.service';
import ListView from '../../../../components/entity/listView';
import authService from '../../../../services/auth.service';
import iziToast from 'izitoast';
import uploadService from '../upload/upload.service';


export class CompanyView extends ListView {
  service     = CompanyService
  addDialog   = false
  editDialog  = false


  columns=[
    {isSortable: true,  show: true,   isSearchable:true,    label: "entities.company.name",                 value: "name"},
    {isSortable: false, show: true,   isSearchable:false,   label: "entities.company.alias",                value: "alias"},
    {isSortable: true,  show: true,   isSearchable:true,    label: "entities.company.code",                 value: "code"},
    {isSortable: true,  show: true,   isSearchable:true,    label: "entities.company.npwp",                 value: "npwp",                  className: "mpk-font-size-S"},
    // {isSortable: false, show: true,  isSearchable:false,   label: "entities.company.isHO",                 value: "isHO",                  type:'boolean'},
    {isSortable: false, show: true,   isSearchable:false,   label: "entities.company.contact.phone",        value: "contact.phone"},
    {isSortable: false, show: false,  isSearchable:false,   label: "entities.company.contact.email",        value: "contact.email"},
    {isSortable: false, show: false,  isSearchable:false,   label: "entities.company.contact.fax",          value: "contact.fax"},
    {isSortable: false, show: false,  isSearchable:false,   label: "entities.company.address.address",      value: "address.address"}
  ]

  barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  async handleSync() {
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Sync Master Company/Branch dengan ePPT',
      width: 500,
      initialValue: {},
      okText: 'Sync',
      text: 'Apakah anda yakin akan meng-sinkronisasi data company/branch dengan Eppt?'
    }));

    if(d) {
      await this.service.api.sync(d);
      iziToast.success({
        title: 'success',
        message: 'Data telah berhasil di sinkronisasi'
      })
    }
  }

  async handleUpload() {
    var value = await this.context.showDialog((props, res, rej) =>({
      title: 'Import Company/Branch',
      width: 500,
      initialValue: {},
      actions: [{
        children: 'Download Template',
        onClick: ()=> {window.open(`/images/templates/company_import.xlsx`,'_blank'); res(false)}
      },{
        children: 'Cancel',
        onClick: ()=> {res(false)}
      },{
        secondary: true,
        children: 'Import',
        onClick: ()=> {
          var result = props.props.formValue;
          if(!result) result = {}
          res(result)
        }
      }],
      okText: 'Import',
      text:
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.upload.file')}
            name          = 'file'
            className     = 'md-cell md-cell--12'
            component     = {Fileinput}
            // twoLines
            validate      = {validation.required}
          />
      </div>
    }));

    if(value) {
      var uploadData = new FormData();
      uploadData.append('file', value.file)
      var res = await uploadService.api.upload(uploadData);

      if(res.data.name) {
        value = {
          ...value,
          ...res.data,
          delimiter: ';'
        }

        var uploadRes = this.service.api.import(value)

        iziToast.success({
          title: 'success',
          message: counterpart('entities.upload.importSuccess')
        })
      }
    }
  }

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]
}

export default connect(CompanyService.stateConnectSetting(), CompanyService.actionConnectSetting())(CompanyView)
