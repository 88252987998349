/**
 * Created by dwiargo on 2/22/18.
 */

import { httpService } from 'react-mpk';
import utilsService from './utilsService';
import hosts from './hosts';

const companyService = {
	get:(params, addDataItem) => {
		let host = hosts.getAll().iam.host;
    params = utilsService.mapUrlParams(params);
    return httpService.streamGet({
      url:`${host}${hosts.baseUrl}/company`,
      params:params
    }, item => {
      addDataItem(item);
    })
	},
	getById:(id) => {
    return httpService.get({
      url:`${hosts.getAll().iam.host}${hosts.baseUrl}/company/${id}`,
			config:{
				headers:{
					'X-COMPANY-ID':id
				}
			}
    })
  },
	me: (id, subscriptionId ) => {
		return httpService.get({
      url: `/api/iams/company/${id}/${subscriptionId}/me`,
      params: {
        companyId: id
      }
		})
	}
};

export default companyService;
