import React, { Component } from 'react';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';

// import FormDialog from './Item.dialog.view';
import RoleService from './Role.service';
import ListView from '../../../../components/entity/listView';
import authService from '../../../../services/auth.service';

@connect(RoleService.stateConnectSetting(), RoleService.actionConnectSetting())
export default class RoleView extends ListView {
  service     = RoleService
  addDialog   = false
  editDialog  = false

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.role.name",            value: "name",        isDefaultSort:true},
    {isSortable: false, show: false,  isSearchable:false,  label: "entities.role.description",     value: "description"}

  ]
}
