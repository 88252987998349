import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontIcon } from 'react-md';
import Lightbox from 'react-images';
import './ImageThumbnail.scss';

class ImageThumbnail extends Component{
  constructor(){
    super();
    this.state = {
      open:false
    }
  }

  render(){
    let {image, baseUrl="", style, onClick, footer, isSelected, className, removable, onRemove, defaultIconClassName, disabledOpen, borderType} = this.props;
    return(
      <div 
        className={`mpk-image-thumbnail ${onClick?'mpk-cursor-pointer' : ''} ${isSelected?'selected':''} ${className ? className : ''}`} 
      >
        <div 
          className="image-container" 
          style={style ? style : {}}
        >
          {image && image.src ? (
            <img src={image.src} 
              // className={`img ${borderType}`} style={{
              //   background:`url(${baseUrl}${image.src}) center center no-repeat` 
              // }}
              onClick={() => onClick ? onClick(image) : this.setState({open: disabledOpen ? false : true})}
            />
          ) : (
            <div className="default-img mpk-layout justify-center align-center">
              <FontIcon iconClassName={defaultIconClassName}/>
            </div>
          )}
          {removable ? (
            <FontIcon iconClassName="mdi mdi-delete" onClick={() => onRemove ? onRemove(image) : null}/>
          ) : (null)}
        </div>
        {footer ? (
          <div className="mpk-full width">
            {footer}
          </div>
        ) : (null)}
        <Lightbox
          images={[image]}
          isOpen={this.state.open}
          onClose={() => this.setState({open:false})}
        />
      </div>
    )
  }
}

ImageThumbnail.borderTypes = {
  'DEFAULT':'default-border',
  'ROUNDED':'rounded-border',
  'CIRCLE':'circle-border'
}

ImageThumbnail.propTypes = {
  image:PropTypes.shape({
    src:PropTypes.string,
    caption:PropTypes.string
  }),
  onClick:PropTypes.func,
  disabledOpen:PropTypes.bool,
  baseUrl:PropTypes.string,
}

ImageThumbnail.defaultProps = {
  defaultIconClassName: 'mdi mdi-image',
  borderType: ImageThumbnail.borderTypes.DEFAULT
}

export default ImageThumbnail;

