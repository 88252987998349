import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './Designation.dialog.view';
import DesignationService from './Designation.service';
import ListView from '../../../../../components/entity/listView';
import DesignationDialog from './Designation.dialog';

@connect(DesignationService.stateConnectSetting(), DesignationService.actionConnectSetting())
export default class DesignationView extends ListView {
  service     = DesignationService
  FormDialog  = DesignationDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.designation.name",                    value: "name"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.designation.description",             value: "description",                  className: "mpk-font-size-S"}
  ]
}
