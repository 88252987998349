import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import Service from './User.service';
import UserRoleService from '../UserRole/UserRole.service';
import RoleService from '../Role/Role.service';
import CompanyService from '../Company/Company.service';
import UserService from './User.service';
import DepartmentService from '../../Payrol/Master/Department/Department.service';

@reduxForm({form: 'UserForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  formData: getFormValues('UserForm')(state)
}))
export default class UserForm extends FormView {
  viewType    = 2
  service     = UserService
  // viewType    = 2;
  initialData = {
    "login": '',
    "name": "",
    "email": "",
    "password": "123456",
    "kdUnitKerja": "",
    "unitKerja": "",
    "active": true,
    "role": "",
    departmentMap: {}
}

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      departments: []
    }
  }

  async initData() {
    let departmentQ = await DepartmentService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    if(departmentQ.data) {
      this.setState({departments: departmentQ.data.data})
    }

    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      res.data.departmentMap = {}

      let departmentIds = res.data.unitKerja?res.data.unitKerja:""
      let departmentIdMap = departmentIds.split(",").filter(x => x != "").reduce((pv, cv) => {
        pv[cv] = true;
        return pv;
      }, {})

      res.data.departmentMap = departmentIdMap

      this.props.initialize(res.data);
    }
  }

  formView() {
    var map = this.props.formData.companyMap;
    return (<div>
      <div className="md-card md-paper md-paper--1">
      <div className='md-grid'>
        {/* { !this.props.formData.id &&
        <Field
          label         = {'Lokal/Eksternal'}
          name          = 'userType'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          options       = {[
            {id: 'local', name: 'Local'},
            {id: 'external', name: 'External'}
          ]}
          />
        } */}
        <Field
          label         = {counterpart('entities.user.username')}
          name          = 'login'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          />
        {/* <Field
          label         = {counterpart('word.password')}
          name          = 'password'
          className     = 'md-cell md-cell--12'
          type          = 'password'
          component     = {Textfield}
          /> */}
        <Field
          label         = {counterpart('entities.user.name')}
          name          = 'name'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          />
        <Field
          label         = {counterpart('entities.user.email')}
          name          = 'email'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          />
        <Field
          label         = {"Atur Departemen"}
          name          = 'isDepartment'
          className     = 'md-cell md-cell--6'
          component     = {Switch}
          />
        {/* <Field
          label         = {counterpart('entities.user.kdUnitKerja')}
          name          = 'kdUnitKerja'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          />
        <Field
          label         = {counterpart('entities.user.unitKerja')}
          name          = 'unitKerja'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          /> */}
        {/* <Field
          label         = {counterpart('entities.user.active')}
          name          = 'active'
          className     = 'md-cell md-cell--6'
          component     = {Switch}
          />
        <Field
          label         = {counterpart('entities.user.role')}
          name          = 'role'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          options       = {[{
            id: 'user',
            name: 'User'
          },{
            id: 'admin',
            name: 'Admin'
          }]}
          /> */}

      </div>
      </div>
      <br/>

      {this.props.formData.isDepartment && <Card className="md-card md-paper md-paper--1" >
        <DataTable baseId="simple-pagination" plain style={{overflow: 'hidden'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No. </TableColumn>
                <TableColumn>Nama Departemen</TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {this.state.departments.map((d, i)=> {
                return <TableRow key={i} selectable={false}>
                  <TableColumn>{i+1}</TableColumn>
                  <TableColumn>
                    {d.name}
                  </TableColumn>
                  <TableColumn>
                    <div className='mpk-layout'>
                      <div className='flex' />
                      <Field component={Switch} name={`departmentMap.${d.id}`} />
                    </div>
                  </TableColumn>
                </TableRow>
              })}
            </TableBody>
          </DataTable>
        </Card>}
      </div>

    )
  }

  beforeSave(value) {
    value.unitKerja = Object.keys(value.departmentMap).filter(x => value.departmentMap[x]).join(",")

    return value
  }

  async handleSave(value) {
    var beforeSave = await this.beforeSave(value);
    var res = null;
    if(beforeSave) {
      if(typeof beforeSave === 'object') value = beforeSave;
      try {
        this.setState({onProgress: true})
        if(value.id) {
          res = await this.service.api.update(value)
        } else {
          if(value.userType == 'external') {
            res = await this.service.api.saveExternal(value)
          } else {
            res = await this.service.api.save(value)
          }

        }
        this.setState({onProgress: false})
        this.afterSave(res, value)

        var pathArray = this.props.location.pathname.split('/');
        var path = '';
        pathArray.forEach((d, i)=> {
          if(pathArray.length-1 != i) {
            path = path+d;
            if(i != pathArray.length-2) path = path+'/'
          }
        })

        this.props.history.push(path)
      } catch(e) {
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      }
    }
  }
}
