import React from 'react';
import rdxConnect from '../../hoc/rdxConnect';
import MitraDJP from '../MitraDJP';
import './Companies.scss';
import t from 'counterpart';
import renderHTML from 'react-render-html';
import CompanyCard from './__components/CompanyCard';
import SelectSubscription from './__components/SelectSubscription';

class Companies extends React.Component{
  constructor(){
    super();
    this.state = {
      selectedCompany: null
    }
  }

  onSelectCompany = selectedCompany => {
    if(selectedCompany){
      if(this.props.ignoreSelectSubscription){
        this.props.onSelectCompany(selectedCompany);
      }else{
        if(selectedCompany.subscriptions.length === 0){
          this.props.toastActions.izi(
            t.translate('word.sorry'),
            t.translate('sentence.company.noSubscription').replace(/\*companyName/g, selectedCompany.name),
            'warning'
          )
        } else if(selectedCompany.subscriptions.length === 1){
          this.props.onSelectCompany(selectedCompany, selectedCompany.subscriptions[0]);
        } else if(selectedCompany.subscriptions.length > 1){
          this.setState({selectedCompany});
        }
      }
    }
  }

  onSelectSubscription = (company, subscription) => {
    this.props.onSelectCompany(company, subscription);
    this.setState({selectedCompany: null})
  }

  render(){
    let { auth, showGreeting, welcomeHello, welcomeGreeting, welcomeInfo, className, footer, companies, global, iconBaseUrl, ...otherProps } = this.props;
    let { selectedCompany } = this.state;
    // console.log(envStore, 'envStore')
    let { appConsole } = global;
    return(
      <div className={`mpk-companies mpk-full width height mpk-content mpk-padding-N padding-all ${className}`} {...otherProps}>
        <div className={showGreeting ? '' : 'company-center-container'}>
          { showGreeting ? (
            <h1 className="mpk-font-color-primary">
              {welcomeHello ? welcomeHello : t.translate('welcome.hello').replace('*name*', auth.user.name.split(' ')[0])}
            </h1>
          ) : null}
          <div className="mpk-layout column-xs">
            { showGreeting ? (
              <div className="lb flex-none mpk-margin-N margin-right">
                <div className="mpk-position sticky mpk-border right solid dark mpk-padding-M padding-right">
                  <h2>
                    {welcomeGreeting ? welcomeGreeting : renderHTML(t.translate('welcome.greeting'))}
                  </h2>
                  <p className="mpk-font-color-D3">
                    { welcomeInfo ? welcomeInfo : (
                      <span>
                        {t.translate('welcome.info')}
                      </span>
                    )}
                  </p>
                  {footer}
                  <div style={{maxWidth: 120}}>
                    <MitraDJP/>
                  </div>
                  <div style={{paddingTop: 10}}>
                  Version {this.props.global.appInfo.version}
                  </div>
                </div>
              </div>
            ) : null }
            <div className="mpk-layout wrap flex">
              { companies ? companies.map((d, i) => (
                <CompanyCard 
                  item={d} key={`companies-item-${i}`}
                  onSelect={() => this.onSelectCompany(d)}
                  appConsole={appConsole}
                  iconBaseUrl={iconBaseUrl}
                />
                // <div key={`d-${i}`}>{d.name}</div>
              )) : auth.user && auth.user.company ? auth.user.company.map((d,i) => (
                <CompanyCard 
                  item={d} key={`companies-item-${i}`}
                  onSelect={() => this.onSelectCompany(d)}
                  appConsole={appConsole}
                  iconBaseUrl={iconBaseUrl}
                />
              )) : null}
            </div>
          </div>
        </div>
        <SelectSubscription
          visible={selectedCompany ? true : false}
          company={selectedCompany}
          onCancel={() => this.setState({selectedCompany: null})}
          onSelect={this.onSelectSubscription}
        />
      </div>
    )
  }
}

Companies.defaultProps = {
  className:'',
  ignoreSelectSubscription: false,
  showGreeting: true,
  onSelectCompany: (company) => {
    console.log('react-mpk::Companies:On_Select_Company', company);
  }
}

export default rdxConnect(Companies);