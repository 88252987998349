import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import TarifService from './Tarif.service';
import ListView from '../../../../components/entity/listView';
import TarifDialog from './Tarif.dialog';

@connect(TarifService.stateConnectSetting(), TarifService.actionConnectSetting())
export default class TarifView extends ListView {
  service     = TarifService
  FormDialog  = TarifDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.tarif.name",       value: "name",    isDefaultSort:true},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.tarif.code",       value: "code"},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.tarif.value",      value: "value",   type: "number"}
  ]
}
