import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './SalarySlip.dialog.view';
import SalarySlipService from './SalarySlip.service';
import ListView from '../../../../../components/entity/listView';
import uploadService from '../../../Admin/upload/upload.service';
import {Field, reduxForm, getFormValues} from 'redux-form';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield, Fileinput} from '../../../../../components/form';
import counterpart from 'counterpart';
import iziToast from 'izitoast';
import DepartmentService from '../../Master/Department/Department.service';
import download from "downloadjs";

@connect((state) => ({
  ...SalarySlipService.stateConnectSetting()(state),
  periode: state.authHalona.currentSPT,
}), SalarySlipService.actionConnectSetting())
export default class SalarySlipView extends ListView {
  service     = SalarySlipService
  editDialog  = false;
  addDialog   = false;
  // FormDialog  = SalarySlipDialog

  beforeFetch(params) {
    params.periodeId = this.props.periode.data.id
  }

  componentDidMount() {
    this.firstCall = false;

    this.rootPath = this.props.match.url;
  }

  columns=[
    {isSortable: true, show: true,   isSearchable:false, label: "entities.employee.basicInfo.name",          value: "basicInfo.name"},
    {isSortable: true, show: true,   isSearchable:false, label: "entities.employee.basicInfo.npwp",          value: "basicInfo.npwp"},
    {isSortable: true, show: true,   isSearchable:false, label: "entities.salarySlip.salaryType",            value: "salaryType"},
    {isSortable: false, show: false,   isSearchable:true, label: "entities.employee.basicInfo.identityNo",    value: "basicInfo.identityNo"},
    {isSortable: false, show: true,   isSearchable:true, label: "entities.employee.jobInfo.employeeType",    value: (v)=> {
      if(v.otherInfo.tipeKaryawan == "0") {return "KARYAWAN TETAP"} else {return "KARYAWAN TIDAK TETAP/BUKAN KARYAWAN"}
    }, type: 'func'},
    {isSortable: true, show: true,   isSearchable:false, label: "word.date",          value: "paymentDate"},
    {isSortable: false, show: true,   isSearchable:true, label: "entities.employee.jobInfo.employeeNo",    value: "jobInfo.employeeNo"},
    {isSortable: false, show: true,   isSearchable:true, label: "entities.employee.jobInfo.designation",    value: "jobInfo.designation"},

    {isSortable: false, show: true,   isSearchable:true, label: "entities.employee.jobInfo.department",    value: (d)=> {
      if(d.jobInfo.department) { return d.jobInfo.department.name} else {return ""}
    }, type: 'func'},
    {isSortable: true, show: true,   isSearchable:false, label: "entities.salarySlip.status",            value: "status"},
    {isSortable: true, show: true,   isSearchable:false, label: "entities.salarySlip.note",            value: "note"},
    // {isSortable: true, show: true,   isSearchable:false, label: "entities.salarySlip.total",                 value: "total", type: 'number'}
  ]

  barActions = [

    // {
    //   label:'word.create',
    //   iconClassName:'mdi mdi-plus',
    //   onClick:() => this.addItem()
    // },
    {
      label:'word.import',
      iconClassName:'mdi mdi-import',
      onClick:() => this.handleUpload()
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    {label:"divider", iconClassName:"-"},
    {
      label:"Download",
      iconClassName:"mdi mdi-download",
      onClick: (item) => this.handleDownload(item)
    },
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  async handleUpload() {
    var value = await this.context.showDialog((props, res, rej) =>({
      title: 'Import Salary Slip',
      width: 500,
      initialValue: {},
      actions: [{
        children: 'Download Template',
        onClick: ()=> {window.open(`/images/templates/salary_slip.xlsx`,'_blank'); res(false)}
      },{
        children: 'Cancel',
        onClick: ()=> {res(false)}
      },{
        secondary: true,
        children: 'Import',
        onClick: ()=> {
          var result = props.props.formValue;
          if(!result) result = {}
          res(result)
        }
      }],
      okText: 'Import',
      text:
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.upload.file')}
            name          = 'file'
            className     = 'md-cell md-cell--12'
            component     = {Fileinput}
            // twoLines
            validate      = {validation.required}
          />
      </div>
    }));

    if(value) {
      var uploadData = new FormData();
      uploadData.append('file', value.file)
      var res = await uploadService.api.upload(uploadData);

      if(res.data.name) {
        value = {
          ...value,
          ...res.data,
          // todo
          companyId: '99a16478-9b69-4df6-a350-e5adaec6cbb5',
          delimiter: ';'
        }

        var uploadRes = this.service.api.import(value)

        iziToast.success({
          title: 'success',
          message: counterpart('entities.upload.importSuccess')
        })
      }
    }
  }

  async handleDownload(item) {
    // await this.service.api.download(item, true);
    let res = await this.service.api.download(item.periode.id, item.employee.id);
    let filename = res.headers['content-disposition'].split('filename=').pop();
    download(res.data, filename);
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='name'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp'
          className="md-cell md-cell--12"
          component={this.ig.TextfieldMask}
          maskFormat    = "##.###.###.#-###-###"
          mask          = "_"
          length        = {15}
        />
        <this.ig.Field
          label='NIK'
          name='nik'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NRP'
          name='nip'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
         <this.ig.Field
          label='Jenis Karyawan'
          name='tipeKaryawan'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[{id: '0', name: 'KARYAWAN TETAP'}, {id: '1', name: 'KARYAWAN TIDAK TETAP / BUKAN KARYAWAN'}]}
        />
        <this.ig.Field
          label='Jabatan'
          name='designation'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Departemen'
          name='department'
          className="md-cell md-cell--12"
          service={DepartmentService}
          component={this.ig.Searchfield}
        />
      </this.SearchContainer>
    )
  }
}
