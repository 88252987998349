import ApiService from '../../../../../services/api.service';

class StatusPtkpService extends ApiService {
  name  = 'statusPtkp';
  path  = 'pph21/master/statusPTKP';
  constructor() {
    super();
    this.init()
  }
}


export default new StatusPtkpService();
