import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import FormView from '../../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import PPh21TidakFinalService from './PPh21TidakFinal.service';
import StatusPtkpService from '../../Master/StatusPtkp/StatusPtkp.service';
import DesignationService from '../../Master/Designation/Designation.service';
import cloneDeep from 'lodash/cloneDeep'
import EarningService from '../../Master/Earning/Earning.service';
import DeductionService from '../../Master/Deduction/Deduction.service';
import CompanyService from '../../../Admin/Company/Company.service';
import ObjekPajakService from '../../Master/ObjekPajak/ObjekPajak.service';
import NonEmployeeService from '../NonEmployee/NonEmployee.service';
import EmployeeService from '../Employee/Employee.service';

@reduxForm({form: 'PPh21TidakFinalForm', destroyOnUnmount: true, initialValues: {
  earnings: [],
  deductions: [],
  earningMap: {},
  deductionMap: {},
  taxInfo: {}
}})
@connect((state) => ({
  data: getFormValues('PPh21TidakFinalForm')(state),
  periode: state.authHalona.currentSPT
}))
export default class PPh21TidakFinalForm extends FormView {
  service     = PPh21TidakFinalService
  viewType    = 2;

  initialData = {
    basicInfo: {
      npwp: '000000000000000',
      isForeign: false,
      religion: '-'
    },
    "calculation": {
      "totalBruto":  0,
      "totalPPh":  0,
      tarif: 0,
      totalDpp: 0,
      urutan: 1,
      isEncrypted: false
    },

    taxInfo: {
      namaPenghasilan: "NORMAL",
      statusKirim: "belum dikirim",
      tanpaNpwp: true,
      tipePenghasilan: 'BG',
      dipotongOleh: 'SPK',
      caraPembayaran: 'B',
      jumlahHariKerja: 0,
      brutoSebelumnya50Persen: 0,
      bruto: 0,
      phkpSebelumnya:0,
      statusPtkp: 'TK',
      jumlahTanggungan: 0
    },
    otherInfo: {}
  }

  bagBConfig= {
    undefined: {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: false
    },
    '21-100-03': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: true,
      jumlahTanggungan: true,
      caraPembayaran: true,
      jumlahHariKerja: true,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-04': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-05': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-06': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-07': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-08': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-09': {
      tipePenghasilan: true,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-10': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-11': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-12': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-13': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-99': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: false
    },
    '27-100-99': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: true
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      earningType: [],
      deductionType: []
    }
  }

  async initData() {

    var earningRes  = await EarningService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })
    var deductionRes  = await DeductionService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    this.setState({
      earningType: earningRes.data.data,
      deductionType: deductionRes.data.data
    })

    if(this.props.match.params.id == 'new') {
      this.initialData.calculation.urutan = this.props.periode.data.month+"1"
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>
        {this.props.match.params.id != 'new' && <Button primary flat onClick={()=> {this.handleCalculate()}} style={{marginRight: 16}}>{counterpart.translate('word.calculate')}</Button>}
        <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>
      </div>
    )
  }

  formView() {
    var formData = this.props.data
    if(!formData) formData = {taxInfo: {}}
    // console.log(formData)
    return (<div>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.basicInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.employee.title')}
            name          = 'employee'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            params        = {{page: 1, sortBy: 'id', size: 90000000, sort: 'ASC'}}
            valueField    = 'parent'
            service       = {EmployeeService}
            viewField     = 'id'
            onChange      = {(d) => {
              var data = cloneDeep(this.props.data);
              data.nonEmployeeId = d.id
              delete d.id;
              delete d.basicInfo.id;
              delete d.jobInfo.id;

              d.otherInfo = {
                tipePenghasilan: ''
              }
              // delete d.otherInfo.id;
              // delete d.bankInfo;

              var data = {
                ...data,
                ...d,
                taxInfo: d.otherInfo
              }

              data.taxInfo.tanpaNpwp = false
              if(data.basicInfo.npwp == "" || data.basicInfo.npwp == "000000000000000" || data.basicInfo.npwp == null) {
                data.taxInfo.tanpaNpwp = true
              }

              if(data.basicInfo.ptkp) {
                var s = data.basicInfo.ptkp.split("/")
                data.taxInfo.statusPtkp =  s[0]
                data.taxInfo.jumlahTanggungan = parseInt(s[1])
              }

              data.taxInfo.namaPenghasilan= "NORMAL"
              data.taxInfo.statusKirim = "belum dikirim"

              this.props.initialize(data)
            }}
            itemTemplate  = {function(d) {
              return {
                primaryText: d.basicInfo? d.basicInfo.name: '',
                secondaryText: d.basicInfo? d.basicInfo.npwp: '',
                onClick: () => {
                  if(!this.props.disabled){
                    this.handleItemClick(d); this.hide()
                  }
                }
              }
            }}
          />

          <Field
            label         = {counterpart('entities.employee.basicInfo.name')}
            name          = 'basicInfo.name'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.npwp')}
            name          = 'basicInfo.npwp'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            maskFormat    = "##.###.###.#-###-###"
            mask          = "_"
            length        = {15}
            validate      = {validation.required}
            onChange      = {(e, d)=> {
              if(d == "" || d == "000000000000000" || d == null) {
                this.props.change('taxInfo.tanpaNpwp', true)
              } else {
                this.props.change('taxInfo.tanpaNpwp', false)
              }
            }}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.identityNo')}
            name          = 'basicInfo.identityNo'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.address')}
            name          = 'basicInfo.address'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.email')}
            name          = 'basicInfo.email'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.isForeign')}
            name          = 'basicInfo.isForeign'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.country')}
            name          = 'basicInfo.country'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.religion')}
            name          = 'basicInfo.religion'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.ptkp')}
            name          = 'basicInfo.ptkp'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            valueField    = 'name'
            service       = {StatusPtkpService}
            onChange      = {(e, d) => {
              if(d) {
                var s = d.split("/")
                this.props.change("taxInfo.statusPtkp", s[0])
                this.props.change("taxInfo.jumlahTanggungan", parseInt(s[1]))
              }
            }}
          />
        </div>
      </Card>
      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.jobInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.employee.jobInfo.employeeNo')}
            name          = 'jobInfo.employeeNo'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.designation')}
            name          = 'jobInfo.designation'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            service       = {DesignationService}
            valueField    = 'name'
          />
        </div>
      </Card>

      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.nonEmployee.tidakFinalInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.employee.otherInfo.objekPajak')}
            name          = 'taxInfo.objekPajak'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            // valueField    = 'code'
            onChange={(e, v, p)=> {
              if(v === '21-100-08') {
                this.props.change('taxInfo.tipePenghasilan', 'BG')
              } else if(v === '21-100-09') {
                this.props.change('taxInfo.tipePenghasilan', 'TBG')
              } else {
                this.props.change('taxInfo.tipePenghasilan', 'BG')
              }
            }}
            itemTemplate={function(d) {
              return {
                primaryText: d[this.props.viewField],
                secondaryText: d.detail,
                onClick: () => {this.handleItemClick(d); this.hide() }
              }
            }}
            options={[
              {id: '21-100-03', name:'21-100-03', detail: 'Upah Pegawai Tidak tetap atau Tenaga Kerja Lepas'},
              {id: '21-100-04', name:'21-100-04', detail: 'Imbalan Kepada Distributor Multi Level Marketing (MLM)'},
              {id: '21-100-05', name:'21-100-05', detail: 'Imbalan kepada Petugas Dinas Luar Asuransi'},
              {id: '21-100-06', name:'21-100-06', detail: 'Imbalan Kepada Penjaja Barang Dadangan'},
              {id: '21-100-07', name:'21-100-07', detail: 'Imbalan Kepada Tenaga Ahli'},
              {id: '21-100-08', name:'21-100-08', detail: 'Imbalan Kepada Bukan Pegawai yang Menerima Penghasilan yang Bersifat Berkesinambungan'},
              {id: '21-100-09', name:'21-100-09', detail: 'Imbalan Kepada Bukan Pegawai yang Menerima Penghasilan yang Tidak Bersifat Berkesinambungan'},
              {id: '21-100-10', name:'21-100-10', detail: 'Honorarium atau Imbalan Kepada Anggota Dewan Komisaris atau Dewan Pengawas yang tidak Merangkap sebagai Pegawai Tetap'},
              {id: '21-100-11', name:'21-100-11', detail: 'Jasa Produksi, Tantiem, Bonus atau Imbalan Kepada Mantan Pegawai'},
              {id: '21-100-12', name:'21-100-12', detail: 'Penarikan Dana Pensiun oleh Pegawai'},
              {id: '21-100-13', name:'21-100-13', detail: 'Imbalan Kepada Peserta Kegiatan'},
              {id: '21-100-99', name:'21-100-99', detail: 'Objek PPh Pasal 21 Tidak Final Lainnya'},
              {id: '27-100-99', name:'27-100-99', detail: 'Imbalan sehubungan dengan jasa, pekerjaan dan kegiatan, hadiah dan penghargaan, pensiun dan pembayaran berkala lainnya yang dipotong PPh Pasal 26'},]}
          />
          <Field
            label         = {counterpart('entities.employee.otherInfo.taxType')}
            name          = 'taxInfo.taxType'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: 'GROSS',
              name: 'GROSS'
            }, {
              id: 'GROSS UP',
              name: 'GROSS UP'

            }]}
          />

          <div className='md-cell md-cell--6'>
          {this.bagBConfig[formData.taxInfo.objekPajak].tipePenghasilan && <Field
            label         = {counterpart('entities.nonEmployee.tidakFinalInfo.tipePenghasilan')}
            name          = 'taxInfo.tipePenghasilan'
            // className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: "BG",
              name: "BERKESINAMBUNGAN"
            },{
              id: "TBG",
              name: "TIDAK BERKESINAMBUNGAN"
            }]}
          />}
          {this.bagBConfig[formData.taxInfo.objekPajak].dipotongOleh && formData.taxInfo.tipePenghasilan !== 'TBG' && <Field
            label         = {counterpart('entities.nonEmployee.tidakFinalInfo.dipotongOleh')}
            name          = 'taxInfo.dipotongOleh'
            // className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: "SPK",
              name: "SATU PEMBERI KERJA"
            },{
              id: "LSPK",
              name: "LEBIH DARI SATU PEMBERI KERJA"
            }]}
          />}

          {this.bagBConfig[formData.taxInfo.objekPajak].caraPembayaran && <Field
            label         = {counterpart('entities.nonEmployee.tidakFinalInfo.caraPembayaran')}
            name          = 'taxInfo.caraPembayaran'
            // className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: "B",
              name: "BULANAN"
            },{
              id: "TB",
              name: "TIDAK BULANAN"
            }]}
          />}

          {this.bagBConfig[formData.taxInfo.objekPajak].jumlahTanggungan && formData.taxInfo.dipotongOleh !== 'LSPK' && formData.taxInfo.tipePenghasilan !== 'TBG' &&<Field
            label         = {counterpart('entities.nonEmployee.tidakFinalInfo.statusPtkp')}
            name          = 'taxInfo.statusPtkp'
            // className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: "K",
              name: "K"
            },{
              id: "TK",
              name: "TK"
            },{
              id: "K/I",
              name: "K/I"
            }]}
          />}
          {this.bagBConfig[formData.taxInfo.objekPajak].jumlahTanggungan && formData.taxInfo.dipotongOleh !== 'LSPK' && formData.taxInfo.tipePenghasilan !== 'TBG' && <Field
            label         = {counterpart('entities.nonEmployee.tidakFinalInfo.jumlahTanggungan')}
            name          = 'taxInfo.jumlahTanggungan'
            // className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: 0,
              name: "0"
            },{
              id: 1,
              name: "1"
            },{
              id: 2,
              name: "2"
            },{
              id: 3,
              name: "3"
            }]}
          />}
          </div>
          <div className='md-cell md-cell--6'>
          {(this.bagBConfig[formData.taxInfo.objekPajak].jumlahHariKerja) && <Field
            label         = {counterpart('entities.nonEmployee.tidakFinalInfo.jumlahHariKerja')}
            name          = 'taxInfo.jumlahHariKerja'
            // className     = 'md-cell md-cell--12'
            component     = {TextfieldMask}
            money         = ","
          />}
           {this.bagBConfig[formData.taxInfo.objekPajak].phkpSebelumnya && formData.taxInfo.tipePenghasilan !== 'TBG' && formData.taxInfo.dipotongOleh !== 'LSPK' &&<Field
            label         = {counterpart('entities.nonEmployee.tidakFinalInfo.phkpSebelumnya')}
            name          = 'taxInfo.phkpSebelumnya'
            // className     = 'md-cell md-cell--12'
            component     = {TextfieldMask}
            money         = ","
          />}

          {(formData.taxInfo.objekPajak === '21-100-10' || formData.taxInfo.objekPajak ==  '21-100-11' || formData.taxInfo.objekPajak ==  '21-100-12') &&
            <Field
              label         = {counterpart('entities.nonEmployee.tidakFinalInfo.phkpSebelumnya')}
              name          = 'taxInfo.phkpSebelumnya'
              // className     = 'md-cell md-cell--12'
              component     = {TextfieldMask}
              money         = ","
            />
          }

          {this.bagBConfig[formData.taxInfo.objekPajak].brutoSebelumnya50Persen && formData.taxInfo.dipotongOleh !== 'SPK' && formData.taxInfo.tipePenghasilan !== 'TBG' && <Field
            label         = {counterpart('entities.nonEmployee.tidakFinalInfo.brutoSebelumnya50Persen')}
            name          = 'taxInfo.brutoSebelumnya50Persen'
            // className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            money         = ","
          />}
          <Field
            label         = {counterpart('entities.nonEmployee.tidakFinalInfo.tanpaNpwp')}
            name          = 'taxInfo.tanpaNpwp'
            // className     = 'md-cell md-cell--6'
            component     = {Switch}
            money         = ","
          />
          </div>

          <Field
            label         = {'Bruto'}
            name          = 'calculation.bruto'
            className     = 'md-cell md-cell--12  md-text-right'
            component     = {TextfieldMask}
            // disabled
            money         = ","
          />
        </div>
      </Card>

      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.calculation.title')} />
        <Divider/>



        <DataTable plain>
          <TableBody>

          <TableRow>
              <TableColumn>Nama Penghasilan</TableColumn>
              <TableColumn>
              <Field
            label         = {'Nama Penghasilan'}
            name          = 'taxInfo.namaPenghasilan'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: 'NORMAL',
              name: 'NORMAL'
            }, {
              id: 'JASA PRODUKSI',
              name: 'JASA PRODUKSI'
            }]}
            onChange={(d, v)=> {
              if("JASA PRODUKSI") {
                this.props.change('calculation.urutan', this.props.periode.data.month+"2")
              } else {
                this.props.change('calculation.urutan', this.props.periode.data.month+"1")
              }

            }}
          />
              </TableColumn>
            </TableRow>

          <TableRow>
              <TableColumn>Urutan BP (Bulan+Urutan)</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.urutan'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  disabled
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.employee.calculation.totalBruto')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.totalBruto'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  disabled
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.employee.calculation.totalDpp')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.totalDpp'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  disabled
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn >{counterpart('entities.employee.calculation.tarif')}</TableColumn>
              <TableColumn >
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.tarif'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  disabled={!(formData.taxInfo.objekPajak === '21-499-99' || formData.taxInfo.objekPajak === '27-100-99' || formData.taxInfo.objekPajak === '21-100-99')}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.employee.calculation.totalPPh')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.totalPPh'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  disabled
                  money         = ","
                />
              </TableColumn>
            </TableRow>

          </TableBody>
        </DataTable>
      </Card>
    </div>)
  }

  async beforeSave(value) {
    value.periode = this.props.periode.data
    value.calculation.urutan = parseInt(value.calculation.urutan)
    return true
  }

  async handleCalculate() {
    // await this.service.api.update(this.props.data)
    this.props.data.calculation.urutan = parseInt(this.props.data.calculation.urutan)
    await this.service.api.calculate(this.props.data)
    this.initData()
  }
}
