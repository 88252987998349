import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer
} from 'react-md';
import FormView from '../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../components/form/index';
import DashboardService from './Dashboard.service';
import utilsService from '../../../services/utils.service';
import CompanyService from '../Admin/Company/Company.service';

@reduxForm({form: 'DashboardForm', destroyOnUnmount: true})
@connect((state) => ({
  // access      : state.entity.access.api.find,
  company     : state.auth.company,
  formData    : getFormValues('DashboardForm')(state)
}))
export default class DashboardView extends FormView {
  translate = false
  titleHeader = () =>  'Dashboard'
  service = DashboardService
  viewType=2

  initialData={
  }

  constructor(props) {
    super(props)
    this.state = {
      onProgress: false,
      isError: false,
      errorMessage: '',
      limitation: {}
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div>

      </div>
    )
  }

  formView() {
    var styleKaryawan = {backgroundColor: '#60ad5e'}
    if(this.state.limitation.karyawanTerpakai>this.state.limitation.karyawan) {
      styleKaryawan={backgroundColor: '#ff867f'}
    }
    return <div className="md-grid">
      <div className="md-cell md-cell--4">
        <h3>Quota Sobat Upah</h3>
        <List className="md-paper md-paper--1">
          <ListItem style={styleKaryawan} primaryText="KARYAWAN" secondaryText={<div style={{fontSize:18, fontWeight: 'bold'}}>Terpakai {this.state.limitation.karyawanTerpakai?this.state.limitation.karyawanTerpakai:0} dari {(this.state.limitation.karyawan?this.state.limitation.karyawan:'unlimited')}</div>} />
          <ListItem style={{backgroundColor: '#60ad5e'}} primaryText="ORGANISASI" secondaryText={<div style={{fontSize:18, fontWeight: 'bold'}}>{(this.state.limitation.organisasi?this.state.limitation.organisasi:'unlimited')}</div>}  />
          <ListItem style={{backgroundColor: '#60ad5e'}} primaryText="USER" secondaryText={<div style={{fontSize:18, fontWeight: 'bold'}}>{(this.state.limitation.user?this.state.limitation.user:'unlimited')}</div>} />
        </List>
      </div>

    </div>
  }

  async initData() {
    var res = await CompanyService.api.getLimitation();
    this.setState({limitation: res.data})
  }


}
