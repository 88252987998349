import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import LogExportService from './LogExport.service';
import ListView from '../../../components/entity/listView';
import LogExportDialog from './LogExport.dialog'
import download from "downloadjs";

@connect(LogExportService.stateConnectSetting(), LogExportService.actionConnectSetting())
export default class LogExportView extends ListView {
  service     = LogExportService
  FormDialog  = LogExportDialog

  async beforeFetch(params) {

      params.module=["FakturKeluaran", "FakturMasukan", "ReturFakturKeluaran", "ReturFakturMasukan", "Cbs", "Penyusutan Amortisasi", "TEST_MODULE", "Daftar Nominatif Biaya Entertain", "Daftar Nominatif Biaya Promosi", "Invoice", "PPh25", "Export Koreksi Fiskal", "Koreksi Fiskal", "SUMMARY"]
      params.start = "01/01/2018"
      params.end   = "01/01/5020"
    }

  columns=[
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logExport.name",                  value: "name",                 },
      // {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logExport.origin",                value: "origin"},
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logExport.module",                value: "module"},
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logExport.status",                value: "status"},
      {isSortable: false,  show: true,   isSearchable:false,  label: "entities.logExport.description",           value: "description"},
      {isSortable: false,  show: true,   isSearchable:false,  label: "entities.logExport.message",               value: "message"},
      {isSortable: false,  show: true,   isSearchable:false,  label: "entities.logExport.delimiter",             value: "delimiter"},
      // {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logExport.source",                value: "source"},
      {isSortable: false,  show: false,   isSearchable:false,  label: "entities.logExport.total",                 value: "total",                type: "number"},
      // {isSortable: false,  show: false,   isSearchable:false,  label: "entities.logExport.current",               value: "current",              type: "number"},
      // {isSortable: false,  show: false,   isSearchable:false,  label: "entities.logExport.error",                 value: "error",                type: "number"},
      {isSortable: true,  show: true,   isSearchable:false,  label: "entities.logExport.start",                 value: "start",                isDefaultSort: true},
      {isSortable: false,  show: true,   isSearchable:false,  label: "entities.logExport.end",                   value: "end",                  },
      {isSortable: false,  show: false,   isSearchable:false,  label: "entities.logExport.isSourceAvailable",     value: "isSourceAvailable",    type: "boolean"},
      // {isSortable: false,  show: false,   isSearchable:false,  label: "entities.logExport.isErrorAvailable",      value: "isErrorAvailable",     type: "boolean"}
  ]

  tableActions = (d)=> {
    var menu = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    {label:"divider", iconClassName:"-"},
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  if(d.isSourceAvailable) {
    menu.push({
      label:"Download",
      iconClassName:"mdi mdi-download",
      onClick: (item) => this.handleDownload(item)
    })
  }

  return menu;
}

  async handleDownload(item) {
    let res = await this.service.api.download(item);
    let filename = res.headers['content-disposition'].split('filename=').pop();
    console.log(res.headers)
    download(res.data, filename);
  }
}
