import {
  auth, table, global, toast, dialog, tmpObject
} from 'react-mpk/redux/reducers';
import { reducer as formReducer } from 'redux-form'
import image from './imageReducer';

import {combineReducers} from 'redux';

const entityReducer = combineReducers({
  company                          : require('../../modules/Main/Admin/Company/Company.service').default.reducer,
  currency                         : require('../../modules/Main/Admin/Currency/Currency.service').default.reducer,
  user                             : require('../../modules/Main/Admin/User/User.service').default.reducer,
  role                             : require('../../modules/Main/Admin/Role/Role.service').default.reducer,
  archiveSpt23                     : require('../../modules/Main/Archive/ArchiveBp23/ArchiveBp23.service').default.reducer,
  archiveSpt21                     : require('../../modules/Main/Archive/ArchiveBp21/ArchiveBp21.service').default.reducer,
  archiveSpt4a2                    : require('../../modules/Main/Archive/ArchiveBp4a2/ArchiveBp4a2.service').default.reducer,
  archiveSpt15                     : require('../../modules/Main/Archive/ArchiveBp15/ArchiveBp15.service').default.reducer,
  tarif                            : require('../../modules/Main/Admin/Tarif/Tarif.service').default.reducer,
  documentType                     : require('../../modules/Main/Admin/DocumentType/DocumentType.service').default.reducer,
  logImport                        : require('../../modules/Main/LogImport/LogImport.service').default.reducer,
  logExport                        : require('../../modules/Main/LogExport/LogExport.service').default.reducer,
  logProcess                       : require('../../modules/Main/LogProcess/LogProcess.service').default.reducer,
  appSchedule                      : require('../../modules/Main/Admin/AppSchedule/AppSchedule.service').default.reducer,
  // PAYROLL -----------------------------------------------------------------

  religion                          : require('../../modules/Main/Payrol/Master/Religion/Religion.service').default.reducer,
  designation                       : require('../../modules/Main/Payrol/Master/Designation/Designation.service').default.reducer,
  bank                              : require('../../modules/Main/Payrol/Master/Bank/Bank.service').default.reducer,
  department                              : require('../../modules/Main/Payrol/Master/Department/Department.service').default.reducer,
  objekPajak                        : require('../../modules/Main/Payrol/Master/ObjekPajak/ObjekPajak.service').default.reducer,
  componentType                     : require('../../modules/Main/Payrol/Master/ComponentType/ComponentType.service').default.reducer,
  earning                           : require('../../modules/Main/Payrol/Master/Earning/Earning.service').default.reducer,
  deduction                         : require('../../modules/Main/Payrol/Master/Deduction/Deduction.service').default.reducer,
  pph21Setting                      : require('../../modules/Main/Payrol/Master/PPh21Setting/PPh21Setting.service').default.reducer,
  statusPtkp                        : require('../../modules/Main/Payrol/Master/StatusPtkp/StatusPtkp.service').default.reducer,
  employee                          : require('../../modules/Main/Payrol/Process/Employee/Employee.service').default.reducer,
  nonEmployee                       : require('../../modules/Main/Payrol/Process/NonEmployee/NonEmployee.service').default.reducer,
  salarySlip                        : require('../../modules/Main/Payrol/Process/SalarySlip/SalarySlip.service').default.reducer,
  periode                           : require('../../modules/Main/Payrol/Process/Periode/Periode.service').default.reducer,
  pph21Data                         : require('../../modules/Main/Payrol/Process/PPh21Data/PPh21Data.service').default.reducer,
  pph21Final                        : require('../../modules/Main/Payrol/Process/PPh21Final/PPh21Final.service').default.reducer,
  pph21TidakFinal                   : require('../../modules/Main/Payrol/Process/PPh21TidakFinal/PPh21TidakFinal.service').default.reducer,
  a1                           : require('../../modules/Main/Payrol/Process/PPh21A1/PPh21A1.service').default.reducer,
  // END OF PAYROLL ----------------------------------------------------------
})

const rootReducer = combineReducers({
  auth, table, global, toast,
  image, dialog, tmpObject, global,
  form: formReducer,
  auth: auth,
  authHalona: require('../../services/auth.service').default.reducer,
  entity: entityReducer
});

export default rootReducer;
