/**
 * @author Ismail Sunny
 * @email ismiletea@gmail.com
 * @create date 2018-01-17 12:17:15
 * @modify date 2018-01-17 12:17:15
 * @desc [description]
*/
import http from './http.service';
import Service from './service';
import { Field, reduxForm, getFormValues  } from 'redux-form';
import TableService from './table.service';
import { bindActionCreators } from 'redux';
import qs from 'qs';
import env from 'env';

export default class ApiService extends Service {
  reducer;
  apiMockup;
  repoType= 'Default'; //Default || QueryDsl


  // API -------------------------------
	_api = {
		find: async (param={}, dispatch=()=>{}, opt={})=> {
      console.log(this.apiLocation, 'asdads')
      param = {...param};

      delete param.total;

      if(param.page)
        param.page = param.page
      else
        param.page = 1

      if(this.repoType === 'QueryDsl') {
        Object.keys(param).forEach((v, i) => {
          var vArr = v.split('.');
          if(vArr.length === 2) {
            switch (vArr[1]) {
              case 'equals':
                param[vArr[0]] = param[v];
                break;
              case 'contains':
                param[vArr[0]] = '%'+param[v]+'%';
                break;
              default:
                break;
            }
          }
        })
      }

      if(param.column) {
        if(param.column == 'organization.name') {
          param['orgName.contains'] = param.keyword
        } else {
          param[param.column+'.contains'] = param.keyword
        }
      }

      if(param.sort && param.sortBy) {
        if(param.sortBy == "createdDate"){
          param.sortBy = "createdAt"
        }
        var sort  = param.sort;
        if(sort == 'ASC') {
          param.sort = 1
        } else {
          param.sort = -1
        }
      }

      let path = this.path;
      if(opt.path) path = opt.path;

      let res = await http.get(`${this.apiLocation}/${path}`, {params:param, paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }});
      param.page = param.page;
      dispatch({
        type: `${this.name.toUpperCase()}_FIND`,
        data: res.data
      })

      return res
    },

		page: async (param={}, dispatch=()=>{}, opt={})=> {
      param = {...param};

      delete param.total;


      if(param.page)
        param.page = param.page
      else
        param.page = 0

      if(this.repoType === 'QueryDsl') {
        Object.keys(param).forEach((v, i) => {
          var vArr = v.split('.');
          if(vArr.length === 2) {
            switch (vArr[1]) {
              case 'equals':
                param[vArr[0]] = param[v];
                break;
              case 'contains':
                param[vArr[0]] = '%'+param[v]+'%';
                break;
              default:
                break;
            }
          }
        })
      }

      if(param.column) {
        if(param.column == 'organization.name') {
          param['orgName.contains'] = param.keyword
        } else {
          param[param.column+'.contains'] = param.keyword
        }
      }

      if(param.sort && param.sortBy) {
        if(param.sortBy == "createdAt"){
          param.sortBy = "createdDate"
        }
        var sort  = param.sort;
        param.sort = param.sortBy+','+sort;
      }

      let path = this.path;
      if(opt.path) path = opt.path;


      let res = await http.get(`${this.apiLocation}/${path}/page`, {params:param});
      param.page = param.page;
      dispatch({
        type: `${this.name.toUpperCase()}_PAGE`,
        data: res.data
      })

      return res
    },

		findOne: async (id, dispatch=()=> {})=> {
      let res = await http.get(`${this.apiLocation}/${this.path}/${id}`)
      dispatch({
        type: `${this.name.toUpperCase()}_FIND_ONE`,
        data: res.data
      })

      return res;
    },

		save: async (data, dispatch=()=>{})=> {
      let res = await http.post(`${this.apiLocation}/${this.path}`, data);
      dispatch({
        type: `${this.name.toUpperCase()}_SAVE`,
        data: res.data
      })

      return res;
    },

		update: async (data, dispatch=()=>{})=> {
      let res = await http.put(`${this.apiLocation}/${this.path}/${data.id}`, data);
      dispatch({
        type: `${this.name.toUpperCase()}_UPDATE`,
        data: res.data
      })

      return res;
    },

		delete: async (id, dispatch=()=>{})=> {
      let res = await http.delete(`${this.apiLocation}/${this.path}/${id}`);
      dispatch({
        type: `${this.name.toUpperCase()}_DELETE`,
        data: res.data
      })

      return res;
    },

    deleteAll: async (ids, dispatch=()=>{})=> {
      console.log(ids, 'asdsa')
      let res = await http.post(`${this.apiLocation}/${this.path}/delete/bulk`, {ids});
      dispatch({
        type: `${this.name.toUpperCase()}_DELETE`,
        data: res.data
      })

      return res;
    },

    updateFilter: (data, dispatch=()=>{}) => {
      dispatch({
        type: `${this.name.toUpperCase()}_FILTER`,
        data
      });
      return data;
    },

    updateFilterObj: async (data, dispatch=()=>{}) => {
      await dispatch({
        type: `${this.name.toUpperCase()}_FILTER_OBJ`,
        data
      });
      return data;
    }
  }

  _apiMockup={
    find: async (param={}, dispatch=()=>{})=> {
      var res = {
        data: this.apiMockup.find? this.apiMockup.find: [],
        headers: {
          'x-total-count': 5
        }
      }

      return res
    },
    findOne: async (id, dispatch=()=> {})=> {
      return this.apiMockup.findOne? this.apiMockup.findOne: {}
    },
    save: async (data, dispatch=()=>{})=> {
      return {}
    },
    update: async (data, dispatch=()=>{})=> {
      return {}
    },
    delete: async (id, dispatch=()=>{})=> {
      return {}
    }
  }

	initialApiState = {
		find: [],
		page: {
			totals: 0,
			results: []
		},
    data: {},
    filter: [],
    filterObj: {}
	}

	apiReducer() {
		var obj = this;
		return function(state=obj.initialApiState, action) {
			switch (action.type) {
				case `${obj.name.toUpperCase()}_FIND`:
        return {
          ...state,
          find:action.data
        }
				case `${obj.name.toUpperCase()}_PAGE`:
        return {
          ...state,
          page:action.data
        }
				case `${obj.name.toUpperCase()}_FIND_ONE`:
        return {
          ...state,
          data:action.data
        }
				case `${obj.name.toUpperCase()}_SAVE`:
        return {
          ...state,
          data:action.data
        }
				case `${obj.name.toUpperCase()}_UPDATE`:
        return {
          ...state,
          data:action.data
        }
        case `${obj.name.toUpperCase()}_FILTER`:
        return {
          ...state,
          filter:action.data
        }
        case `${obj.name.toUpperCase()}_FILTER_OBJ`:
        return {
          ...state,
          filterObj:action.data
        }
				default:
					return state;
			}
		}
  }



  // END API ---------------------------


  // TABLE -----------------------------
  //** @type {TableService} *//
  TableService:tableService;
  tableReducer;
  tableAction={}
  // END TABLE -------------------------

  // CONNECT ---------------------------
  stateConnectSetting() {
    var obj = this;
    return (state) => {
      return {
        searchForm: getFormValues(`${obj.name.toUpperCase()}_SEARCH`)(state),
        tableFilter: state.entity[this.name].api.filter,
        tableFilter2: getFormValues(obj.name+'_search')(state),
        filterObj: state.entity[this.name].api.filterObj,
        table:state.entity[this.name].table,
        global:state.global
      }
    }
  }

  actionConnectSetting() {
    var obj = this;
    return (dispatch) => {
      return {
        tableActions:bindActionCreators(obj.tableAction, dispatch),
        dispatch: dispatch
      }
    }
  }
  // END OF CONNECT --------------------



  // method
  constructor() {
    super();
    this.init()
  }

  init() {
    this.initTableService()
		this.initApi()
    this.initReducers()
    return this;
  }

  initTableService() {
    var tableService = new TableService(this.name);
    this.tableService = tableService;
    this.tableAction = tableService.tableAction;
    this.tableReducer = tableService.reducer

  }

	initApi() {
		if(this.api) {
			this.api = {
				...this._api,
				...this.api
			}
		} else {
			this.api = this._api
    }

    if(this.apiMockup) {
      this.api = {
        ...this.api,
        ...this._apiMockup
      }
    }
  }

  initReducers() {
    this.reducer = this.combineReducers({
      api: this.apiReducer(),
      table: this.tableReducer
		})
  }

  initTable() {

  }
}
