import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import FormView from '../../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import SalarySlipService from './SalarySlip.service';
import StatusPtkpService from '../../Master/StatusPtkp/StatusPtkp.service';
import DesignationService from '../../Master/Designation/Designation.service';
import EarningService from '../../Master/Earning/Earning.service';
import DeductionService from '../../Master/Deduction/Deduction.service';
import CompanyService from '../../../Admin/Company/Company.service';
import ObjekPajakService from '../../Master/ObjekPajak/ObjekPajak.service';
import EmployeeService from '../Employee/Employee.service';
import DepartmentService from '../../Master/Department/Department.service';
import PeriodeService from '../Periode/Periode.service';
import { PeriodeView } from '../Periode/Periode.view';
import { CompanyView } from '../../../Admin/Company/Company.view';


@reduxForm({form: 'SummaryForm', destroyOnUnmount: true, initialValues: {
  earnings: [],
  deductions: [],
  earningMap: {},
  deductionMap: {}
}})
@connect((state) => ({
  ...PeriodeService.stateConnectSetting()(state),
  data: getFormValues('SummaryForm')(state),
  periode: state.authHalona.currentSPT,
  table2: state.entity.company.table
}))
export default class SummaryForm extends FormView {
  service     = PeriodeService
  viewType    = 2;
  translate   = false;

  titleHeader() {
    if(this.props.match.params.type == 'bulanan') {
      return `Summary ${this.props.match.params.type}`;
    } else if(this.props.match.params.type == 'employee') {
      return `Karyawan`;
    } else {
      return `Printout Slip Gaji`;
    }

  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      earningType: [],
      deductionType: [],
      periodes:[],
      companies: []
    }
  }

  async initData() {
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>

        <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Download</Button>
      </div>
    )
  }


  formView() {
    return (<div>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.salarySlip.basicInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = "Departemen"
            name          = 'departmentId'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            params        = {{page: 1, sortBy: 'id', size: 90000000, sort: 'ASC'}}
            valueField    = 'id'
            service       = {DepartmentService}
          />
          <Field
            label         = "Jabatan"
            name          = 'designation'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            params        = {{page: 1, sortBy: 'id', size: 90000000, sort: 'ASC'}}
            valueField    = 'name'
            service       = {DesignationService}
          />
          {this.props.match.params.type == 'bulanan' && <Field
            label         = "Format"
            name          = 'format'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            params        = {{page: 1, sortBy: 'id', size: 90000000, sort: 'ASC'}}
            valueField    = 'id'
            options       = {[{
              id: '1', name: 'Format 1'
            },{
              id: '2', name: 'Format 2'
            },{
              id: '3', name: 'Realisasi Report'
            }]}
          />}
          {/* <Field
            label         = "Tahun"
            name          = 'tahun'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
            validate      = {validation.required}
          />
          <Field
            label         = {"Bulan"}
            name          = 'month'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options       = {[{
              id: 1,
              name: 'Januari'
            }, {
              id: 2,
              name: 'Februari'
            }, {
              id: 3,
              name: 'Maret'
            }, {
              id: 4,
              name: 'April'
            }, {
              id: 5,
              name: 'Mei'
            }, {
              id: 6,
              name: 'Juni'
            }, {
              id: 7,
              name: 'Juli'
            }, {
              id: 8,
              name: 'Agustus'
            }, {
              id: 9,
              name: 'September'
            }, {
              id: 10,
              name: 'Oktober'
            }, {
              id: 11,
              name: 'November'
            },{
              id: 12,
              name: 'Desember'
            },]}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.salarySlip.paymentDate')}
            name          = 'paymentDate'
            className     = 'md-cell md-cell--12'
            component     = {Datepicker}
          /> */}
        </div>
      </Card>
      <br/>
      {this.props.match.params.type != 'employee' && <Card>
        <div className='md-grid'>
          <Button primary raised onClick={() => this._tambahPeriode()}>Pilih Periode</Button>
        </div>
        <Divider/>

        <List>
          {this.state.periodes.map((x) => {
            return <ListItem key={x.id} primaryText={x.company.npwp+" - "+x.company.name} secondaryText={x.year+" - "+x.month} />
          })}
        </List>
      </Card>}

      {this.props.match.params.type == 'employee' && <Card>
        <div className='md-grid'>
          <Button primary raised onClick={() => this._tambahCompany()}>Pilih Cabang/Perusahaan</Button>
        </div>
        <Divider/>

        <List>
          {this.state.companies.map((x) => {
            return <ListItem key={x.id} primaryText={x.name} secondaryText={x.npwp} />
          })}
        </List>
      </Card>}
    </div>)
  }

  async beforeSave(value) {
    value.ids = this.state.periodes.map(x => x.id)

    if(this.props.match.params.type == 'employee') {
      value.npwps = this.state.companies.map(x => x.npwp)
    }

    return value
  }

  async handleSave(value) {
    var beforeSave = await this.beforeSave(value);
    var res = null;
    if(beforeSave) {
      if(typeof beforeSave === 'object') value = beforeSave;
      try {
        this.setState({onProgress: true})
        if(this.props.match.params.type == 'bulanan') {

          // if(value.format == '3') {
          //   res = await this.service.api.realisasi(value)
          // } else {
          //   res = await this.service.api.bulanan(value)
          // }
          if(value.format == '3') {
            value.typeSummary = "realisasi-report"
          } else {
            value.typeSummary = "summary-bulanan"
          }
          res = await this.service.api.all(value)

        } else if(this.props.match.params.type == 'employee') {
          value.typeSummary = "karyawan"
          res = await this.service.api.all(value)
        } else  {
          value.typeSummary = "slip-printout"
          res = await this.service.api.all(value)
        }

        iziToast.success({title: 'Info', message: 'Data sedang di export, mohon cek status di Log Export'})
        this.setState({onProgress: false})
        this.afterSave(res, value)
        // setTimeout(() => {
        //   var filename = res.headers['content-disposition'].split('filename=').pop();
        // download(res.data, filename)
        // }, 2000)



      } catch(e) {
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      }
    }
  }

  _tambahPeriode = async () => {
    var p = await this.context.showDialog((props, res, rej) =>({
      // title: 'Tambah Pembayaran',
      initialValue: {},
      height: 'auto',
      width: 800,
      contentStyle: {overflow: 'inherit', padding: 0},
      okText: 'Tambah',
      text: <PeriodePicker />

    }))

    if(p) {
      this.setState({periodes: this.props.table.selected})
    }
  }

  _tambahCompany = async () => {
    var p = await this.context.showDialog((props, res, rej) =>({
      // title: 'Tambah Pembayaran',
      initialValue: {},
      height: 'auto',
      width: 800,
      contentStyle: {overflow: 'inherit', padding: 0},
      okText: 'Tambah',
      text: <CompanyPicker />

    }))

    if(p) {
      this.setState({companies: this.props.table2.selected})
    }
  }
}



@connect((state) => ({
  ...PeriodeService.stateConnectSetting()(state),
  approvalSetting: state.authHalona.approvalSetting,
  user: state.authHalona.user
}), PeriodeService.actionConnectSetting())
class PeriodePicker extends PeriodeView {
  viewType=2;
  service=PeriodeService
  translate=false


  titleHeader() {
    return ""
  }


  beforeFetch(params) {
    params.id = this.props.lphId
  }

  _barActions = () =>([
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ])

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }
}

@connect((state) => ({
  ...CompanyService.stateConnectSetting()(state)
}), CompanyService.actionConnectSetting())
class CompanyPicker extends CompanyView {
  viewType=2;
  service=CompanyService
  translate=false


  titleHeader() {
    return ""
  }


  beforeFetch(params) {
    params.id = this.props.lphId
  }

  _barActions = () =>[
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }
}
