import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import UserService from './User.service';
import ListView from '../../../../components/entity/listView';
import authService from '../../../../services/auth.service';
import {Field, reduxForm, getFormValues, initialize} from 'redux-form';

@connect(UserService.stateConnectSetting(), UserService.actionConnectSetting())
export default class UserView extends ListView {
  service     = UserService
  addDialog  = false
  editDialog = false

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.user.login",     value: "login",   isDefaultSort:true},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.user.name",      value: "name"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.user.email",     value: "email"},
    {isSortable: true,  show: true,   isSearchable:false, label: "entities.user.active",    value: "active",   type: "boolean"},
    {isSortable: true,  show: true,   isSearchable:false, label: "entities.user.role",      value: "role"}

  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    // {
    //   label:"Change Role",
    //   iconClassName:"mdi mdi-bell",
    //   onClick: (item) => this.handleChangeRole(item)
    // },
    {label:"divider", iconClassName:"-"},
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  async handleChangeRole(item) {
    await this.props.dispatch(initialize('UserRoleForm', {companys: [], companyMap: {}, menus: [], user: item, hasBranch: false}))
    setTimeout(()=> {
      this.props.history.push('/'+this.service.name+"-role/change");
    }, 500)

  }

}
