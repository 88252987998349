import React from 'react';
import { Card, FontIcon, Button } from 'react-md';
import t from 'counterpart';
import './CompanyCard.scss';

const CompanyCard = ({item, onSelect, appConsole, iconBaseUrl='', hideSettingSubscription=false}) => (
  <Card 
    className="mpk-company-card flex-none mpk-margin-S margin-all"
  >
    <div 
      className="company-card-logo mpk-layout justify-center align-center"
      onClick={() => {
        onSelect(item)
      }}
    >
      {item.icon ? (
        <div style={{background: 'white', width: '100%', height: '100%'}}>
          <img src={`${iconBaseUrl}${item.icon}`} role="presentation"/>
        </div>
      ) : (
        <FontIcon iconClassName="mdi mdi-square-inc"/>
      )}
    </div>
    <div className="company-card-info mpk-padding-N padding-all mpk-dont-break-word">
      <div 
        className="mpk-font-weight-B mpk-font-size-M mpk-link-on-hover mpk-font-color-secondary"
        onClick={() => {
          onSelect(item)
        }}
      >
        {item.name}
      </div>
      <div className="mpk-font-size-NS mpk-font-color-D3">
        {t.translate('sentence.company.status').replace('*tSubscriptions', item.subscriptions.length)}
      </div>
    </div>
      <div className="md-dialog-footer mpk-padding-NS padding-all mpk-layout align-center">
        { appConsole && appConsole.userAccount && !hideSettingSubscription ? (
          <Button
            mini
            icon iconClassName="mdi mdi-settings"
            onClick={() => {
              window.open(`${appConsole.userAccount.url}/company/${item.id}/subscriptions`, '_blank');
            }}
          />
        ) :  null }
        <span className="flex"/>
        <Button 
          mini
          icon primary iconClassName="mdi mdi-arrow-right"
          onClick={() => {
            onSelect(item)
          }}
        />
      </div>
  </Card>
)

export default CompanyCard;