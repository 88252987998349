import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import FormView from '../../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import PPh21DataService from './PPh21Data.service';
import StatusPtkpService from '../../Master/StatusPtkp/StatusPtkp.service';
import DesignationService from '../../Master/Designation/Designation.service';
import cloneDeep from 'lodash/cloneDeep'
import EarningService from '../../Master/Earning/Earning.service';
import DeductionService from '../../Master/Deduction/Deduction.service';
import CompanyService from '../../../Admin/Company/Company.service';
import ObjekPajakService from '../../Master/ObjekPajak/ObjekPajak.service';
import EmployeeService from '../Employee/Employee.service';

@reduxForm({form: 'PPh21DataForm', destroyOnUnmount: true, initialValues: {
  earnings: [],
  deductions: [],
  earningMap: {},
  deductionMap: {}
}})
@connect((state) => ({
  data: getFormValues('PPh21DataForm')(state),
  periode: state.authHalona.currentSPT
}))
export default class PPh21DataForm extends FormView {
  service     = PPh21DataService
  viewType    = 2;

  initialData = {
    basicInfo: {
      npwp: '000000000000000',
      isForeign: false,
      religion: '-'
    },
    otherInfo: {
      statusKirim: "belum dikirim"
    },
    jobInfo: {
      "isMutation": false,
      "isJoining": false,
      "isRetiring": false,
    },
    "calculation": {
      "title": 0,
      "gaji":  0,
      "tunjanganPPh":  0,
      "tunjanganLainnya":  0,
      "honorium":  0,
      "premiAsuransi":  0,
      "kenikmatanLainnya":  0,
      "bonus":  0,
      "jasaProduksi":  0,
      "iuranPensiun":  0,
      "biayaJabatan":  0,
      "totalBruto":  0,
      "totalPPh":  0,
      "slipTunjanganPPh":  0,
      "slipPotonganPPh":  0,
      "pphSebelumnya": 0,
      "nettoSebelumnya": 0
    },
    bankInfo: {}
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      earningType: [],
      deductionType: []
    }
  }

  async initData() {

    var earningRes  = await EarningService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })
    var deductionRes  = await DeductionService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    this.setState({
      earningType: earningRes.data.data,
      deductionType: deductionRes.data.data
    })

    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      res.data.calculation.pphDtp = 0
      if(res.data.calculation.isDtp) { res.data.calculation.pphDtp = res.data.calculation.pphTetap }
      this.props.initialize(res.data);
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>
        {/* {this.props.match.params.id != 'new' && <Button primary flat onClick={()=> {this.handleCalculate()}} style={{marginRight: 16}}>{counterpart.translate('word.calculate')}</Button>} */}
        <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>
      </div>
    )
  }

  formView() {
    return (<div>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.basicInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.employee.title')}
            name          = 'employee'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            params        = {{page: 1, sortBy: 'id', size: 90000000, sort: 'ASC'}}
            valueField    = 'parent'
            service       = {EmployeeService}
            viewField     = 'id'
            onChange      = {(d) => {
              var data = cloneDeep(this.props.data);
              delete d.id;
              delete d.basicInfo.id;
              delete d.jobInfo.id;
              delete d.otherInfo.id;
              delete d.bankInfo;
              d.otherInfo.statusKirim = data.otherInfo.statusKirim;

              var data = {
                ...data,
                ...d
              }

              this.props.initialize(data)
            }}
            itemTemplate  = {function(d) {
              return {
                primaryText: d.basicInfo? d.basicInfo.name: '',
                secondaryText: d.basicInfo? d.basicInfo.npwp: '',
                onClick: () => {
                  if(!this.props.disabled){
                    this.handleItemClick(d); this.hide()
                  }
                }
              }
            }}
          />

          <Field
            label         = {counterpart('entities.employee.basicInfo.name')}
            name          = 'basicInfo.name'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.npwp')}
            name          = 'basicInfo.npwp'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            maskFormat    = "##.###.###.#-###-###"
            mask          = "_"
            length        = {15}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.identityNo')}
            name          = 'basicInfo.identityNo'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.address')}
            name          = 'basicInfo.address'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.email')}
            name          = 'basicInfo.email'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.isForeign')}
            name          = 'basicInfo.isForeign'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.country')}
            name          = 'basicInfo.country'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.religion')}
            name          = 'basicInfo.religion'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.ptkp')}
            name          = 'basicInfo.ptkp'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            valueField    = 'name'
            service       = {StatusPtkpService}
          />
        </div>
      </Card>

      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.otherInfo.title')} />
        <Divider/>
        <div className='md-grid'>
        <Field
            label         = {"Status Pindahan"}
            name          = 'otherInfo.statusPindahan'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: '0',
              name: 'Normal'
            }, {
              id: '1',
              name: 'Dipindahkan ke cabang lain'
            }, {
              id: '2',
              name: 'Berhenti ke perusahaan lain/pensiun'
            }, {
              id: '3',
              name: 'Berhenti meninggal/pindah ke LN/dari LN'
            }, {
              id: '4',
              name: 'Pegawai baru'
            }, {
              id: '5',
              name: 'Pindahan'
            }]}
            onChange      = {(e, d)=> {
              switch (d) {
                case '0':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                case '1':
                  this.props.change('jobInfo.isMutation', true)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '2':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '3':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '4':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', true)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                case '5':
                  this.props.change('jobInfo.isMutation', true)
                  this.props.change('jobInfo.isJoining', true)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                default:
                  break;
              }
            }}
          />
          <Field
            label         = {counterpart('entities.employee.otherInfo.taxType')}
            name          = 'otherInfo.taxType'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options       = {[{
              id: 'GROSS',
              name: 'GROSS'
            }, {
              id: 'GROSS UP',
              name: 'GROSS UP'

            }, {
              id: 'NET',
              name: 'NET'
            }]}
          />
          <Field
            label         = {counterpart('entities.employee.otherInfo.objekPajak')}
            name          = 'otherInfo.objekPajak'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            valueField    = 'id'
            params        = {{page: 1, sortBy: 'code', size: 90000000, sort: 'ASC'}}
            // service       = {ObjekPajakService}
            options       = {[
              {id: '21-100-01', code: '21-100-01', name:'Pegawai Tetap', detail: ''},
              {id: '21-100-02', code: '21-100-01', name:'Penerimaan Pensiun Secara Teratur', detail: ''}
            ]}
            itemTemplate  = {function(d) {
              return {
                primaryText: `${d.name}`,
                secondaryText: d.id,
                onClick: () => {
                  if(!this.props.disabled){
                    this.handleItemClick(d); this.hide()
                  }
                }
              }
            }}
          />
        </div>
      </Card>

      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.jobInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.employee.jobInfo.employeeNo')}
            name          = 'jobInfo.employeeNo'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.status')}
            name          = 'jobInfo.status'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options       = {[{
              id: 'ACTIVE',
              name: 'ACTIVE'
            }, {
              id: 'LEFT',
              name: 'LEFT'
            }]}
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.designation')}
            name          = 'jobInfo.designation'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            service       = {DesignationService}
            valueField    = 'name'
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.isMutation')}
            name          = 'jobInfo.isMutation'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.mutationDate')}
            name          = 'jobInfo.mutationDate'
            className     = 'md-cell md-cell--6'
            component     = {Datepicker}
          />

          <Field
            label         = {counterpart('entities.employee.jobInfo.isJoining')}
            name          = 'jobInfo.isJoining'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.joinedDate')}
            name          = 'jobInfo.joinedDate'
            className     = 'md-cell md-cell--6'
            component     = {Datepicker}
          />

          <Field
            label         = {counterpart('entities.employee.jobInfo.isRetiring')}
            name          = 'jobInfo.isRetiring'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.retireDate')}
            name          = 'jobInfo.retireDate'
            className     = 'md-cell md-cell--6'
            component     = {Datepicker}
          />

          <Field
            label         = {counterpart('entities.employee.jobInfo.note')}
            name          = 'note'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />

        </div>
      </Card>

      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.calculation.title')} />
        <Divider/>

        <DataTable plain>
          <TableBody>
            <TableRow>
              <TableColumn>{counterpart('entities.employee.calculation.gaji')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.gaji'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.employee.calculation.tunjanganPPh')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.tunjanganPPh'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.employee.calculation.tunjanganLainnya')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.tunjanganLainnya'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.employee.calculation.honorium')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.honorium'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.employee.calculation.premiAsuransi')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.premiAsuransi'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.employee.calculation.kenikmatanLainnya')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.kenikmatanLainnya'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.employee.calculation.bonus')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.bonus'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            {/* <TableRow>
              <TableColumn>{counterpart('entities.employee.calculation.jasaProduksi')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.jasaProduksi'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow> */}

            <TableRow>
              <TableColumn style={{borderTop: '3px solid black'}}>{counterpart('entities.employee.calculation.iuranPensiun')}</TableColumn>
              <TableColumn style={{borderTop: '3px solid black'}}>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.iuranPensiun'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.employee.calculation.biayaJabatan')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.biayaJabatan'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  disabled
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn style={{borderTop: '3px solid black'}}>{counterpart('entities.employee.calculation.totalBruto')}</TableColumn>
              <TableColumn style={{borderTop: '3px solid black'}}>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.totalBruto'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  disabled
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.employee.calculation.totalPPh')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.totalPPh'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  disabled
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>Apakah mendapat fasilitas DTP</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.isDtp'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {Switch}
                  // disabled
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>Pajak atas penghasilan Tetap</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.pphTetap'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  disabled
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>Pajak atas penghasilan Tidak Tetap</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.pphTidakTetap'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  disabled
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn style={{borderTop: '3px solid black'}}>{counterpart('entities.employee.calculation.slipTunjanganPPh')}</TableColumn>
              <TableColumn style={{borderTop: '3px solid black'}}>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.slipTunjanganPPh'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  disabled
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.employee.calculation.slipPotonganPPh')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.slipPotonganPPh'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  disabled
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>PPh yang ditanggung pemerintah</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.pphDtp'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  disabled
                  money         = ","
                />
              </TableColumn>
            </TableRow>



          </TableBody>
        </DataTable>
      </Card>
    </div>)
  }

  async beforeSave(value) {
    value.periode = this.props.periode.data
    return true
  }

  async handleCalculate() {
    await this.service.api.update(this.props.data)
    await this.service.api.calculate(this.props.data.id)
    this.initData()
  }
}
