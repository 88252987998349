import * as CONSTANT from '../../config/constant';

const mainMenus = [{
  label: {
    id: 'Menu Utama',
    en: 'Main'
  },
  children: [{
    label: {
      id: 'Dashboard',
      en: 'Dashboard'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/dashboard',
    // role: 'Melihat halaman koreksi fiskal'
  }]
},
{
  label: {
    id: 'Kalkulator',
    en: 'Kalkulator'
  },
  children: [{
    label: {
      id: 'PPh 21 Masa',
      en: 'PPh 21 Masa'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/kalkulator/masa',
    // role: 'Melihat halaman koreksi fiskal'
  }]
},
// {
//   label: {
//     id: 'Entitas',
//     en: 'Entity'
//   },
//   children: [{
//     label: {
//       id: 'Perusahaan',
//       en: 'Company'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/company',
//     admin: true
//   },{
//     label: {
//       id: 'Lawan Transaksi',
//       en: 'Lawan Transaksi'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/lawanTransaksi',
//     // admin: true
//   },{
//     label: {
//       id: 'Jenis Dokumen',
//       en: 'Document Type'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/documentType',
//     // admin: true
//   }]
// },
// {
//   label: {
//     id: 'Barang dan Jasa',
//     en: 'Item'
//   },
//   children: [{
//     label: {
//       id: 'Barang dan Jasa',
//       en: 'Item'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/item',
//     admin: true
//   }, {
//     label: {
//       id: 'Jenis Satuan',
//       en: 'Unit'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/unit',
//     admin: true
//   },{
//     label: {
//       id: 'Jenis Item',
//       en: 'Item Type'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/itemType',
//     admin: true
//   },{
//     label: {
//       id: 'Satuan Periode',
//       en: 'Period Unit'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/periodUnit',
//     admin: true
//   },{
//     label: {
//       id: 'Mata Uang',
//       en: 'Mata Uang'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/currency',
//     admin: true
//   }]
// },
// {
//   label: {
//     id: 'COA',
//     en: 'COA'
//   },
//   children: [{
//     label: {
//       id: 'COA',
//       en: 'COA'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/coa',
//     admin: true
//   },{
//     label: {
//       id: 'COA Daftar Nominatif Biaya Promosi',
//       en: 'COA Daftar Nominatif Biaya Promosi'
//       },
//       iconClassName: 'mdi mdi-view-grid',
//       path: '/coaDaftarNominatifBiayaPromosi',
//       admin: true

//   },{
//     label: {
//       id: 'COA Daftar Nominatif Biaya Entertain',
//       en: 'COA Daftar Nominatif Biaya Entertain'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/coaDaftarNominatifBiayaEntertain',
//     admin: true
//   },{
//     label: {
//       id: 'COA Bagi Hasil Bunga Deposito',
//       en: 'COA Bagi Hasil Bunga Deposito'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/coa-bunga-deposito',
//     admin: true
//   }, {
//     label: {
//       id: 'Jenis Penghasilan',
//       en: 'Income Type'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/incomeType',
//     admin: true
//   },{
//     label: {
//       id: 'Tarif PPh Pasal 29 & Pasal 25',
//       en: 'Tarif'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/tarif',
//     admin: true
//   },
  // {
  //   label: {
  //     id: 'Penomoran Bp4a2',
  //     en: 'Penomoran Bp4a2'
  //   },
  //   iconClassName: 'mdi mdi-view-grid',
  //   path: '/penomoranBp4a2',
  //   admin: true
  // },{
  //   label: {
  //     id: 'Penomoran Bp23',
  //     en: 'Penomoran Bp23'
  //   },
  //   iconClassName: 'mdi mdi-view-grid',
  //   path: '/penomoranBp23',
  //   admin: true
  // }
// ]
// },
{
  label: {
    id: 'Data Master',
    en: 'Master Data'
  },
  children: [
  {
    label: {
      id: 'Perusahaan',
      en: 'Company'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/company',
    // admin: true
  },
  {
    label: {
      id: 'User',
      en: 'User'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/user',
    admin: true
  },
  {
    label: {
      id: 'Jabatan',
      en: 'Designation'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/designation',
    // admin: true
  },
  {
    label: {
      id: 'Departemen',
      en: 'Departemen'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/department',
    // admin: true
  },
  {
    label: {
      id: 'Pendapatan',
      en: 'Earning'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/earning',
    // admin: true
  },{
    label: {
      id: 'Potongan',
      en: 'Deduction'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/deduction',
    // admin: true
  },
  {
    label: {
      id: 'Agama',
      en: 'Religion'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/religion',
    // admin: true
  },{
    label: {
      id: 'Bank',
      en: 'Bank'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/bank',
    // admin: true
  },{
    label: {
      id: 'Objek Pajak',
      en: 'Objek Pajak'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/objekPajak',
    // admin: true
  },{
    label: {
      id: 'Jenis Komponen',
      en: 'Tax Type'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/componentType',
    // admin: true
  }
  ,{
    label: {
      id: 'Pengaturan PPh 21',
      en: 'PPh 21 Setting'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/pph21Setting',
    // admin: true
  },{
    label: {
      id: 'Status PTKP',
      en: 'Status PTKP'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/statusPTKP',
    // admin: true
  }]
  },
  {
    label: {
      id: 'Penggajian',
      en: 'Payroll'
    },
    children: [{
      label: {
        id: 'Karyawan',
        en: 'Employee'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/employee',
      // admin: true
    }, {
      label: {
        id: 'Periode',
        en: 'Period'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/periode',
      // role: 'Melihat halaman PPh 21 Periode',
      // user: true
    }]
  },
  {
    label: {
      id: 'Import',
      en: 'Import'
    },
    children: [{
      label: {
        id: 'Departemen',
        en: 'Department'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/import/departemen',
      // admin: true
    },{
      label: {
        id: 'Jenis Pendapatan',
        en: 'Earning Type'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/import/pendapatan',
      // admin: true
    },{
      label: {
        id: 'Jenis Potongan',
        en: 'Deduction Type'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/import/potongan',
      // admin: true
    },
      {
      label: {
        id: 'Karyawan',
        en: 'Employee'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/import/karyawan',
      // admin: true
    },
    {
      label: {
        id: 'Slip Gaji',
        en: 'Salary Slip'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/import/slipgaji',
      // admin: true
    },
    // {
    //   label: {
    //     id: 'Log Import',
    //     en: 'Log Import'
    //   },
    //   iconClassName: 'mdi mdi-view-grid',
    //   path: '/logImport',
    //   // user: true
    // }
  ]
  },

  {
    label: {
      id: 'Laporan',
      en: 'Report'
    },
    children: [
      {
      label: {
        id: 'Summary Bulanan',
        en: 'Summary Bulanan'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/summary/bulanan',
      // admin: true
    },
    {
      label: {
        id: 'Karyawan',
        en: 'Employee'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/summary/employee',
      // admin: true
    },
    // {
    //   label: {
    //     id: 'Summary Tahunan',
    //     en: 'Summary Tahunan'
    //   },
    //   iconClassName: 'mdi mdi-view-grid',
    //   path: '/summary/tahunan',
    //   // admin: true
    // },
    {
      label: {
        id: 'Print Slip Gaji',
        en: 'Print Salary Slip'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/summary/salarySlip'
      // admin: true
    }
  ]
  },
//   {
//   label: {
//     id: 'User',
//     en: 'User'
//   },
//   children: [{
//     label: {
//       id: 'User',
//       en: 'User'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/user',
//     admin: true
//   },{
//     label: {
//       id: 'Role',
//       en: 'Role'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/role',
//     admin: true
//   }]
// },{
//   label: {
//     id: 'Approval',
//     en: 'Approval'
//   },
//   children: [{
//     label: {
//       id: 'Approval Setting',
//       en: 'Approval Setting'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/approvalSetting',
//     admin: true
//   }]
// },
// {
//   label: {
//     id: 'Fiskal',
//     en: 'Fiskal'
//   },
//   children: [{
//     label: {
//       id: 'Koreksi Fiskal',
//       en: 'Koreksi Fiskal'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/koreksiFiskal',
//     user: true
//   },{
//     label: {
//       id: 'Penyusutan & Amortisasi',
//       en: 'Penyusutan & Amortisasi'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/penyusutanAmortisasi',
//     role: 'Melihat halaman penyusutan & amortisasi',
//     user: true
//   },{
//     label: {
//       id: 'Impor Penyusutan & Amortisasi',
//       en: 'Penyusutan Amortisasi & Import'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     role: 'Melakukan import penyusutan & amortisasi',
//     path: '/import-penyusutanAmortisasi',
//     user: true
//   },{
//     label: {
//       id: 'Jenis Harta',
//       en: 'Property Type'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/jenisHarta',
//     admin: true
//   },{
//     label: {
//       id: 'Kelompok Harta',
//       en: 'Kelompok Harta'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/kelompokHarta',
//     admin: true
//   },{
//     label: {
//       id: "Jenis Usaha",
//       en: "Business Type"
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/jenisUsaha',
//     admin: true
//   },{
//     label: {
//       id: 'Harta',
//       en: 'Harta'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/harta',
//     admin: true
//   },{
//     label: {
//       id: "Metode Penyusutan Komersial",
//       en: "Commercial Depreciation Methode"
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/metodePenyusutanKomersial',
//     admin: true
//   },{
//     label: {
//       id: "Metode Penyusutan Fiskal",
//       en: "Fiscal Depreciation Methode"
//     },
//     iconClassName: "mdi mdi-view-grid",
//     path: '/metodePenyusutanFiskal',
//     admin: true
//   },{
//     label: {
//       id: 'Pengaturan Koreksi Fiskal',
//       en: 'Koreksi Fiskal Setting'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/koreksiFiskalSetting',
//     admin: true
//   }]
// }, {
//   label: {
//     id: 'Transaksi',
//     en: 'Transaction'
//   },
//   children: [{
//     label: {
//       id: 'Perjanjian/PO',
//       en: 'Agreement/PO',
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     role: 'Melihat halaman perjanjian',
//     path: '/perjanjian',
//     user: true
//   },{
//     label: {
//       id: "Receipt",
//       en: "Receipt"
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     role: 'Melihat detail receipt',
//     path: '/receipt',
//     user: true
//   }, {
//     label: {
//       id: 'Invoice',
//       en: 'Invoice'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     role: 'Melihat halaman invoice',
//     path: '/invoice',
//     user: true
//   }]
// }, {
//   label: {
//     id:'CBS',
//     en: 'CBS'
//   },
//   children: [{
//     label: {
//       id: 'CBS',
//       en: 'CBS'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/cbs',
//     role: 'Melihat halaman cbs',
//     user: true
//   },{
//     label: {
//       id: 'Impor CBS',
//       en: 'Import CBS'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     role: 'Melakukan import cbs',
//     path: '/import-cbs',
//     user: true
//   }]
// },{
//   label: {
//     id: 'Archive SPT',
//     en: 'Archive SPT'
//   },
//   children: [{
//     label: {
//       id: 'Archive SPT 21',
//       en: 'Archive SPT 21'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/archiveSPT21',
//     role: 'Melihat halaman archive spt21',
//     user: true
//   },{
//     label: {
//       id: 'Archive SPT 23',
//       en: 'Archive SPT 23'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/archiveSPT23',
//     role: 'Melihat halaman archive spt23',
//     user: true
//   },{
//     label: {
//       id: 'Archive SPT 4(2)',
//       en: 'Archive SPT 4(2)'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     role: 'Melihat halaman archive spt4a2',
//     path: '/archiveSPT4a2',
//     user: true
//   },
//   {
//     label: {
//       id: 'Summary PPh 21',
//       en: 'Summary PPh 21'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/summary/21',
//     role: 'Melihat halaman archive spt23',
//     user: true
//   },
//   {
//     label: {
//       id: 'Summary PPh 23',
//       en: 'Summary PPh 23'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/summary/23',
//     role: 'Melihat halaman archive spt4a2',
//     user: true
//   },
//   {
//     label: {
//       id: 'Summary PPh 4(2)',
//       en: 'Summary PPh 4(2)'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/summary/4A2',
//     role: 'Melihat halaman archive spt21',
//     user: true
//   },
//   {
//     label: {
//       id: 'Summary Bulanan',
//       en: 'Summary Bulanan'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/summary-bulanan',
//     role: 'Melihat halaman archive spt21',
//     user: true
//   },
//   {
//     label: {
//       id: 'Summary Tahunan',
//       en: 'Summary Tahunan'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/summary-tahunan',
//     role: 'Melihat halaman archive spt21',
//     user: true
//   }
  // {
  //   label: {
  //     id: 'Archive SPT 25',
  //     en: 'Archive SPT 25'
  //   },
  //   iconClassName: 'mdi mdi-view-grid',
  //   path: '/archiveSPT25',
  //   user: true
  // }
// ]
// },
{
  label: {
    id: 'Log Aplikasi',
    en: 'Application Log'
  },
  children: [{
    label: {
      id: 'Log Import',
      en: 'Log Import'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/logImport',
    // user: true
  },{
    label: {
      id: 'Log Export',
      en: 'Log Export'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/logExport',
    // user: true
  }, {
    label: {
      id: 'Log Proses',
      en: 'Log Process'
    },
      iconClassName: 'mdi mdi-view-grid',
      path: '/logProcess',
      // user: true
    }]
}
// {
//   label: {
//     id: 'Pengaturan',
//     en: 'Setting'
//   },
//   children: [{
//     label: {
//       id: 'App Schedule',
//       en: 'App Schedule'
//     },
//     iconClassName: 'mdi mdi-view-grid',
//     path: '/appSchedule',
//     admin: true
//   }]
// }
]

const koreksiFiskalMenus = [{
  label: {
    id: 'Menu Utama',
    en: 'Main Menu'
  },
  children: [
    {
      label: {
        id: 'Bagi Hasil Deposito, Tabungan dan Giro',
        en: 'Bagi Hasil Deposito, Tabungan dan Giro'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/bungaDeposito',
      role: 'Melihat halaman bunga deposito tabungan',
      // user: true
    },
    {
      label: {
        id: 'Rekonsilisasi Fiskal',
        en: 'Rekonsilisasi Fiskal'
      },
      iconClassName: 'mdi mdi-view-grid',
      role: 'Melihat halaman koreksi fiskal',
      path: '/koreksiFiskalBulan',
      user: true
    },{
      label: {
        id: 'Perhitungan PPh 25',
        en: 'Perhitungan PPh 25'
      },
      iconClassName: 'mdi mdi-view-grid',
      role: 'Melihat halaman pph25',
      path: '/pph25',
      user: true
    }
  ]
},{
  label: {
    id: 'Daftar Nominatif',
    en: 'Nominatif List'
  },
  children: [

    {
      label: {
        id: 'Biaya Promosi',
        en: 'Biaya Promosi'
      },
      iconClassName: 'mdi mdi-view-grid',
      role: 'Melihat halaman daftar nominatif biaya promosi',
      path: '/biayaPromosi',
      user: true
    },{
      label: {
        id: 'Biaya Entertain',
        en: 'Biaya Entertain'
      },
      iconClassName: 'mdi mdi-view-grid',
      role: 'Melihat halaman daftar nominatif biaya entertain',
      path: '/biayaEntertain',
      user: true
    }
  ]},{
    label: {
      id: 'Log',
      en: 'Log'
    },
    children: [ {
      label: {
        id: 'Log Export',
        en: 'Log Export'
      },
        iconClassName: 'mdi mdi-view-grid',
        path: '/logExport',
        user: true
      }, {
      label: {
        id: 'Log Impor',
        en: 'Log Import'
      },
        iconClassName: 'mdi mdi-view-grid',
        path: '/logImport',
        user: true
      }, {
        label: {
          id: 'Log Proses',
          en: 'Log Process'
        },
          iconClassName: 'mdi mdi-view-grid',
          path: '/logProcess',
          user: true
        }]
  }
]

const periodeMenus = [{
  label: {
    id: 'Slip Gaji',
    en: 'Slip Gaji'
  },
  children: [
  //   {
  //   label: {
  //     id: 'Dashboard',
  //     en: 'Dashboard'
  //   },
  //   iconClassName: 'mdi mdi-view-grid',
  //   path: '/dashboard',
  //   // user: true
  // },
  {
    label: {
      id: 'Daftar Slip',
      en: 'Slip List'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/salarySlip',
    // user: true
  }, {
    label: {
      id: 'Generate Slip',
      en: 'Generate Slip'
    },
    iconClassName: 'mdi mdi-view-grid',
    path: '/generateSlip',
    // user: true
  }]
}, {
  label: {
    id: 'PPh',
    en: 'PPh'
  },
  children: [
    // {
    //   label: {
    //     id: 'Slip Gaji',
    //     en: 'Salary Slip'
    //   },
    //   iconClassName: 'mdi mdi-view-grid',
    //   path: '/salarySlip',
    //   user: true
    // },
    {
      label: {
        id: 'PPh 21 Satu Masa',
        en: 'PPh 21 Satu Masa'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/pph21Data',
      // user: true
    },
    {
      label: {
        id: 'PPh 21 Final',
        en: 'PPh 21 FInal'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/pph21DataFinal',
      // user: true
    },
    {
      label: {
        id: 'PPh 21 Tidak Final',
        en: 'PPh 21 Tidak Final'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/pph21DataTidakFinal',
      // user: true
    },
    {
      label: {
        id: 'PPh 21 A1',
        en: 'PPh 21 A1'
      },
      iconClassName: 'mdi mdi-view-grid',
      path: '/pph21A1',
      // user: true
    }
  ]
},{
  label: {
    id: 'Log Aplikasi',
    en: 'Log Aplikasi'
  },
  children: [{
    label: {
      id: 'Log Impor',
      en: 'Log Import'
    },
      iconClassName: 'mdi mdi-view-grid',
      path: '/logImport',
      user: true
    }, {
      label: {
        id: 'Log Proses',
        en: 'Log Process'
      },
        iconClassName: 'mdi mdi-view-grid',
        path: '/logProcess',
        user: true
      }]
}]


export default {
  mainMenus,
  koreksiFiskalMenus,
  periodeMenus
};
