import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './Religion.dialog.view';
import ReligionService from './Religion.service';
import ListView from '../../../../../components/entity/listView';
import ReligionDialog from './Religion.dialog';

@connect(ReligionService.stateConnectSetting(), ReligionService.actionConnectSetting())
export default class ReligionView extends ListView {
  service     = ReligionService
  FormDialog  = ReligionDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.religion.name",                    value: "name"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.religion.description",             value: "description",                  className: "mpk-font-size-S"}
  ]

  _tableActions = [
  ]

  barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]
}
