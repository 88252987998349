import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import FormView from '../../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import NonEmployeeService from './NonEmployee.service';
import StatusPtkpService from '../../Master/StatusPtkp/StatusPtkp.service';
import DesignationService from '../../Master/Designation/Designation.service';
import cloneDeep from 'lodash/cloneDeep'
import EarningService from '../../Master/Earning/Earning.service';
import DeductionService from '../../Master/Deduction/Deduction.service';
import CompanyService from '../../../Admin/Company/Company.service';
import ObjekPajakService from '../../Master/ObjekPajak/ObjekPajak.service';

@reduxForm({form: 'NonEmployeeForm', destroyOnUnmount: true, initialValues: {
  earnings: [],
  deductions: [],
  earningMap: {},
  deductionMap: {}
}})
@connect((state) => ({
  data: getFormValues('NonEmployeeForm')(state)
}))
export default class NonEmployeeForm extends FormView {
  service     = NonEmployeeService
  viewType    = 2;

  initialData = {
    basicInfo: {
      npwp: '000000000000000',
      isForeign: false
    },
    jobInfo: {
      "isMutation": false,
      "isJoining": false,
      "isRetiring": false,
    },
    bankInfo: {},
    otherInfo: {},
    earnings: [],
    deductions: [],
    earningMap: {},
    deductionMap: {}
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      earningType: [],
      deductionType: []
    }
  }

  async initData() {

    var earningRes  = await EarningService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })
    var deductionRes  = await DeductionService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    this.setState({
      earningType: earningRes.data.data,
      deductionType: deductionRes.data.data
    })

    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      res.data.earningMap = res.data.earnings.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})

      res.data.deductionMap = res.data.deductions.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})

      this.props.initialize(res.data);
    }
  }

  formView() {
    return (<div>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.nonEmployee.basicInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.company.title')}
            name          = 'companyInfo'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            valueField    = 'parent'
            service       = {CompanyService}
          />
          <Field
            label         = {counterpart('entities.nonEmployee.basicInfo.name')}
            name          = 'basicInfo.name'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.nonEmployee.basicInfo.npwp')}
            name          = 'basicInfo.npwp'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            maskFormat    = "##.###.###.#-###-###"
            mask          = "_"
            length        = {15}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.nonEmployee.basicInfo.identityNo')}
            name          = 'basicInfo.identityNo'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.nonEmployee.basicInfo.address')}
            name          = 'basicInfo.address'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.nonEmployee.basicInfo.email')}
            name          = 'basicInfo.email'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.nonEmployee.basicInfo.isForeign')}
            name          = 'basicInfo.isForeign'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          />
          <Field
            label         = {counterpart('entities.nonEmployee.basicInfo.country')}
            name          = 'basicInfo.country'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.nonEmployee.basicInfo.religion')}
            name          = 'basicInfo.religion'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.nonEmployee.basicInfo.ptkp')}
            name          = 'basicInfo.ptkp'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            valueField    = 'name'
            service       = {StatusPtkpService}
          />
        </div>
      </Card>
      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.nonEmployee.jobInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.nonEmployee.jobInfo.employeeNo')}
            name          = 'jobInfo.employeeNo'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.nonEmployee.jobInfo.designation')}
            name          = 'jobInfo.designation'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            service       = {DesignationService}
            valueField    = 'name'
          />
        </div>
      </Card>

      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.nonEmployee.finalInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.nonEmployee.finalInfo.golonganPns')}
            name          = 'finalInfo.golonganPns'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: "I",
              name: "I"
            },{
              id: "II",
              name: "II"
            },{
              id: "III",
              name: "III"
            },{
              id: "IV",
              name: "IV"
            }]}
          />
        </div>
      </Card>

      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.nonEmployee.tidakFinalInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.nonEmployee.tidakFinalInfo.tipePenghasilan')}
            name          = 'tidakFinalInfo.tipePenghasilan'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options       = {[{
              id: "BG",
              name: "BERKESINAMBUNGAN"
            },{
              id: "TBG",
              name: "TIDAK BERKESINAMBUNGAN"
            }]}
          />
          <Field
            label         = {counterpart('entities.nonEmployee.tidakFinalInfo.dipotongOleh')}
            name          = 'tidakFinalInfo.dipotongOleh'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options       = {[{
              id: "SPK",
              name: "SATU PEMBERI KERJA"
            },{
              id: "LSPK",
              name: "LEBIH DARI SATU PEMBERI KERJA"
            }]}
          />
          <Field
            label         = {counterpart('entities.nonEmployee.tidakFinalInfo.caraPembayaran')}
            name          = 'tidakFinalInfo.caraPembayaran'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: "B",
              name: "BULANAN"
            },{
              id: "TB",
              name: "TIDAK BULANAN"
            }]}
          />
        </div>
      </Card>
    </div>)
  }

  async beforeSave(value) {

    value.earnings = Object.keys(value.earningMap).map((k, i) => {
      if(value.earningMap[k].id.search('NEW_DATA') >= 0) {
        delete value.earningMap[k].id
      }
      return value.earningMap[k]
    })

    value.deductions = Object.keys(value.deductionMap).map((k, i) => {
      if(value.deductionMap[k].id.search('NEW_DATA') >= 0) {
        delete value.deductionMap[k].id
      }
      return value.deductionMap[k]
    })

    return true;
  }

  addEarning = (e)=> {
    e.preventDefault()
    var earningMap = cloneDeep(this.props.data.earningMap)
    earningMap['NEW_DATA_'+Object.keys(earningMap).length] = {
      id: 'NEW_DATA_'+Object.keys(earningMap).length,
      amount: 0,
      isEncrypted: false
    }
    this.props.dispatch(this.props.change('earningMap', earningMap))
  }

  deleteEarning = (id)=> {
    var earningMap = cloneDeep(this.props.data.earningMap)
    delete earningMap[id]

    this.props.dispatch(this.props.change('earningMap', earningMap))
  }

  addDeduction = (e)=> {
    e.preventDefault()
    var deductionMap = cloneDeep(this.props.data.deductionMap)
    deductionMap['NEW_DATA_'+Object.keys(deductionMap).length] = {
      id: 'NEW_DATA_'+Object.keys(deductionMap).length,
      amount: 0,
      isEncrypted: false
    }
    this.props.dispatch(this.props.change('deductionMap', deductionMap))
  }

  deleteDeduction = (id)=> {
    var deductionMap = cloneDeep(this.props.data.deductionMap)
    delete deductionMap[id]

    this.props.dispatch(this.props.change('deductionMap', deductionMap))
  }
}
