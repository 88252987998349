/**
 * Created by dwiargo on 11/29/17.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Router from 'react-router-dom/Router';
import cloneDeep from 'lodash/cloneDeep';
import {
  Avatar,
  Divider,
  FontIcon,
  List,
  ListItem,
  Subheader,
  Button,
  Card,
  CardTitle,
  DialogContainer,
  CircularProgress
} from 'react-md';
import { Field, reduxForm, getFormValues  } from 'redux-form';
import { Appbar, Notification, Sidebar, rdxConnect } from 'react-mpk';
import * as globalActions from 'react-mpk/redux/actions/globalActions';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio} from '../../components/form';
import routes from './routes';
import AuthService from '../../services/auth.service';
// import OrganizationSwitcher from '../../components/OrganizationSwitcher/OrganizationSwitcher';
import defaultMenu from './defaultMenu';
import modularMenu from './modularMenu';
import * as constant from '../../config/constant';
import UserBar from '../../components/UserBar/UserBar';
import ModuleMenu from '../../components/ModuleMenu/ModuleMenu';
import authService from '../../services/auth.service';
import websocketService from '../../services/websocket.service';
import sipbasLogoMini from '../../assets/logo-bas-01.jpg'
import UserService from './Admin/User/User.service';
import iziToast from 'izitoast';
import companyService from './../../services/companyService'
import env from 'env';
import CompanyService from './Admin/Company/Company.service';
import axios from '../../services/http.service';
import Axios from 'axios';

@connect(state => ({
  global:state.global,
  isLoginIn: state.authHalona.isLoginIn,
  user: state.authHalona.user,
  showGlobalProgress: state.authHalona.showGlobalProgress,
  organization: state.authHalona.currentOrganization,
  access: state.authHalona.access,
  spt: state.authHalona.currentSPT,
  currentModule: state.authHalona.currentModule,
  company: state.authHalona.currentCompany,
  setting: state.authHalona.setting,
  menuType: state.authHalona.menuType,
  auth: state.auth.user,
  authHalona: state.authHalona,
  coreCompany: state.auth.company
}), dispatch => ({
  dispatch: dispatch,
  globalActions:bindActionCreators(globalActions, dispatch)
}))
class Main extends Component{
  constructor(props) {
    super(props);
    this.state = {
      version: ''
    }
  }

  static contextTypes = {
    showDialog: 'React.PropTypes.func',
  };

  async logout() {
    this.props.history.push('/account/login')
    await AuthService.api.logout(this.props.dispatch);
  }

  // CORE EPPT
  async componentDidMount(){
    if(!env.devMode){
      try {
        var companyId = this.props.match.params.companyId
        var subscriptionId = this.props.match.params.subscriptionId
        let res = await companyService.me(companyId, subscriptionId);
        var user = res.data
        let { company } = res.data;

        this.props.authActions.setProperties({
          company,
          meInCompany: res.data
        });
        // DISPATCH USER
        // console.log(res.data, "->")
        var userEppt = {
          id: user.id,
          login: user.username,
          firstName: user.name,
          lastName: '',
          email: user.email,
          imageUrl: '',
          activated: true,
          langKey: 'en',
          authorities: [{ name: 'ROLE_EPPT_USER' }],
          company: {
            id: company.id,
            nama: company.name,
            alamat: company.address,
            email: company.email,
            telp: '',
            sn: '',
            active: true
          }
        }
        var token = {
          access_token: 'cb7e9d78-1fa3-4593-ab10-e4ae20a32f92',
          token_type: 'bearer',
          refresh_token: '735121f5-4e61-4ce3-a8f0-068a6d159fd1',
          expires_in: 86399,
          scope: 'read write',
          apps: 'eppt'
        }

        var qInit = await CompanyService.api.init({
          data: company.organizations.map(x => {
            return {
              code         : "",
              npwp         : x.npwp,
              name         : x.name,
              alias        : "",
              ownerId      : x.companyId,
              isHO         : false,
              source       : "sync",
              city         : x.city,
              address      : x.address,
              email        : x.email,
              postalCode   : x.postalCode,
              phone        : x.phone,
              fax          : x.fax
            }
          }
        )})

        axios.defaults.headers.common['X-HALONA-ORGANIZATION-ID'] = qInit.data.map(x => x.id).join(",")

        // var user = await AuthService.api.getCurrentUserCore(userEppt, this.props.dispatch);
        // var token = await AuthService.api.forceLogin(token, this.props.dispatch);
        // var company = await AuthService.api.changeCompany(company.company, this.props.dispatch);
        // DISPATCH USER
      } catch(e){
        console.log(e, 'asd')
        // iziToast.error({
        //   title: "GET COMPANY",
        //   message: "Failed get company properties"
        // })
        // setTimeout(()=> {
        //   this.props.history.push('/main')
        // }, 2000)
      }
    }
  }
  // CORE EPPT

  async componentWillMount() {
    if(env.devMode){
      try {
        // init user
        var res = await AuthService.api.getCurrentUser(this.props.dispatch);
        // await AuthService.api.getApprovalSetting(this.props.dispatch); // ssso

        // console.log(res, 'user')

        AuthService.api.changeMenuType('main', this.props.dispatch)
        // var userAuth = AuthService.api.getAuth(this.props.user);


        // get access
        // AuthService.api.getOrgAccess(this.props.dispatch)

        // connect to websocket
        // websocketService.connect();

        // var version = await UserService.api.getVersion() // ssso
        // if(version.data) {
        //   this.setState({version: version.data.version})
        // }


      } catch(e) {
        console.log(e, 'error')
        // if(e.response && e.response.status == 401) await this.logout(this.props.dispatch) // ssso
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    // change menu from spt -> main
    if(nextProps.location.pathname != this.props.location.pathname && nextProps.location.pathname.search('/open') == -1) {
      AuthService.api.changeMenuType('main', this.props.dispatch)
    }
  }

  buildMainMenus() {
    let defMenus = modularMenu[this.props.currentModule];
    if(!defMenus) defMenus = defaultMenu['none']

    if(this.props.setting.menuType === 'Default') {
      defMenus = defaultMenu.mainMenus;
    }

    if(!env.devMode){
      var companyId = this.props.match.params.companyId
      var subscriptionId = this.props.match.params.subscriptionId
      return this.checkAndCreateMenus(defMenus, `/company/${companyId}/${subscriptionId}/init`);
    } else {
      return this.checkAndCreateMenus(defMenus);
    }

  }

  buildKoreksiFiskalMenus() {
    if(!env.devMode){
      var companyId = this.props.match.params.companyId
      var subscriptionId = this.props.match.params.subscriptionId
      return this.checkAndCreateMenus(defaultMenu.koreksiFiskalMenus, `/company/${companyId}/${subscriptionId}/init/koreksiFiskal`);
    } else {
      return this.checkAndCreateMenus(defaultMenu.koreksiFiskalMenus, '/koreksiFiskal');
    }
  }

  buildPeriodeMenus() {
    if(!env.devMode){
      var companyId = this.props.match.params.companyId
      var subscriptionId = this.props.match.params.subscriptionId
      return this.checkAndCreateMenus(defaultMenu.periodeMenus, `/company/${companyId}/${subscriptionId}/init/periode`);
    } else {
      return this.checkAndCreateMenus(defaultMenu.periodeMenus, '/periode');
    }
  }

  buildCompanyMenus() {
    if(!env.devMode){
      var companyId = this.props.match.params.companyId
      var subscriptionId = this.props.match.params.subscriptionId
      return this.checkAndCreateMenus(defaultMenu.koreksiFiskalMenus, `/company/${companyId}/${subscriptionId}/init/company`);
    } else {
      return this.checkAndCreateMenus(defaultMenu.koreksiFiskalMenus, '/company');
    }
  }

  buildMenus() {
    // switch between spt menu and main menu
    if(this.props.menuType.search('KOREKSI_FISKAL') >= 0) {
      return this.buildKoreksiFiskalMenus()
    } else if(this.props.menuType.search('PPH21_PERIODE') >= 0) {
      return this.buildPeriodeMenus()
    } else if(this.props.menuType === 'company') {
      return this.buildCompanyMenus()
    }else {
      return this.buildMainMenus()
    }
  }

  checkAndCreateMenus(defMenu=[], sptPath) {
    var userAuth = constant.ROLE_EPPT_USER;

    if(this.props.coreCompany && this.props.coreCompany.role && this.props.coreCompany.role.name.toLowerCase().search("admin") >= 0) userAuth = constant.ROLE_EPPT_ADMIN

    var isDecember = null
    if(this.props.spt.data){
      if(this.props.spt.data.month == 12){
        isDecember = true
      }
    }

    var mainMenus = [];
    defMenu.forEach((d, i)=> {
      var hm = {
        label: d.label,
        children: []
      };

      d.children.forEach((c, j)=> {
        var shouldPush = false;
        if(sptPath) {
          c.onClick = ()=> {
            if(this.props.menuType.search('KOREKSI_FISKAL') >= 0) {
              this.props.history.push(sptPath+'/'+this.props.spt.data.id+'/open'+c.path)
            } else if(this.props.menuType.search('PPH21_PERIODE') >= 0) {
              this.props.history.push(sptPath+'/'+this.props.spt.data.id+'/open'+c.path)
            }else if(this.props.menuType === 'company') {
              this.props.history.push(sptPath+'/'+this.props.company.id+'/open'+c.path)
            } else {
              this.props.history.push(sptPath+c.path)
              // console.log(sptPath+c.path)
            }
          }
        }

        if(c.rootPath) {
          c.onClick = ()=> {
            if(userAuth === constant.ROLE_EPPT_OWNER) {
              this.props.history.push(c.rootPath+'/'+this.props.company.id+'/open'+c.path)
            }
          }
        }

        if(userAuth === constant.ROLE_EPPT_ADMIN) {
          if(!c.owner && !c.user) {
            if(c.decemberOnly){
              if(isDecember){
                shouldPush = true;
              }
            } else {
              shouldPush = true;
            }
          }
        } else if(userAuth === constant.ROLE_EPPT_OWNER) {
          if(!c.admin && !c.user) {
            if(c.decemberOnly){
              if(isDecember){
                shouldPush = true;
              }
            } else {
              shouldPush = true;
            }
          }
        } else {
          if(!c.admin && !c.owner) {
            if(c.permission) {
              // check  permission
              // if(this.props.access[c.permission]) {
              //   hm.children.push(c);
              // }
              if(c.decemberOnly){
                if(isDecember){
                  shouldPush = true;
                }
              } else {
                shouldPush = true;
              }
            } else {
              if(c.decemberOnly){
                if(isDecember){
                  shouldPush = true;
                }
              } else {
                shouldPush = true;
              }
            }
          }
        }

        if(c.role) {
          if(this.props.user && this.props.user.permissionMap && this.props.user.permissionMap[c.role]) {
            shouldPush = true
          } else {
            shouldPush = false


          }
        }

        if(shouldPush) {
          // console.log('pushhhhhhhhhhhh')
          hm.children.push(c)
        }
      })
      if(hm.children.length > 0) mainMenus.push(hm);
    })

    return mainMenus;
  }

  editProfile = () => {
    this.props.history.push("/EDIT_PROFILE")
  }

  editPassword = async () => {
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Ubah Password',
      initialValue: cloneDeep(this.props.setting),
      okText: 'Save',
      text: (
        <div className='md-grid'>
          <Field
            label='Password Sebelumnnya'
            name='currentPassword'
            type='password'
            className="md-cell md-cell--12"
            component={Textfield}
          />
          <Field
            label='Password Baru'
            name='newPassword'
            type='password'
            className="md-cell md-cell--12"
            component={Textfield}
          />
          <Field
            label='Ketik Ulang Password'
            name='verifyPassword'
            type='password'
            className="md-cell md-cell--12"
            component={Textfield}
          />
        </div>)
    }))

    if(d) {
      await UserService.api.changePassword(d)
      iziToast.success({
        title: 'success',
        message: 'Password telah berhasil di simpan'
      })
    }
  }

  render(){
    return(
      <div className="mpk-layout column fill">
        {this.props.showGlobalProgress && <div className='animated fadeIn' style={{height: '100%', width: '100%',
          background: 'rgba(0,0,0,.7)', position: 'absolute',
          zIndex: 1000, display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'}}>
          <CircularProgress id='global_progress'  />
        </div>
        }
        {env.devMode &&
          <Appbar
            appName="halona"
            // subname={<div>
            //   <img style={{height: 60, width: 'auto', marginTop: 5}} src={sipbasLogoMini} />
            //   <h1 style={{fontSize: 12, float: 'right', marginTop: '18px', color: 'rgba(0, 0, 0, 0.87)'}}>Sistem Informasi Perpajakan - Bank Aceh Syariah</h1></div>}
            version={this.state.version}
            fixedVersion={true}
            showVersion
            onLogout={()=> this.logout()}
            notification={<Notification newFeeds={0}/>}
            profileMenu={[
              {
                label:"Profile",
                materialIcon:'perm_identity',
                onClick:() => this.editProfile()
              },
              {
                label:"Password",
                materialIcon:'lock',
                onClick:() => this.editPassword()
              },
              {
                label:"Setting",
                materialIcon:'settings',
                onClick: async ()=> {
                  var d = await this.context.showDialog((props, res, rej) =>({
                    title: 'App Settings',
                    initialValue: cloneDeep(this.props.setting),
                    okText: 'Save',
                    text: (
                      <div className='md-grid'>
                        <Field
                          label='Screen size'
                          name='size'
                          className="md-cell md-cell--12"
                          component={Textfield}
                        />
                        <Field
                          label='Menu Type'
                          name='menuType'
                          className="md-cell md-cell--12"
                          options={[{
                            id: 'Default', name: 'Default'
                          },{
                            id: 'Modular', name: 'Modular'
                          }]}
                          component={Searchfield}
                        />
                        <Field
                          label='Menu Collapsible'
                          name='collapsible'
                          className="md-cell md-cell--12"
                          component={Switch}
                        />
                      </div>

                    )
                  }))

                  if(d) {
                    localStorage.setItem('settings', JSON.stringify(d));
                    location.reload()
                  }
                }
              }
            ]}
            translate={true}
          />
        }
        {!env.devMode &&
          <Appbar
            notification={<Notification newFeeds={0}/>}
            appName="halona"
            showVersion fixedVersion={false}
            version={this.state.version}
            profileMenu={[
              {
                label:"Company",
                materialIcon:'launch',
                onClick:() => {
                  this.props.history.push('/main')
                }
              }
            ]}
            appsShortcut={[]}
            consoleShortcut={Object.keys(this.props.global.appConsole).map(key => {
              return this.props.global.appConsole[key];
            })}
            translate={true}
          />
        }
        <div className="mpk-layout fill mpk-relative-position mpk-content">
          <Sidebar
            accordionMode={true}
            menuClass='animated fadeInLeft'
            collapsible={this.props.setting.collapsible}
            headerElements={this.topSideBar()}
            menus={this.buildMenus()}
            getValueByLocaleCode={true}
          />
          {/* {console.log(this.props.user, )} */}
          {(this.props.coreCompany || env.devMode) &&
            routes()
          }
        </div>
      </div>
    )
  }

  topSideBar() {
    var companyId = this.props.match.params.companyId
    var subscriptionId = this.props.match.params.subscriptionId
      // return this.checkAndCreateMenus(defMenus, `/company/${companyId}/${subscriptionId}/init`);


      if(this.props.menuType.search('KOREKSI_FISKAL') >= 0 && this.props.spt.data) {
        return (
          <div>
            {/* <OrganizationSwitcher/> */}
            <List style={{background: '#F44336'}}>
              <ListItem

                primaryTextStyle={{paddingLeft: 58, color: 'rgba(255, 255, 255, 0.87)'}}
                secondaryTextStyle={{paddingLeft: 58, color: 'rgba(255, 255, 255, 0.47)'}}
                tileStyle={{height: 'auto', margin: '10px 0px'}}
                rightIcon={<FontIcon tooltipLabel="Tutup"   className='close-menu' onClick={() => {authService.api.changeMenuType('main', this.props.dispatch);this.props.history.push('/koreksiFiskal')}}>close</FontIcon>}
                primaryText={"FISKAL"}
                secondaryText={this.props.spt.data.tahun}
                />
            </List>
          </div>
        )
      }else if(this.props.menuType.search('PPH21_PERIODE') >= 0 && this.props.spt.data) {

        return (
          <div>
            {/* <OrganizationSwitcher/> */}
            <List style={{background: '#F44336'}}>
              <ListItem

                primaryTextStyle={{paddingLeft: 0, color: 'rgba(255, 255, 255, 0.87)'}}
                secondaryTextStyle={{paddingLeft: 0, color: 'rgba(255, 255, 255, 0.47)'}}
                tileStyle={{height: 'auto', margin: '10px 0px'}}
                threeLines
                rightIcon={<FontIcon tooltipLabel="Tutup"   className='close-menu' onClick={() => {
                  authService.api.changeMenuType('main', this.props.dispatch);
                  this.props.history.push(`/company/${companyId}/${subscriptionId}/init/periode`)
                }}>close</FontIcon>}
                primaryText={`PPh 21 Periode ${this.props.spt.data.month} - ${this.props.spt.data.year}`}
                secondaryText={` ${this.props.spt.data.company.npwp} \n ${this.props.spt.data.company.name} `}
                />
            </List>
          </div>
        )
      } else if(this.props.menuType === 'company' && this.props.company) {
        var companyName = "A";
        if(this.props.company && this.props.company.nama)  companyName = this.props.company.nama;
        return <div>
            <List className="md-list md-dark-theme" style={{background: 'none'}}>
              <ListItem
                leftAvatar={<Avatar suffix="green">{companyName.charAt(0)}</Avatar>}
                tileStyle={{height: 'auto', margin: '10px 0px'}}
                rightIcon={<FontIcon tooltipLabel="Tutup" className='close-menu' onClick={() => {authService.api.changeMenuType('main', this.props.dispatch); this.props.history.push('/company')}}>close</FontIcon >}
                primaryText={this.props.company.nama}
                secondaryText={this.props.company.email}
                />
            </List>
          </div>
      } else {
        return (
          <div>
            <UserBar />
            {this.props.setting.menuType !== 'Default' &&
            <ModuleMenu />
            }

          </div>

        )
      }
  }
}

export default rdxConnect(Main);
