import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  Subheader,
  Avatar,
  Tabs, Tab, TabsContainer
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import FormView from '../../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import PeriodeService from './Periode.service';
import CompanyService from '../../../Admin/Company/Company.service';

@reduxForm({form: 'PeriodeDashboard', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('PeriodeDashboard')(state)
}))
export default class PeriodeDialog extends FormView {
  service     = PeriodeService
  viewType    = 2
  translate   = false;

  titleHeader() {
    return `Dashboard`;
  }

  transform(value) {
    value.month = parseInt(value.month)
    value.year = parseInt(value.year)
    return value;
  }

  async initData() {
    PeriodeService.api.summary()
  }

  barItem() {}

  formView() {
    return (
      <div>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={'STATUS'} />
        <Divider/>
        <div className="md-grid">
          <div className="md-cell md-cell--6">
            <List>
              <ListItem
                style={{borderRight: '1px solid #eee'}}
                leftAvatar={<Avatar style={{backgroundColor:"#ff9800"}} icon={<FontIcon>close</FontIcon>} />}
                // rightIcon={<InfoIcon />}
                primaryText="Hitung"
                secondaryText="Belum Hitung"
              />
            </List>
          </div>
          <div className="md-cell md-cell--6">
            <List>
              <ListItem
                // style={{borderLeft: '4px solid #2e7d32'}}
                leftAvatar={<Avatar style={{backgroundColor:" #2e7d32"}} icon={<FontIcon>check</FontIcon>} />}
                // rightIcon={<InfoIcon />}
                primaryText="Kirim"
                secondaryText="Sudah Kirim"
              />
            </List>
          </div>
        </div>
      </Card>
      <br/>

      <TabsContainer panelClassName="md-grid" colored>
        <Tabs tabId="simple-tab" mobile={true}>
          <Tab label="KARYAWAN TETAP"></Tab>
          <Tab label="KARYAWAN TIDAK TETAP"></Tab>
        </Tabs>
      </TabsContainer>
      <Card style={{marginTop: -14}}>
        <div className="md-grid">
          <div className="md-cell md-cell--4">
            <div style={{padding:'15px', alignItems: 'center', display: 'flex', borderRight: '1px solid #eee'}}>
              <Button icon primary swapTheming style={{}}>person</Button>
              <div style={{fontSize: 16, color: '#777', margin: '0 20px'}} className="flex">KARYAWAN AKTIP</div>
              <div style={{fontSize: 28, fontWeight: 'bold'}}>20</div>
            </div>
          </div>

          <div className="md-cell md-cell--4">
            <div style={{padding:'15px', alignItems: 'center', display: 'flex', borderRight: '1px solid #eee'}}>
              <Button icon secondary swapTheming style={{}}>person</Button>
              <div style={{fontSize: 16, color: '#777', margin: '0 20px'}} className="flex">KARYAWAN BERHENTI</div>
              <div style={{fontSize: 28, fontWeight: 'bold'}}>20</div>
            </div>
          </div>

          <div className="md-cell md-cell--4">
            <div style={{padding:'15px', alignItems: 'center', display: 'flex', borderRight: '1px solid #eee'}}>
              <Button icon primary swapTheming style={{}}>people</Button>
              <div style={{fontSize: 16, color: '#777', margin: '0 20px'}} className="flex">TOTAL</div>
              <div style={{fontSize: 28, fontWeight: 'bold'}}>40</div>
            </div>
          </div>

        </div>
      </Card>
      <br/>
      <Card>
        <div className="md-grid">
          <List className="md-cell md-cell--12">
            <Subheader>GAJI</Subheader>
            <ListItem
              // style={{borderRight: '1px solid #eee'}}
              // leftAvatar={<Avatar style={{backgroundColor:"#ff9800"}} icon={<FontIcon>close</FontIcon>} />}
              primaryText="TOTAL TUNJANGAN"
              secondaryText="1.000.000,-"
            />
            <ListItem
              // style={{borderRight: '1px solid #eee'}}
              // leftAvatar={<Avatar style={{backgroundColor:"#ff9800"}} icon={<FontIcon>close</FontIcon>} />}
              primaryText="TOTAL POTONGAN"
              secondaryText="1.000.000,-"
            />

            <ListItem
              // style={{borderRight: '1px solid #eee'}}
              // leftAvatar={<Avatar style={{backgroundColor:"#ff9800"}} icon={<FontIcon>close</FontIcon>} />}
              primaryText="TOTAL THP"
              secondaryText="1.000.000,-"
            />
          </List>
        </div>
      </Card>
      <br/>
      <Card>
        <div className="md-grid">
          <List className="md-cell md-cell--6">
            <Subheader>BPJS</Subheader>
            <ListItem
              // style={{borderRight: '1px solid #eee'}}
              // leftAvatar={<Avatar style={{backgroundColor:"#ff9800"}} icon={<FontIcon>close</FontIcon>} />}
              primaryText="BPJS KETENAGAKERJAAN"
              secondaryText="1.000.000,-"
            />
            <ListItem
              // style={{borderRight: '1px solid #eee'}}
              // leftAvatar={<Avatar style={{backgroundColor:"#ff9800"}} icon={<FontIcon>close</FontIcon>} />}
              primaryText="BPJS KESEHATAN"
              secondaryText="1.000.000,-"
            />
          </List>

          <List className="md-cell md-cell--6">
            <Subheader>PPH</Subheader>
            <ListItem
              // style={{borderRight: '1px solid #eee'}}
              // leftAvatar={<Avatar style={{backgroundColor:"#ff9800"}} icon={<FontIcon>close</FontIcon>} />}
              primaryText="TOTAL BRUTO"
              secondaryText="1.000.000,-"
            />
            <ListItem
              // style={{borderRight: '1px solid #eee'}}
              // leftAvatar={<Avatar style={{backgroundColor:"#ff9800"}} icon={<FontIcon>close</FontIcon>} />}
              primaryText="TOTAL PPH"
              secondaryText="1.000.000,-"
            />
          </List>
        </div>
      </Card>
      </div>
    )
  }
}
