import React from 'react';
import InputMask from 'react-input-mask';
import './TextFieldMask.scss';

const TextFieldMask = ({label, onChange, className="", ...textProps}) => (
  <div className={`mpk-text-field-mask ${className}`}>
    {label ? <label className="md-floating-label md-floating-label--floating md-text--secondary">{label}</label> : null}
    <InputMask
      className="input-mask"
      alwaysShowMask={true}
      {...textProps}
      onChange={ e => {
        if(onChange) onChange(e.target.value)
      }}
    />
  </div>
)

export default TextFieldMask