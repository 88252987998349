import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './Deduction.dialog.view';
import DeductionService from './Deduction.service';
import ListView from '../../../../../components/entity/listView';
import DeductionDialog from './Deduction.dialog';

@connect(DeductionService.stateConnectSetting(), DeductionService.actionConnectSetting())
export default class DeductionView extends ListView {
  service     = DeductionService
  FormDialog  = DeductionDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.deduction.name",                    value: "name"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.deduction.alias",                   value: "alias"},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.deduction.componentType",           value: "componentType.name"},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.deduction.isDynamic",              value: "isDynamic", type: 'boolean'},
    {isSortable: false,  show: false,   isSearchable:true,  label: "entities.deduction.hideSlipPrintOut",      value: "hideSlipPrintOut", type: 'boolean'},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.deduction.description",             value: "description",                  className: "mpk-font-size-S"}
  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='name'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />

        <this.ig.Field
          label='Sembunyikan Default Pendapatan'
          name='hideDefault'
          className="md-cell md-cell--12"
          component={this.ig.Switch}
        />
        </this.SearchContainer>
    )
  }
}
