import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import AppScheduleService from './AppSchedule.service';
import ListView from '../../../../components/entity/listView';
import AppScheduleDialog from './AppSchedule.dialog';

@connect(AppScheduleService.stateConnectSetting(), AppScheduleService.actionConnectSetting())
export default class AppScheduleView extends ListView {
  service     = AppScheduleService
  FormDialog  = AppScheduleDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.appSchedule.scheduleId",   value: "scheduleId"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.appSchedule.name",         value: "name",  isDefaultSort:true},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.appSchedule.cron",         value: "cron"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.appSchedule.enabled",      value: "enabled",  type: "boolean"}
  ]

  barActions = [
    // {
    //   label:'word.create',
    //   iconClassName:'mdi mdi-plus',
    //   onClick:() => this.addItem()
    // },
    // {
    //   label:'word.delete',
    //   iconClassName:'mdi mdi-delete',
    //   onClick:() => {
    //     this.setState({showDialogConfirmDeleteSelected:true})
    //   },
    //   disabledFunc:() => this.props.table.selected.length === 0
    // },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]
}
