import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  TableFooter,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import FormView from '../../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import EmployeeService from './Employee.service';
import StatusPtkpService from '../../Master/StatusPtkp/StatusPtkp.service';
import DesignationService from '../../Master/Designation/Designation.service';
import cloneDeep from 'lodash/cloneDeep'
import EarningService from '../../Master/Earning/Earning.service';
import DeductionService from '../../Master/Deduction/Deduction.service';
import CompanyService from '../../../Admin/Company/Company.service';
import ObjekPajakService from '../../Master/ObjekPajak/ObjekPajak.service';
import DepartmentService from '../../Master/Department/Department.service';
import utilsService from '../../../../../services/utils.service';
import BankService from '../../Master/Bank/Bank.service';

@reduxForm({form: 'EmployeeForm', destroyOnUnmount: true, initialValues: {
  earnings: [],
  deductions: [],
  earningMap: {},
  deductionMap: {},
  otherInfo: {}
}})
@connect((state) => ({
  formData: getFormValues('EmployeeForm')(state)
}))
export default class EmployeeForm extends FormView {
  service     = EmployeeService
  viewType    = 2;

  initialData = {
    basicInfo: {
      npwp: '000000000000000',
      isForeign: false,
      religion: "",
      jumlahTanggungan: 0
    },
    jobInfo: {
      nettoA1: 0,
      statusPekerja: "Karyawan",
      pphA1: 0,
      status: 'ACTIVE',
      memilikiA1: false,
      pernahBekerja: false,
      "isMutation": false,
      "isJoining": false,
      "isRetiring": false,
      dapatPesangon: false,
      totalPesangon: 0
    },
    bankInfo: {
      paymentMethod: 'TRANSFER'
    },
    otherInfo: {
      golongan: 'Kelompok 1',
      bpjsKet: false,
      bpjsKetDitanggung: false,
      bpjsKetPerhitungan: false,
      bpjsKetLainnya: 0,
      bpjsKes: false,
      bpjsKesDitanggung: false,
      bpjsKesPerhitungan: false,
      jaminanPensiun: false,
      bpjsKesLainnya: 0,
      statusPindahan: '0'
    },
    earnings: [],
    deductions: [],
    earningMap: {},
    deductionMap: {}
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      earningType: [],
      deductionType: [],
      departments: [],
      banks: [],
      render: false
    }
  }

  async initData() {

    var earningRes  = await EarningService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })
    var deductionRes  = await DeductionService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    var department =  await DepartmentService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    var bank =  await BankService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    console.log(department, 'asdasd', department.data.data)

    this.setState({
      earningType: earningRes.data.data,
      deductionType: deductionRes.data.data,
      departments:department.data.data,
      banks: bank.data.data,
      render: true
    })

    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      res.data.earningMap = res.data.earnings.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})

      res.data.deductionMap = res.data.deductions.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})

      this.props.initialize(res.data);
    }
  }

  formView() {
    if(!this.state.render) return <div></div>
    return (<div>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.basicInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.company.title')}
            name          = 'companyInfo'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            valueField    = 'parent'
            service       = {CompanyService}
            itemTemplate  = {function(d) {
              return {
                primaryText: d[this.props.viewField],
                secondaryText: d.npwp+" "+d.alias,
                onClick: () => {
                  if(!this.props.disabled){
                    this.handleItemClick(d); this.hide()
                  }
                }
              }
            }}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.name')}
            name          = 'basicInfo.name'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.npwp')}
            name          = 'basicInfo.npwp'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            maskFormat    = "##.###.###.#-###-###"
            mask          = "_"
            length        = {15}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.identityNo')}
            name          = 'basicInfo.identityNo'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.address')}
            name          = 'basicInfo.address'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.email')}
            name          = 'basicInfo.email'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.isForeign')}
            name          = 'basicInfo.isForeign'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.country')}
            name          = 'basicInfo.country'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.religion')}
            name          = 'basicInfo.religion'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {"Jenis Kelamin"}
            name          = 'basicInfo.gender'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            valueField    = 'name'
            options       = {[{
              id: "MALE",
              name: "LAKI-LAKI"
            },{
              id: "FEMALE",
              name: "PEREMPUAN"
            }]}
            validate      = {validation.required}
          />

          <Field
            label         = {counterpart('entities.employee.basicInfo.ptkp')}
            name          = 'basicInfo.ptkp'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            valueField    = 'name'
            service       = {StatusPtkpService}
            onChange      = {(e, d) => {
              if(d) {
                var s = d.split("/")
                this.props.change("basicInfo.maritalStatus", s[0])
                this.props.change("basicInfo.jumlahTanggungan", parseInt(s[1]))
              }
            }}
          />

          <Field
            label         = {counterpart('entities.nonEmployee.tidakFinalInfo.statusPtkp')}
            name          = 'basicInfo.maritalStatus'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            validate      = {validation.required}
            options       = {[{
              id: "K",
              name: "K"
            },{
              id: "TK",
              name: "TK"
            },{
              id: "K/I",
              name: "K/I"
            }]}
            disabled = {true}

          />
          <Field
            label         = {counterpart('entities.nonEmployee.tidakFinalInfo.jumlahTanggungan')}
            name          = 'basicInfo.jumlahTanggungan'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options       = {[{
              id: 0,
              name: "0"
            },{
              id: 1,
              name: "1"
            },{
              id: 2,
              name: "2"
            },{
              id: 3,
              name: "3"
            }]}
            disabled = {true}
          />
        </div>
      </Card>

      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.jobInfo.title')} />
        <Divider/>
        <div className='md-grid'>

          <Field
            label         = {counterpart('entities.employee.jobInfo.employeeNo')}
            name          = 'jobInfo.employeeNo'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.status')}
            name          = 'jobInfo.status'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            validate      = {validation.required}
            options       = {[{
              id: 'ACTIVE',
              name: 'ACTIVE'
            }, {
              id: 'LEFT',
              name: 'LEFT'
            }]}
          />

          <Field
            label         = {counterpart('entities.employee.jobInfo.designation')}
            name          = 'jobInfo.designation'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            service       = {DesignationService}
            valueField    = 'name'
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.department')}
            name          = 'jobInfo.department'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options = {this.state.departments}
            valueField    = 'parent'
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.employeeType')}
            name          = 'otherInfo.tipeKaryawan'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            validate      = {validation.required}
            options       = {[{id: '0', name: 'Karyawan Tetap'}, {id: '1', name: 'Karyawan Tidak Tetap / Bukan Karyawan'}]}
          />
        {(this.props.formData.otherInfo.tipeKaryawan == '0' && this.props.formData.jobInfo.status == 'ACTIVE') && <Field
            label         = {"Status Pindahan"}
            name          = 'otherInfo.statusPindahan'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: '0',
              name: 'Normal'
            }, {
              id: '3',
              name: 'Berhenti meninggal/pindah ke LN/dari LN'
            }, {
              id: '4',
              name: 'Pegawai baru'
            }, {
              id: '5',
              name: 'Pindahan'
            }]}
            onChange      = {(e, d)=> {
              switch (d) {
                case '0':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                case '1':
                  this.props.change('jobInfo.isMutation', true)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '2':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '3':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '4':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', true)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                case '5':
                  this.props.change('jobInfo.isMutation', true)
                  this.props.change('jobInfo.isJoining', true)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                default:
                  break;
              }
            }}
          />}
          {(this.props.formData.otherInfo.tipeKaryawan == '0' && this.props.formData.jobInfo.status == 'LEFT') && <Field
            label         = {"Status Pindahan"}
            name          = 'otherInfo.statusPindahan'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[ {
              id: '1',
              name: 'Dipindahkan ke cabang lain'
            }, {
              id: '2',
              name: 'Berhenti ke perusahaan lain/pensiun'
            }, {
              id: '3',
              name: 'Berhenti meninggal/pindah ke LN/dari LN'
            }]}
            onChange      = {(e, d)=> {
              switch (d) {
                case '0':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                case '1':
                  this.props.change('jobInfo.isMutation', true)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '2':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '3':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '4':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', true)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                case '5':
                  this.props.change('jobInfo.isMutation', true)
                  this.props.change('jobInfo.isJoining', true)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                default:
                  break;
              }
            }}
          />}
          {/* <Field
            label         = {counterpart('entities.employee.jobInfo.isMutation')}
            name          = 'jobInfo.isMutation'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          /> */}
          {/* <div className     = 'md-cell md-cell--6' /> */}
          {/* <Field
            label         = {counterpart('entities.employee.jobInfo.mutationDate')}
            name          = 'jobInfo.mutationDate'
            className     = 'md-cell md-cell--6'
            component     = {Datepicker}
          /> */}

          {/* <Field
            label         = {counterpart('entities.employee.jobInfo.isJoining')}
            name          = 'jobInfo.isJoining'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          /> */}
          <Field
            label         = {counterpart('entities.employee.jobInfo.joinedDate')}
            name          = 'jobInfo.joinedDate'
            className     = 'md-cell md-cell--6'
            component     = {Datepicker}
          />

          {/* <Field
            label         = {counterpart('entities.employee.jobInfo.isRetiring')}
            name          = 'jobInfo.isRetiring'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          /> */}
          <Field
            label         = {counterpart('entities.employee.jobInfo.retireDate')}
            name          = 'jobInfo.retireDate'
            className     = 'md-cell md-cell--6'
            component     = {Datepicker}
          />

          <Field
            label         = {counterpart('entities.employee.jobInfo.pernahBekerja')}
            name          = 'jobInfo.pernahBekerja'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          />

          <Field
            label         = {counterpart('entities.employee.jobInfo.memilikiA1')}
            name          = 'jobInfo.memilikiA1'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          />

          {(this.props.formData && this.props.formData.jobInfo && this.props.formData.jobInfo.memilikiA1) && <Field
            label         = {counterpart('entities.employee.jobInfo.nettoA1')}
            name          = {'jobInfo.nettoA1'}
            className     = 'md-cell md-cell--6 md-text-right'
            component     = {TextfieldMask}
            money         = ','
            />}

          {(this.props.formData && this.props.formData.jobInfo && this.props.formData.jobInfo.memilikiA1) && <Field
            label         = {counterpart('entities.employee.jobInfo.pphA1')}
            name          = {'jobInfo.pphA1'}
            className     = 'md-cell md-cell--6 md-text-right'
            component     = {TextfieldMask}
            money         = ','
            />}


          <Field
            label         = {counterpart('entities.employee.jobInfo.note')}
            name          = 'jobInfo.note'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />

        </div>
      </Card>

      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.bankInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.employee.bankInfo.paymentMethod')}
            name          = 'bankInfo.paymentMethod'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: 'CASH',
              name: 'CASH'
            }, {
              id: 'TRANSFER',
              name: 'TRANSFER'
            }]}
          />
          {this.props.formData.bankInfo && this.props.formData.bankInfo.paymentMethod == 'TRANSFER' && <Field
            label         = {counterpart('entities.employee.bankInfo.name')}
            name          = 'bankInfo.name'
            className     = 'md-cell md-cell--6'
            options = {this.state.banks}
            valueField    = 'name'
            component     = {Searchfield}
          />}
          {this.props.formData.bankInfo && this.props.formData.bankInfo.paymentMethod == 'TRANSFER' && <Field
            label         = {counterpart('entities.employee.bankInfo.no')}
            name          = 'bankInfo.no'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />}
        </div>
      </Card>

      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={"Info Pajak"} />
        <Divider/>
        <div className='md-grid'>

          {this.props.formData.otherInfo.tipeKaryawan == '1' && <Field
            label         = {counterpart('entities.employee.otherInfo.objekPajak')}
            name          = 'otherInfo.objekPajak'
            className     = 'md-cell md-cell--6'
            validate      = {validation.required}
            component     = {Searchfield}
            valueField    = 'id'
            onChange={(e, v, p)=> {
              if(v === '21-100-08') {
                this.props.change('otherInfo.tipePenghasilan', 'BG')
              } else if(v === '21-100-09') {
                this.props.change('otherInfo.tipePenghasilan', 'TBG')
              } else {
                this.props.change('otherInfo.tipePenghasilan', 'BG')
              }
            }}
            itemTemplate={function(d) {
              var text = d.name+" - "+d.detail;
              if(!d.detail) text = ""
              return {
                primaryText: text,
                secondaryText: d.detail,
                onClick: () => {this.handleItemClick(d); this.hide() }
              }
            }}
            options={[
              {id: '21-100-03', name:'21-100-03', detail: 'Upah Pegawai Tidak tetap atau Tenaga Kerja Lepas'},
              {id: '21-100-04', name:'21-100-04', detail: 'Imbalan Kepada Distributor Multi Level Marketing (MLM)'},
              {id: '21-100-05', name:'21-100-05', detail: 'Imbalan kepada Petugas Dinas Luar Asuransi'},
              {id: '21-100-06', name:'21-100-06', detail: 'Imbalan Kepada Penjaja Barang Dadangan'},
              {id: '21-100-07', name:'21-100-07', detail: 'Imbalan Kepada Tenaga Ahli'},
              {id: '21-100-08', name:'21-100-08', detail: 'Imbalan Kepada Bukan Pegawai yang Menerima Penghasilan yang Bersifat Berkesinambungan'},
              {id: '21-100-09', name:'21-100-09', detail: 'Imbalan Kepada Bukan Pegawai yang Menerima Penghasilan yang Tidak Bersifat Berkesinambungan'},
              {id: '21-100-10', name:'21-100-10', detail: 'Honorarium atau Imbalan Kepada Anggota Dewan Komisaris atau Dewan Pengawas yang tidak Merangkap sebagai Pegawai Tetap'},
              {id: '21-100-11', name:'21-100-11', detail: 'Jasa Produksi, Tantiem, Bonus atau Imbalan Kepada Mantan Pegawai'},
              {id: '21-100-12', name:'21-100-12', detail: 'Penarikan Dana Pensiun oleh Pegawai'},
              {id: '21-100-13', name:'21-100-13', detail: 'Imbalan Kepada Peserta Kegiatan'},
              {id: '21-100-99', name:'21-100-99', detail: 'Objek PPh Pasal 21 Tidak Final Lainnya'},
              {id: '27-100-99', name:'27-100-99', detail: 'Imbalan sehubungan dengan jasa, pekerjaan dan kegiatan, hadiah dan penghargaan, pensiun dan pembayaran berkala lainnya yang dipotong PPh Pasal 26'},
              {id: '21-401-01', name:'21-401-01', detail: 'Uang Pesangon yang Dibayarkan Sekaligus'},
              {id: '21-401-02', name:'21-401-02', detail: 'Uang Manfaat Pensiun, Tunjangan Hari Tua, atau Jaminan Hari Tua yang Dibayarkan Sekaligus'},
              {id: '21-402-01', name:'21-402-01', detail: 'Honor dan Imbalan Lain yang Dibebankan kepada APBN atau APBD yang Diterima oleh PNS, Anggota TNI/POLRI, Pejabat Negara dan Pensiunannya'},
              {id: '21-499-99', name:'21-499-99', detail: 'Objek PPh Pasal 21 Final Lainnya'},
            ]}
          />}

          {this.props.formData.otherInfo.tipeKaryawan == '0' && <Field
            label         = {counterpart('entities.employee.otherInfo.objekPajak')}
            name          = 'otherInfo.objekPajak'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            validate      = {validation.required}
            valueField    = 'id'
            onChange={(e, v, p)=> {
              if(v === '21-100-08') {
                this.props.change('otherInfo.tipePenghasilan', 'BG')
              } else if(v === '21-100-09') {
                this.props.change('otherInfo.tipePenghasilan', 'TBG')
              } else {
                this.props.change('otherInfo.tipePenghasilan', 'BG')
              }
            }}
            itemTemplate={function(d) {
              var text = d.name+" - "+d.detail;
              if(!d.detail) text = ""
              return {
                primaryText: text,
                secondaryText: d.detail,
                onClick: () => {this.handleItemClick(d); this.hide() }
              }
            }}
            options={[
              {id: '21-100-01', name:'21-100-01', detail: 'Pegawai Tetap'},
              {id: '21-100-02', name:'21-100-02', detail: 'Penerima Pensiun secara teratur'}
            ]}
          />}
          <Field
            label         = {counterpart('entities.employee.otherInfo.taxType')}
            name          = 'otherInfo.taxType'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            validate      = {validation.required}
            options       = {[{
              id: 'GROSS',
              name: 'GROSS'
            }, {
              id: 'GROSS UP',
              name: 'GROSS UP'

            }, {
              id: 'NET',
              name: 'NET'
            }]}
          />
          {/* <Field
            label         = {counterpart('entities.employee.otherInfo.objekPajak')}
            name          = 'otherInfo.objekPajak'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            valueField    = 'code'
            service       = {ObjekPajakService}
          /> */}
          {/* <Field
            label         = {counterpart('entities.employee.jobInfo.tipePembayaran')}
            name          = 'otherInfo.tipePembayaran'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options       = {[{id: 'B', name: 'Bulanan'}, {id: 'TB', name: 'Tidak Bulanan'}]}
          /> */}

          {this.bagBConfig[this.props.formData.otherInfo.objekPajak].tipePenghasilan && <Field
            label         = {counterpart('entities.employee.otherInfo.tipePenghasilan')}
            name          = 'otherInfo.tipePenghasilan'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options       = {[{
              id: "BG",
              name: "BERKESINAMBUNGAN"
            },{
              id: "TBG",
              name: "TIDAK BERKESINAMBUNGAN"
            }]}
          />}
          {this.bagBConfig[this.props.formData.otherInfo.objekPajak].dipotongOleh && this.props.formData.otherInfo.tipePenghasilan !== 'TBG' && <Field
            label         = {counterpart('entities.employee.otherInfo.dipotongOleh')}
            name          = 'otherInfo.dipotongOleh'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options       = {[{
              id: "SPK",
              name: "SATU PEMBERI KERJA"
            },{
              id: "LSPK",
              name: "LEBIH DARI SATU PEMBERI KERJA"
            }]}
          />}

          {this.bagBConfig[this.props.formData.otherInfo.objekPajak].caraPembayaran && <Field
            label         = {counterpart('entities.employee.otherInfo.caraPembayaran')}
            name          = 'otherInfo.caraPembayaran'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options       = {[{
              id: "B",
              name: "BULANAN"
            },{
              id: "TB",
              name: "TIDAK BULANAN"
            }]}
          />}

          {(this.bagBConfig[this.props.formData.otherInfo.objekPajak].jumlahHariKerja) && <Field
            label         = {counterpart('entities.employee.otherInfo.jumlahHariKerja')}
            name          = 'otherInfo.jumlahHariKerja'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            money         = ","
          />}
        </div>
      </Card>

      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={"Info Lainnya"} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.employee.otherInfo.golongan')}
            name          = 'otherInfo.golongan'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: 'Kelompok 1',
              name: 'Kelompok 1'
            }, {
              id: 'Kelompok 2',
              name: 'Kelompok 2'

            }, {
              id: 'Kelompok 3',
              name: 'Kelompok 3'

            }, {
              id: 'Kelompok 4',
              name: 'Kelompok 4'

            }, {
              id: 'Kelompok 5',
              name: 'Kelompok 5'

            }]}
          />
          <div className='md-cell md-cell--6'>
          <Field
            label         = {counterpart('entities.employee.otherInfo.bpjsKet')}
            name          = {'otherInfo.bpjsKet'}
            // className     = 'md-cell md-cell--6'
            component     = {Switch}
            />
          <Field
            label         = {counterpart('entities.employee.otherInfo.bpjsKetDitanggung')}
            name          = {'otherInfo.bpjsKetDitanggung'}
            // className     = 'md-cell md-cell--6'
            component     = {Switch}
            />

          <Field
            label         = {"Mendapatkan Jaminan Pensiun"}
            name          = {'otherInfo.jaminanPensiun'}
            // className     = 'md-cell md-cell--6'
            component     = {Switch}
            />
          <Field
            label         = {counterpart('entities.employee.otherInfo.bpjsKetPerhitungan')}
            name          = {'otherInfo.bpjsKetPerhitungan'}
            // className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options = {[{id: false, name: "Gaji Pokok"}, {id: true, name: "Nilai Lainnya"}]}
            />
          <Field
            label         = {counterpart('entities.employee.otherInfo.bpjsKetLainnya')}
            name          = {'otherInfo.bpjsKetLainnya'}
            // className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            money         = ','
            />

          </div>
          <div className='md-cell md-cell--6'>
          <Field
            label         = {counterpart('entities.employee.otherInfo.bpjsKes')}
            name          = {'otherInfo.bpjsKes'}
            // className     = 'md-cell md-cell--6'
            component     = {Switch}
            />
          <Field
            label         = {counterpart('entities.employee.otherInfo.bpjsKesDitanggung')}
            name          = {'otherInfo.bpjsKesDitanggung'}
            // className     = 'md-cell md-cell--6'
            component     = {Switch}
            />
          <Field
            label         = {counterpart('entities.employee.otherInfo.bpjsKesPerhitungan')}
            name          = {'otherInfo.bpjsKesPerhitungan'}
            // className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options = {[{id: false, name: "Gaji Pokok"}, {id: true, name: "Nilai Lainnya"}]}
            />
          <Field
            label         = {counterpart('entities.employee.otherInfo.bpjsKesLainnya')}
            name          = {'otherInfo.bpjsKesLainnya'}
            // className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            money         = ','
            />
          </div>
        </div>
      </Card>

      <br/>

      {this.earningDeductionView()}
    </div>)
  }

  earningDeductionView() {
    return <Card>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.earningDeduction.title')} />
      <Divider/>
      <DataTable plain>
      <TableHeader>
        <TableRow>
          <TableColumn className=' md-text-left'>{counterpart('entities.employee.earningDeduction.earning')}</TableColumn>
          <TableColumn className=' md-text-right'>{counterpart('entities.employee.earningDeduction.amount')}</TableColumn>
          <TableColumn className=' md-text-left'>{counterpart('entities.employee.earningDeduction.note')}</TableColumn>
          <TableColumn className=' md-text-left'>{counterpart('entities.employee.earningDeduction.delete')}</TableColumn>
        </TableRow>
      </TableHeader>
      <TableBody>

        {Object.keys(this.props.formData.earningMap).map((k, i) => {
          var d = this.props.formData.earningMap[k];
          var color = "none"
          if(d.earning && d.earning.hideSlipPrintOut) color = "#ffecb3"
          return <TableRow key={d.id} style={{backgroundColor: color}}>
            <TableColumn>
              <Field
                label         = {'_'}
                name          = {'earningMap.'+d.id+'.earning'}
                className     = 'md-cell md-cell--12'
                component     = {Searchfield}
                valueField    = 'parent'
                options       = {this.state.earningType}
              />
            </TableColumn>
            <TableColumn>
              <Field
                label         = {'_'}
                name          = {'earningMap.'+d.id+'.amount'}
                className     = 'md-cell md-cell--12 md-text-right'
                component     = {TextfieldMask}
                money         = ','
              />
            </TableColumn>
            <TableColumn className='md-text-right'>
              <Field
                label         = {'_'}
                name          = {'earningMap.'+d.id+'.note'}
                className     = 'md-cell md-cell--12'
                component     = {Textfield}
              />
            </TableColumn>
            <TableColumn className='md-text-center'>
              <Button style={{margin: 0}} secondary flat onClick={()=> this.deleteEarning(d.id)}>{counterpart('entities.employee.earningDeduction.delete')}</Button>
            </TableColumn>
          </TableRow>
        })}

        <TableRow key={'d'} style={{backgroundColor: '#EEE'}}>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn className='md-text-center'>
            <Button primary flat onClick={this.addEarning}>{counterpart('entities.employee.earningDeduction.add')}</Button>
          </TableColumn>
        </TableRow>
      </TableBody>
      </DataTable>

      <Divider/>
      <DataTable plain>
      <TableHeader>
        <TableRow>
          <TableColumn className=' md-text-left'>{counterpart('entities.employee.earningDeduction.deduction')}</TableColumn>
          <TableColumn className=' md-text-right'>{counterpart('entities.employee.earningDeduction.amount')}</TableColumn>
          <TableColumn className=' md-text-left'>{counterpart('entities.employee.earningDeduction.note')}</TableColumn>
          <TableColumn className=' md-text-left'>{counterpart('entities.employee.earningDeduction.delete')}</TableColumn>
        </TableRow>
      </TableHeader>
      <TableBody>
        {Object.keys(this.props.formData.deductionMap).map((k, i) => {
          var d = this.props.formData.deductionMap[k];
          return <TableRow key={d.id}>
            <TableColumn>
              <Field
                label         = {'_'}
                name          = {'deductionMap.'+d.id+'.deduction'}
                className     = 'md-cell md-cell--12'
                component     = {Searchfield}
                valueField    = 'parent'
                options       = {this.state.deductionType}
              />
            </TableColumn>
            <TableColumn>
              <Field
                label         = {'_'}
                name          = {'deductionMap.'+d.id+'.amount'}
                className     = 'md-cell md-cell--12 md-text-right'
                component     = {TextfieldMask}
                money         = ','
              />
            </TableColumn>
            <TableColumn className='md-text-right'>
              <Field
                label         = {'_'}
                name          = {'deductionMap.'+d.id+'.note'}
                className     = 'md-cell md-cell--12'
                component     = {Textfield}
              />
            </TableColumn>
            <TableColumn className='md-text-center'>
              <Button style={{margin: 0}} secondary flat onClick={()=> this.deleteDeduction(d.id)}>{counterpart('entities.employee.earningDeduction.delete')}</Button>
            </TableColumn>
          </TableRow>
        })}

        <TableRow key={'k1'} style={{backgroundColor: '#EEE'}}>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn className='md-text-center'>
            <Button primary flat onClick={this.addDeduction}>{counterpart('entities.employee.earningDeduction.add')}</Button>
          </TableColumn>
        </TableRow>

        <TableRow key={'d2'}>
          <TableColumn>THP</TableColumn>
          <TableColumn className="md-text-right">{utilsService.number.format2( this.totalEarning())}</TableColumn>
          <TableColumn></TableColumn>
          <TableColumn className='md-text-center'></TableColumn>
        </TableRow>

      </TableBody>
      </DataTable>
    </Card>
  }

  async beforeSave(value) {

    value.earnings = Object.keys(value.earningMap).map((k, i) => {
      if(value.earningMap[k].id.search('NEW_DATA') >= 0) {
        delete value.earningMap[k].id
      }
      return value.earningMap[k]
    })

    value.deductions = Object.keys(value.deductionMap).map((k, i) => {
      if(value.deductionMap[k].id.search('NEW_DATA') >= 0) {
        delete value.deductionMap[k].id
      }
      return value.deductionMap[k]
    })

    if(!value.otherInfo.caraPembayaran) value.otherInfo.caraPembayaran = "B"

    value.otherInfo.tipePembayaran = value.otherInfo.caraPembayaran

    return value;
  }

  addEarning = (e)=> {
    e.preventDefault()
    var earningMap = cloneDeep(this.props.formData.earningMap)
    earningMap['NEW_DATA_'+Object.keys(earningMap).length] = {
      id: 'NEW_DATA_'+Object.keys(earningMap).length,
      amount: 0,
      earning: {},
      isEncrypted: false
    }
    this.props.dispatch(this.props.change('earningMap', earningMap))
  }

  deleteEarning = (id)=> {
    var earningMap = cloneDeep(this.props.formData.earningMap)
    delete earningMap[id]

    this.props.dispatch(this.props.change('earningMap', earningMap))
  }

  addDeduction = (e)=> {
    e.preventDefault()
    var deductionMap = cloneDeep(this.props.formData.deductionMap)
    deductionMap['NEW_DATA_'+Object.keys(deductionMap).length] = {
      id: 'NEW_DATA_'+Object.keys(deductionMap).length,
      amount: 0,
      isEncrypted: false,
      deduction: {},
    }
    this.props.dispatch(this.props.change('deductionMap', deductionMap))
  }

  deleteDeduction = (id)=> {
    var deductionMap = cloneDeep(this.props.formData.deductionMap)
    delete deductionMap[id]

    this.props.dispatch(this.props.change('deductionMap', deductionMap))
  }

  totalEarning() {
    let totalEarning = Object.keys(this.props.formData.earningMap).filter(x => !this.props.formData.earningMap[x].earning.hideSlipPrintOut).reduce((pv, cv)=> {
      var d = this.props.formData.earningMap[cv]
      return pv+d.amount
    }, 0)

    let totalDeduction = Object.keys(this.props.formData.deductionMap).reduce((pv, cv)=> {
      var d = this.props.formData.deductionMap[cv]
      return pv+d.amount
    }, 0)

    return totalEarning - totalDeduction
  }

  bagBConfig= {
    undefined: {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: false
    },
    '': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: false
    },
    '21-100-01': {

    },
    '21-100-02': {

    },
    "21-401-01": {},
    "21-401-02": {},
    "21-402-01": {},
    "21-499-99": {},
    '21-100-03': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: true,
      jumlahTanggungan: true,
      caraPembayaran: true,
      jumlahHariKerja: true,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-04': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-05': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-06': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-07': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-08': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-09': {
      tipePenghasilan: true,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-10': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-11': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-12': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-13': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-99': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: false
    },
    '27-100-99': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: true
    }
  }
}
