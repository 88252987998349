import ApiService from '../../../../services/api.service';

class AppScheduleService extends ApiService {
  name  = 'appSchedule';
  path  = 'app-schedule';
  constructor() {
    super();
    this.init()
  }
}


export default new AppScheduleService();
