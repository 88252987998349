import ApiService from '../../../services/api.service';

class LogExportService extends ApiService {
  name  = 'logExport';
  path  = 'logging/log-export';
  constructor() {
    super();
    this.init()
  }

  api = {
    download: async (d) => {
      console.log(d)
      var res = await this.http.get(`${this.apiLocation}/${this.path}/download/${d.id}`, {
        responseType: 'blob'
      })

      return res;
    }
  }
}


export default new LogExportService();
