import ApiService from '../../../../services/api.service';

class DocumentTypeService extends ApiService {
  name  = 'documentType';
  path  = 'master/document-type';
  constructor() {
    super();
    this.init()
  }

  api = {
    lookup: async () => {
      var res = await this.http.get(`${this.apiLocation}/master/document-type-look-up-all`)
      console.log(res)
      return {
        data: res
      }
    }
  }
}


export default new DocumentTypeService();