import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import DialogView from '../../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import ComponentTypeService from './ComponentType.service';

@reduxForm({form: 'ComponentTypeForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('ComponentTypeForm')(state)
}))
export default class ComponentTypeDialog extends DialogView {
  service     = ComponentTypeService
  initialData = {
    category: 'Earning',
    taxType: 'Gross Up'
  }
  formView() {
    return (
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.componentType.name')}
          name          = 'name'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.componentType.code')}
          name          = 'code'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.componentType.category')}
          name          = 'category'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          options       = {[{
            id: 'EARNING',
            name: "EARNING"
          }, {
            id: 'DEDUCTION',
            name: 'DEDUCTION'
          }]}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.componentType.taxType')}
          name          = 'taxType'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          options       = {[{
            id: 'GROSS',
            name: "GROSS"
          }, {
            id: 'GROSS UP',
            name: 'GROSS UP'
          }]}
          // validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.componentType.description')}
          name          = 'description'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          // validate      = {validation.required}
        />
      </div>
    )
  }
}
