import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import LogProcessService from './LogProcess.service';
import ListView from '../../../components/entity/listView';
import LogProcessDialog from './LogProcess.dialog'
import download from "downloadjs";

@connect(LogProcessService.stateConnectSetting(), LogProcessService.actionConnectSetting())
export default class LogProcessView extends ListView {
  service     = LogProcessService
  FormDialog  = LogProcessDialog

  async beforeFetch(params) {

      params.module=["Send Email Salary Slip", "PPh21 Eppt Core", "PPh 21 Satu Masa", "BULK HITUNG"],
      params.start = "01/01/2018"
      params.end   = "01/01/5020"
    }

  columns=[
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logImport.name",                  value: "name"},
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logImport.origin",                value: "origin"},
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logImport.module",                value: "module"},
      {isSortable: false,  show: true,   isSearchable:true,  label: "entities.logImport.description",           value: "description"},
      {isSortable: false,  show: true,   isSearchable:false,  label: "entities.logImport.message",               value: "message"},
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logImport.status",                value: "status"},
      {isSortable: false,  show: false,   isSearchable:false,  label: "entities.logImport.delimiter",             value: "delimiter"},
      {isSortable: false,  show: false,   isSearchable:false,  label: "entities.logImport.source",                value: "source"},
      {isSortable: false,  show: false,   isSearchable:false,  label: "entities.logImport.total",                 value: "total",                type: "number"},
      {isSortable: false,  show: false,   isSearchable:false,  label: "entities.logImport.current",               value: "current",              type: "number"},
      {isSortable: false,  show: true,   isSearchable:false,  label: "entities.logImport.error",                 value: "error",                type: "number"},
      {isSortable: true,  show: true,   isSearchable:false,  label: "entities.logImport.start",                 value: "start",                 isDefaultSort: true},
      {isSortable: false,  show: true,   isSearchable:false,  label: "entities.logImport.end",                   value: "end",                  },
      {isSortable: false,  show: false,   isSearchable:false,  label: "entities.logImport.isSourceAvailable",     value: "isSourceAvailable",    type: "boolean"},
      {isSortable: false,  show: false,   isSearchable:false,  label: "entities.logImport.isErrorAvailable",      value: "isErrorAvailable",     type: "boolean"},

  ]

  tableActions = (d)=>{

    var menu =  [
      {
        label:"More Info",
        iconClassName:"mdi mdi-bell",
        onClick: (item) => this.editItem(item)
      },

      {
        label:"Delete",
        iconClassName:"mdi mdi-delete",
        onClick:(item, callback) => this.deleteItem(item, callback),
        confirmation:{
          title:"sentence.custom.deleteItem",
          message:"sentence.custom.deleteItemConfirmation"
        }
      },
      {label:"divider", iconClassName:"-"},
    ]

    if(d.isSourceAvailable) {
      menu.push({
        label:"Download Source",
        iconClassName:"mdi mdi-download",
        onClick: (item) => this.handleDownload(item)
      })
    }

    if(d.isErrorAvailable) {
      menu.push({
        label:"Download Error Log",
        iconClassName:"mdi mdi-download",
        onClick: (item) => this.handleDownloadError(item)
      })
    }

    return menu
  }

  async handleDownload(item) {
    // await this.service.api.download(item, true);
    let res = await this.service.api.download(item);
    let filename = res.headers['content-disposition'].split('filename=').pop();
    download(res.data, filename);
  }

  async handleDownloadError(item) {
    // await this.service.api.downloadError(item, true);
    let res = await this.service.api.downloadError(item);
    let filename = res.headers['content-disposition'].split('filename=').pop();
    download(res.data, filename);
  }

  _barActions = () => {
    var arr = [
      {
        label:'word.refresh',
        iconClassName:'mdi mdi-refresh',
        onClick:() => {

          window.location.hash = window.location.hash.split('?')[0]
          this.fetchData()
        }
      }
    ]

    if(this.showBatchApproval) {
      arr.push({
        label:'word.approval',
        iconClassName:'mdi mdi-clipboard-check-outline',
        onClick:() => this.handleBatchApproval(this.props.table.selected),
        disabledFunc:() => this.props.table.selected.length === 0
      })
    }

    if(this.showDeleteAll) {
      arr.push({
        label:'word.delete',
        iconClassName:'mdi mdi-delete',
        onClick:() => {
          this.setState({showDialogConfirmDeleteSelected:true})
        },
        disabledFunc:() => this.props.table.selected.length === 0
      })
    }
    return arr
  }

}
