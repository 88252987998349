import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import DocumentTypeService from './DocumentType.service';
import ListView from '../../../../components/entity/listView';
import DocumentTypeDialog from './DocumentType.dialog';

@connect(DocumentTypeService.stateConnectSetting(), DocumentTypeService.actionConnectSetting())
export default class DocumentTypeView extends ListView {
  showDeleteAll = true;
  service     = DocumentTypeService
  FormDialog  = DocumentTypeDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.documentType.category",            value: "category"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.documentType.name",                value: "name"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.documentType.description",         value: "description"}

  ]
}
