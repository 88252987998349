import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import ArchiveBp23Service from '../ArchiveBp23/ArchiveBp23.service';


@reduxForm({form: 'ArchiveBp23Form', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('ArchiveBp23Form')(state)
}))
export default class ArchiveBp23Form extends FormView {
  service     = ArchiveBp23Service

  formView() {
    return (
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.archiveSpt23.category')}
          name          = 'category'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt23.masa')}
          name          = 'masa'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt23.tahun')}
          name          = 'tahun'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt23.pembetulan')}
          name          = 'pembetulan'
          className     = 'md-cell md-cell--12'
          component     = {TextfieldMask}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt23.npwp')}
          name          = 'npwp'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt23.name')}
          name          = 'name'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt23.email')}
          name          = 'email'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt23.date')}
          name          = 'date'
          className     = 'md-cell md-cell--6'
          component     = {Datepicker}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt23.bruto')}
          name          = 'bruto'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt23.pph')}
          name          = 'pph'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt23.nomorBp')}
          name          = 'nomorBp'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt23.detail')}
          name          = 'detail'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt23.description')}
          name          = 'description'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
      </div>
    )
  }
}
