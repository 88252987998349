/**
 * Created by dwiargo on 11/30/17.
 */

import * as types from '../actions/actionTypes';
import _ from 'lodash';

const defaultParams = () => ({
  page:1,
  size:20,
  column:'',
  keyword:'',
  sortBy:'createdAt',
  sort:'DESC',
  total:0,
  startDate:'',
  endDate:''
})

const initialState = () => ({
  id: 'tableId',
  columns:[],
  data:[],
  isLoading:false,
  itemActions:[],
  params: _.clone(defaultParams()),
  confirmation:{},
  selected:[],
  selectedItem:null,
  defaultSelectedRows:[],
  error:{
    isError:false,
    title:'',
    message:''
  },
  reload:() => {}
});

let idx, data, columns, target, item, update, selected, params, set;

export default (state = initialState(), action = {}) => {
  switch (action.type){

    case types.TABLE_SET_PROPERTIES:
      if(action.properties.params) {
        params = action.resetParams ? defaultParams() : state.params;
        action.properties.params = _.merge(params, action.properties.params)
        action.properties.params.total = Number(action.properties.params.total);
      }

      return Object.assign({}, state, action.properties);

    case types.TABLE_SET_DATA:
      return Object.assign({}, state, {
        data:action.data
      });

    case types.TABLE_SET_COLUMNS:
      return Object.assign({}, state, {
        columns:action.columns
      });

    case types.TABLE_UPDATE_COLUMN:
      columns = state.columns;
      target = _.find(columns, action.column);
      update = _.merge(target, action.update);
      idx = columns.indexOf(target);
      columns.splice(idx, 1, update);

      return Object.assign({}, state, {
        columns:columns
      });

    case types.TABLE_UPDATE_PARAMS:
      params = _.merge(state.params, action.params);
      return Object.assign({}, state, {
        params:params
      });

    case types.TABLE_ADD_DATA_ITEM:
      params = state.params;    
      set = {
        data:state.params.sort === 'DESC' ? [action.item, ...state.data] : [...state.data, action.item],
      }

      if(!action.disabledTotalIncrement){
        params.total += 1;
        set.params = params;
      }

      return Object.assign({}, state, set);

    case types.TABLE_DELETE_DATA_ITEM_WITH_SELECTOR:
        data = state.data;
        item = _.find(data, action.selector);
        idx = data.indexOf(item);        
        if(item && idx >= 0){
          data.splice(idx, 1);
        }

        params = state.params;    
        params.total -= 1;      

      return Object.assign({}, state, {data, params});


    case types.TABLE_DELETE_DATA_ITEM:
      data = state.data;
      item = _.find(data, action.item);
      idx = data.indexOf(item);
      data.splice(idx, 1);

      selected = state.selected;
      item = _.find(selected, action.item);
      idx = selected.indexOf(item);
      selected.splice(idx,1);

      params = state.params;
      params.total -= 1;      

      return Object.assign({}, state, {
        data:data,
        params: params,
        selected:selected
      });

    case types.TABLE_DELETE_SELECTED_DATA_ITEMS:
      data = state.data.filter(d => {
        item = _.find(state.selected, d);
        return item ? false : true;
      });
      params = state.params;
      params.total -= data.length;
      return Object.assign({}, state, {
        data:data,
        selected:[],
        params: params
      });

    case types.TABLE_UPDATE_DATA_ITEM:
      if(action.index > 0) {
        data = state.data;
        idx = action.index - 1;
        item = data[idx];
        update = _.merge(item, action.update);
        data.splice(idx, 1, update);
      } else if(action.index === 0){
        data = state.data.map(function(d){
          update = _.merge(d, action.update);
          return update;
        })
      }

      return Object.assign({}, state, {
        data:data
      });

    case types.TABLE_UPDATE_DATA_ITEM_WITH_SELECTOR:
        data = state.data;
        item = _.find(data, action.selector);
        idx = data.indexOf(item);        
        if(item && idx >= 0){
          update = _.merge(item, action.update);
          data.splice(idx, 1, update);
        }

      return Object.assign({}, state, {
        data:data
      });

    case types.TABLE_REPLACE_DATA_ITEM:
      if(action.index > 0) {
        data = state.data;
        idx = action.index - 1;
        data.splice(idx, 1, action.update);
      } else if(action.index === 0){
        data = state.data.map(function(d){
          d = action.update;
          return update;
        })
      }

      return Object.assign({}, state, {
        data:data
      });

    case types.TABLE_SELECT_DATA_ITEM:
      if(action.index > 0) {
        item = state.data[action.index - 1];
        selected = state.selected;
        let _item = _.find(selected, item);
        idx = selected.indexOf(_item);
        if (action.isSelect) {
          if(idx < 0) selected.push(item);
        } else {
          if(idx >= 0) selected.splice(idx , 1);
        }
      } else {
        if(action.isSelect) {
          selected = state.data.map(function (d) {
            return d;
          })
        } else {
          selected = [];
        }
      }
      return Object.assign({}, state, {
        selected:selected
      });

    case types.TABLE_RESET_ALL:
      let defaultState = initialState();    
      return Object.assign({}, state, defaultState);

    case types.TABLE_RESET:
      let _initialState = initialState();
      let updatedState = {};
      for(var i = 0 ; i < action.keys.length ; i++){
        let key = action.keys[i];
        updatedState[key] = _initialState[key];
      }
      return Object.assign({}, state, updatedState);

    case types.TABLE_DESELECT_DATA_ITEM:
      return Object.assign({}, state, {
        selectedItem:null
      });

    case types.TABLE_RESET_PARAMS:
      let newParams = initialState().params;
      return Object.assign({}, state, {
        params:newParams
      });

    case types.TABLE_RELOAD_DATA:
      state.reload();
      return Object.assign({}, state, {
        isLoading: true,
        data: []
      });

    default:
      return state;
  }
}