/**
 * Created by dwiargo on 10/11/17.
 */

import navService from './navService';
import cookie from 'react-cookies';

let roles = [];
let logoutAction; //Function
let hasChangeTokenNames = false;
let tokenNames = {
  accessToken: 'SP_ACCESS_TOKEN',
  refreshToken: 'SP_REFRESH_TOKEN',
  accessKey: 'MPK_ACCESS_KEY',
  code: 'MPK_CODE',
  userProfile: 'USER_PROFILE'
}

const authService = {
  setTokenNames: (accessToken='SP_ACCESS_TOKEN', refreshToken= 'SP_REFRESH_TOKEN', code='MPK_CODE') => {
    if(!hasChangeTokenNames){
      tokenNames = {accessToken, refreshToken}
      hasChangeTokenNames = true;
    }
  },
  setLogoutAction: newLogoutAction => {
    logoutAction = newLogoutAction;
  },
  logout: (callback, redirectUri) => {
    if(logoutAction) logoutAction(redirectUri, () => {
      authService.clearCookie();
      if(callback) callback();
    });
    else {
      authService.clearCookie();
    }
  },
  getMe: (callback) => {

  },
  clearCookie:() => {
    cookie.remove(tokenNames.accessToken, {path:'/'});
    cookie.remove(tokenNames.refreshToken, {path:'/'});
    cookie.remove(tokenNames.accessKey, {path:'/'});
    cookie.remove(tokenNames.userProfile, {path:'/'});
  },
  getAccessToken: () => cookie.load(tokenNames.accessToken),
  getRefreshToken: () => cookie.load(tokenNames.refreshToken),
  getAccessKey: () => cookie.load(tokenNames.accessKey),
  getUserProfile: () => cookie.load(tokenNames.userProfile),
  setAccessToken: access_token => cookie.save(tokenNames.accessToken, access_token, {path:'/'}),
  setAccessKey: accessKey => cookie.save(tokenNames.accessKey, accessKey),
  setUserProfile: user => {
    let { username, id, email, name } = user;
    cookie.save(tokenNames.userProfile, JSON.stringify({username, id, email, name}), {path:'/'})
  },
  removeUserProfile: () => cookie.remove(tokenNames.userProfile, {path:'/'}),
  getCode: () => cookie.load(tokenNames.code),
  setCode: code => cookie.save(tokenNames.code, code, {path:'/'}),
  getSession: code => cookie.load('JSESSIONID'),
  initMe: (callback)=> {
    authService.getMe(response => {
      //userService.actions().init(response.data);
      //menuService.actions().init(response.data);
      const currentPath = window.location.pathname;
      if (currentPath === '/' || currentPath === '/login' || currentPath === '/register') {
        if(authService.hasRole('ADMIN')) navService.goToPath('/manage/Application'); else  navService.goToPath('/application');
      }
      if (callback) callback();
    }, ()=> {
      navService.goToPath('/');
    });
  },
  hasRole: role => {
    return roles.indexOf(role) >= 0;
  }
};

export default authService;