import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  TabsContainer,
  Tabs,
  Tab
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import FormView from '../../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import SalarySlipService from './SalarySlip.service';
import StatusPtkpService from '../../Master/StatusPtkp/StatusPtkp.service';
import DesignationService from '../../Master/Designation/Designation.service';
import cloneDeep from 'lodash/cloneDeep'
import EarningService from '../../Master/Earning/Earning.service';
import DeductionService from '../../Master/Deduction/Deduction.service';
import CompanyService from '../../../Admin/Company/Company.service';
import ObjekPajakService from '../../Master/ObjekPajak/ObjekPajak.service';
import EmployeeService from '../Employee/Employee.service';
import utilsService from '../../../../../services/utils.service';

@reduxForm({form: 'SalarySlipForm', destroyOnUnmount: true, initialValues: {
  earnings: [],
  deductions: [],
  earningMap: {},
  deductionMap: {},
  otherInfo: {}
}})
@connect((state) => ({
  formData: getFormValues('SalarySlipForm')(state),
  periode: state.authHalona.currentSPT
}))
export default class SalarySlipForm extends FormView {
  service     = SalarySlipService
  viewType    = 2;

  initialData = {
    basicInfo: {
      npwp: '000000000000000',
      isForeign: false,
      religion: ""
    },
    jobInfo: {
      nettoA1: 0,
      statusPekerja: "Karyawan",
      pphA1: 0,
      memilikiA1: false,
      pernahBekerja: false,
      "isMutation": false,
      "isJoining": false,
      "isRetiring": false,
      dapatPesangon: false,
      totalPesangon: 0
    },
    bankInfo: {
      paymentMethod: 'TRANSFER'
    },
    otherInfo: {
      golongan: 'Kelompok 1',
      bpjsKet: false,
      bpjsKetDitanggung: false,
      bpjsKetPerhitungan: false,
      bpjsKetLainnya: 0,
      bpjsKes: false,
      bpjsKesDitanggung: false,
      bpjsKesPerhitungan: false,
      jaminanPensiun: false,
      bpjsKesLainnya: 0
    },
    earnings: [],
    deductions: [],
    earningMap: {},
    deductionMap: {},
    salaryType: 'NORMAL',
    paymentStatus: 'PAID',
    "totalEarning": 0,
    "totalDeduction": 0,
    "total": 0,
    earnings: [],
    deductions: [],
    earningMap: {},
    deductionMap: {}
  }

  bagBConfig= {
    undefined: {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: false
    },
    '21-100-01': {

    },
    '21-100-02': {

    },
    "21-401-01": {},
    "21-401-02": {},
    "21-402-01": {},
    "21-499-99": {},
    '21-100-03': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: true,
      jumlahTanggungan: true,
      caraPembayaran: true,
      jumlahHariKerja: true,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-04': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-05': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-06': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-07': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-08': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-09': {
      tipePenghasilan: true,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-10': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-11': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-12': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-13': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-99': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: false
    },
    '27-100-99': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: true
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      earningType: [],
      deductionType: []
    }
  }

  async initData() {

    var earningRes  = await EarningService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })
    var deductionRes  = await DeductionService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    this.setState({
      earningType: earningRes.data.data,
      deductionType: deductionRes.data.data
    })

    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      res.data.earningMap = res.data.earnings.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})

      res.data.deductionMap = res.data.deductions.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})

      this.props.initialize(res.data);
    }
  }

  async handleCalculate(value) {
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Konfirmasi',
      initialValue: {},
      text: 'Apakah anda yakin akan meng-kalkulasi ulang slip gaji ini?'
    }));

    if(d) {
      var beforeSave = await this.beforeSave(value);
      var res = null;
      if(beforeSave) {
        if(typeof beforeSave === 'object') value = beforeSave;

        await this.service.api.update(value)
        await this.service.api.generate({
          slipMethod: 'Hanya Hitung',
          paymentDate: this.props.formData.paymentDate,
          komponenDihitung: "Gaji, Tunjangan & THR",
          id: this.props.formData.periode.id,
          employeeType: this.props.formData.otherInfo.tipeKaryawan,
          empId: this.props.formData.employee.id
        })
      }

      this.initData()
    }

  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>
        {this.props.match.params.id != 'new' && <Button primary flat onClick={handleSubmit(this.handleCalculate.bind(this))} style={{marginRight: 16}}>Generate</Button>}
        <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>
      </div>
    )
  }

  formView() {
    return (<div>
      <TabsContainer panelStyle={{overflowY: 'none'}} colored>
        <Tabs tabId="simple-tab" mobile={false}>
          <Tab label="DATA GAJI">
            <div>
          <Card>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.salarySlip.basicInfo.title')} />
            <Divider/>
            <div className='md-grid'>
              <Field
                label         = {counterpart('entities.employee.title')}
                name          = 'employee'
                className     = 'md-cell md-cell--12'
                component     = {Searchfield}
                params        = {{page: 1, sortBy: 'id', size: 90000000, sort: 'ASC'}}
                valueField    = 'parent'
                service       = {EmployeeService}
                viewField     = 'id'
                itemTemplate  = {function(d) {
                  return {
                    primaryText: d.basicInfo? d.basicInfo.name: '',
                    secondaryText: d.basicInfo? d.basicInfo.npwp: '',
                    onClick: () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
              />
              <Field
                label         = {counterpart('entities.salarySlip.salaryType')}
                name          = 'salaryType'
                className     = 'md-cell md-cell--12'
                component     = {Searchfield}
                options       = {[{
                  id: 'NORMAL',
                  name: 'NORMAL'
                }, {
                  id: 'NORMAL',
                  name: 'NORMAL'
                }]}
                validate      = {validation.required}
              />
              <Field
                label         = {counterpart('entities.salarySlip.paymentStatus')}
                name          = 'paymentStatus'
                className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                options       = {[{
                  id: 'PAID',
                  name: 'PAID'
                }, {
                  id: 'HOLD',
                  name: 'HOLD'
                }]}
                validate      = {validation.required}
              />

              <Field
                label         = {counterpart('entities.salarySlip.paymentDate')}
                name          = 'paymentDate'
                className     = 'md-cell md-cell--6'
                component     = {Datepicker}
              />
              <Field
                label         = "Status"
                name          = 'status'
                className     = 'md-cell md-cell--12'
                disabled
                component     = {Textfield}
              />
              <Field
                label         = {counterpart('entities.salarySlip.note')}
                name          = 'note'
                className     = 'md-cell md-cell--12'
                component     = {Textfield}
              />
            </div>
          </Card>
          <br/>
          <Card>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.salarySlip.earningDeduction.title')} />
            <Divider/>
            <DataTable plain>
            <TableHeader>
              <TableRow>
                <TableColumn className=' md-text-left'>{counterpart('entities.salarySlip.earningDeduction.earning')}</TableColumn>
                <TableColumn className=' md-text-right'>{counterpart('entities.salarySlip.earningDeduction.amount')}</TableColumn>
                <TableColumn className=' md-text-left'>{counterpart('entities.salarySlip.earningDeduction.note')}</TableColumn>
                <TableColumn className=' md-text-left'>{counterpart('entities.salarySlip.earningDeduction.delete')}</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>

              {Object.keys(this.props.formData.earningMap).map((k, i) => {
                var d = this.props.formData.earningMap[k];
                var color = "none"
                if (d.earning && d.earning.hideSlipPrintOut) color = "#ffecb3"
                return <TableRow key={d.id} style={{backgroundColor: color}}>
                  <TableColumn>
                    <Field
                      label         = {'_'}
                      name          = {'earningMap.'+d.id+'.earning'}
                      className     = 'md-cell md-cell--12'
                      component     = {Searchfield}
                      valueField    = 'parent'
                      options       = {this.state.earningType}
                    />
                  </TableColumn>
                  <TableColumn>
                    <Field
                      label         = {'_'}
                      name          = {'earningMap.'+d.id+'.amount'}
                      className     = 'md-cell md-cell--12 md-text-right'
                      component     = {TextfieldMask}
                      money         = ','
                    />
                  </TableColumn>
                  <TableColumn className='md-text-right'>
                    <Field
                      label         = {'_'}
                      name          = {'earningMap.'+d.id+'.note'}
                      className     = 'md-cell md-cell--12'
                      component     = {Textfield}
                    />
                  </TableColumn>
                  <TableColumn className='md-text-center'>
                    <Button style={{margin: 0}} secondary flat onClick={()=> this.deleteEarning(d.id)}>{counterpart('entities.salarySlip.earningDeduction.delete')}</Button>
                  </TableColumn>
                </TableRow>
              })}

              <TableRow key={'d'} style={{backgroundColor: '#EEE'}}>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn className='md-text-center'>
                  <Button primary flat onClick={this.addEarning}>{counterpart('entities.salarySlip.earningDeduction.add')}</Button>
                </TableColumn>
              </TableRow>
            </TableBody>
            </DataTable>

            <Divider/>
            <DataTable plain>
            <TableHeader>
              <TableRow>
                <TableColumn className=' md-text-left'>{counterpart('entities.salarySlip.earningDeduction.deduction')}</TableColumn>
                <TableColumn className=' md-text-right'>{counterpart('entities.salarySlip.earningDeduction.amount')}</TableColumn>
                <TableColumn className=' md-text-left'>{counterpart('entities.salarySlip.earningDeduction.note')}</TableColumn>
                <TableColumn className=' md-text-left'>{counterpart('entities.salarySlip.earningDeduction.delete')}</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {Object.keys(this.props.formData.deductionMap).map((k, i) => {
                var d = this.props.formData.deductionMap[k];
                var color = "none"
                if (d.deduction && d.deduction.hideSlipPrintOut) color = "#ffecb3"
                return <TableRow key={d.id} style={{backgroundColor: color}}>
                  <TableColumn>
                    <Field
                      label         = {'_'}
                      name          = {'deductionMap.'+d.id+'.deduction'}
                      className     = 'md-cell md-cell--12'
                      component     = {Searchfield}
                      valueField    = 'parent'
                      options       = {this.state.deductionType}
                    />
                  </TableColumn>
                  <TableColumn>
                    <Field
                      label         = {'_'}
                      name          = {'deductionMap.'+d.id+'.amount'}
                      className     = 'md-cell md-cell--12 md-text-right'
                      component     = {TextfieldMask}
                      money         = ','
                    />
                  </TableColumn>
                  <TableColumn className='md-text-right'>
                    <Field
                      label         = {'_'}
                      name          = {'deductionMap.'+d.id+'.note'}
                      className     = 'md-cell md-cell--12'
                      component     = {Textfield}
                    />
                  </TableColumn>
                  <TableColumn className='md-text-center'>
                    <Button style={{margin: 0}} secondary flat onClick={()=> this.deleteDeduction(d.id)}>{counterpart('entities.salarySlip.earningDeduction.delete')}</Button>
                  </TableColumn>
                </TableRow>
              })}

              <TableRow key={'k1'} style={{backgroundColor: '#EEE'}}>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn></TableColumn>
                <TableColumn className='md-text-center'>
                  <Button primary flat onClick={this.addDeduction}>{counterpart('entities.salarySlip.earningDeduction.add')}</Button>
                </TableColumn>
              </TableRow>

              <TableRow key={'d1'}>
                <TableColumn>THP</TableColumn>
                <TableColumn className="md-text-right">{utilsService.number.format2( this.totalEarning())}</TableColumn>
                <TableColumn></TableColumn>
                <TableColumn className='md-text-center'></TableColumn>
              </TableRow>
            </TableBody>
            </DataTable>
          </Card>
          </div>
          </Tab>
          <Tab label="DATA DETAIL KARYAWAN">
          <Card>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.basicInfo.title')} />
            <Divider/>
            <div className='md-grid'>
              {/* <Field
                label         = {counterpart('entities.company.title')}
                name          = 'companyInfo'
                className     = 'md-cell md-cell--12'
                component     = {Searchfield}
                valueField    = 'parent'
                service       = {CompanyService}
              /> */}
              <Field
                label         = {counterpart('entities.employee.basicInfo.name')}
                name          = 'basicInfo.name'
                className     = 'md-cell md-cell--12'
                component     = {Textfield}
                validate      = {validation.required}
              />
              <Field
                label         = {counterpart('entities.employee.basicInfo.npwp')}
                name          = 'basicInfo.npwp'
                className     = 'md-cell md-cell--6'
                component     = {TextfieldMask}
                maskFormat    = "##.###.###.#-###-###"
                mask          = "_"
                length        = {15}
                validate      = {validation.required}
              />
              <Field
                label         = {counterpart('entities.employee.basicInfo.identityNo')}
                name          = 'basicInfo.identityNo'
                className     = 'md-cell md-cell--6'
                component     = {Textfield}
              />
              <Field
                label         = {counterpart('entities.employee.basicInfo.address')}
                name          = 'basicInfo.address'
                className     = 'md-cell md-cell--12'
                component     = {Textfield}
              />
              <Field
                label         = {counterpart('entities.employee.basicInfo.email')}
                name          = 'basicInfo.email'
                className     = 'md-cell md-cell--12'
                component     = {Textfield}
              />
              <Field
                label         = {counterpart('entities.employee.basicInfo.isForeign')}
                name          = 'basicInfo.isForeign'
                className     = 'md-cell md-cell--6'
                component     = {Switch}
              />
              <Field
                label         = {counterpart('entities.employee.basicInfo.country')}
                name          = 'basicInfo.country'
                className     = 'md-cell md-cell--6'
                component     = {Textfield}
              />
              <Field
                label         = {counterpart('entities.employee.basicInfo.religion')}
                name          = 'basicInfo.religion'
                className     = 'md-cell md-cell--6'
                component     = {Textfield}
              />
              <Field
                label         = {"Jenis Kelamin"}
                name          = 'basicInfo.gender'
                className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                valueField    = 'name'
                options       = {[{
                  id: "MALE",
                  name: "LAKI-LAKI"
                },{
                  id: "FEMALE",
                  name: "PEREMPUAN"
                }]}
              />

              <Field
                label         = {counterpart('entities.employee.basicInfo.ptkp')}
                name          = 'basicInfo.ptkp'
                className     = 'md-cell md-cell--12'
                component     = {Searchfield}
                valueField    = 'name'
                service       = {StatusPtkpService}
                onChange      = {(e, d) => {
                  if(d) {
                    var s = d.split("/")
                    this.props.change("basicInfo.maritalStatus", s[0])
                    this.props.change("basicInfo.jumlahTanggungan", parseInt(s[1]))
                  }
                }}
              />

              <Field
                label         = {counterpart('entities.nonEmployee.tidakFinalInfo.statusPtkp')}
                name          = 'basicInfo.maritalStatus'
                className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                options       = {[{
                  id: "K",
                  name: "K"
                },{
                  id: "TK",
                  name: "TK"
                },{
                  id: "K/I",
                  name: "K/I"
                }]}
              />
              <Field
                label         = {counterpart('entities.nonEmployee.tidakFinalInfo.jumlahTanggungan')}
                name          = 'basicInfo.jumlahTanggungan'
                className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                options       = {[{
                  id: 0,
                  name: "0"
                },{
                  id: 1,
                  name: "1"
                },{
                  id: 2,
                  name: "2"
                },{
                  id: 3,
                  name: "3"
                }]}
              />
            </div>
          </Card>

          <br/>
          <Card>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.jobInfo.title')} />
            <Divider/>
            <div className='md-grid'>

              <Field
                label         = {counterpart('entities.employee.jobInfo.employeeNo')}
                name          = 'jobInfo.employeeNo'
                className     = 'md-cell md-cell--6'
                component     = {Textfield}
              />
              <Field
                label         = {counterpart('entities.employee.jobInfo.status')}
                name          = 'jobInfo.status'
                className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                options       = {[{
                  id: 'ACTIVE',
                  name: 'ACTIVE'
                }, {
                  id: 'LEFT',
                  name: 'LEFT'
                }]}
              />

              <Field
                label         = {counterpart('entities.employee.jobInfo.designation')}
                name          = 'jobInfo.designation'
                className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                service       = {DesignationService}
                valueField    = 'name'
              />
              <Field
                label         = {counterpart('entities.employee.jobInfo.department')}
                name          = 'jobInfo.department'
                className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                options = {this.state.departments}
                valueField    = 'parent'
              />
              <Field
                label         = {counterpart('entities.employee.jobInfo.employeeType')}
                name          = 'otherInfo.tipeKaryawan'
                className     = 'md-cell md-cell--12'
                component     = {Searchfield}
                options       = {[{id: '0', name: 'Karyawan Tetap'}, {id: '1', name: 'Karyawan Tidak Tetap / Bukan Karyawan'}]}
              />
            {(this.props.formData.otherInfo.tipeKaryawan == '0' && this.props.formData.jobInfo.status == 'ACTIVE') && <Field
            label         = {"Status Pindahan"}
            name          = 'otherInfo.statusPindahan'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: '0',
              name: 'Normal'
            }, {
              id: '3',
              name: 'Berhenti meninggal/pindah ke LN/dari LN'
            }, {
              id: '4',
              name: 'Pegawai baru'
            }, {
              id: '5',
              name: 'Pindahan'
            }]}
            onChange      = {(e, d)=> {
              switch (d) {
                case '0':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                case '1':
                  this.props.change('jobInfo.isMutation', true)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '2':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '3':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '4':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', true)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                case '5':
                  this.props.change('jobInfo.isMutation', true)
                  this.props.change('jobInfo.isJoining', true)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                default:
                  break;
              }
            }}
          />}
          {(this.props.formData.otherInfo.tipeKaryawan == '0' && this.props.formData.jobInfo.status == 'LEFT') && <Field
            label         = {"Status Pindahan"}
            name          = 'otherInfo.statusPindahan'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[ {
              id: '1',
              name: 'Dipindahkan ke cabang lain'
            }, {
              id: '2',
              name: 'Berhenti ke perusahaan lain/pensiun'
            }, {
              id: '3',
              name: 'Berhenti meninggal/pindah ke LN/dari LN'
            }]}
            onChange      = {(e, d)=> {
              switch (d) {
                case '0':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                case '1':
                  this.props.change('jobInfo.isMutation', true)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '2':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '3':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '4':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', true)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                case '5':
                  this.props.change('jobInfo.isMutation', true)
                  this.props.change('jobInfo.isJoining', true)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                default:
                  break;
              }
            }}
          />}
              {/* <Field
                label         = {counterpart('entities.employee.jobInfo.isMutation')}
                name          = 'jobInfo.isMutation'
                className     = 'md-cell md-cell--6'
                component     = {Switch}
              /> */}
              {/* <div className     = 'md-cell md-cell--6' /> */}
              {/* <Field
                label         = {counterpart('entities.employee.jobInfo.mutationDate')}
                name          = 'jobInfo.mutationDate'
                className     = 'md-cell md-cell--6'
                component     = {Datepicker}
              /> */}

              {/* <Field
                label         = {counterpart('entities.employee.jobInfo.isJoining')}
                name          = 'jobInfo.isJoining'
                className     = 'md-cell md-cell--6'
                component     = {Switch}
              /> */}
              <Field
                label         = {counterpart('entities.employee.jobInfo.joinedDate')}
                name          = 'jobInfo.joinedDate'
                className     = 'md-cell md-cell--6'
                component     = {Datepicker}
              />

              {/* <Field
                label         = {counterpart('entities.employee.jobInfo.isRetiring')}
                name          = 'jobInfo.isRetiring'
                className     = 'md-cell md-cell--6'
                component     = {Switch}
              /> */}
              <Field
                label         = {counterpart('entities.employee.jobInfo.retireDate')}
                name          = 'jobInfo.retireDate'
                className     = 'md-cell md-cell--6'
                component     = {Datepicker}
              />

              <Field
                label         = {counterpart('entities.employee.jobInfo.pernahBekerja')}
                name          = 'jobInfo.pernahBekerja'
                className     = 'md-cell md-cell--6'
                component     = {Switch}
              />

              <Field
                label         = {counterpart('entities.employee.jobInfo.memilikiA1')}
                name          = 'jobInfo.memilikiA1'
                className     = 'md-cell md-cell--6'
                component     = {Switch}
              />

              {(this.props.formData && this.props.formData.jobInfo && this.props.formData.jobInfo.memilikiA1) && <Field
                label         = {counterpart('entities.employee.jobInfo.nettoA1')}
                name          = {'jobInfo.nettoA1'}
                className     = 'md-cell md-cell--6 md-text-right'
                component     = {TextfieldMask}
                money         = ','
                />}

              {(this.props.formData && this.props.formData.jobInfo && this.props.formData.jobInfo.memilikiA1) && <Field
                label         = {counterpart('entities.employee.jobInfo.pphA1')}
                name          = {'jobInfo.pphA1'}
                className     = 'md-cell md-cell--6 md-text-right'
                component     = {TextfieldMask}
                money         = ','
                />}


              <Field
                label         = {counterpart('entities.employee.jobInfo.note')}
                name          = 'jobInfo.note'
                className     = 'md-cell md-cell--12'
                component     = {Textfield}
              />

            </div>
          </Card>

          <br/>
          <Card>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.bankInfo.title')} />
            <Divider/>
            <div className='md-grid'>
              <Field
                label         = {counterpart('entities.employee.bankInfo.paymentMethod')}
                name          = 'bankInfo.paymentMethod'
                className     = 'md-cell md-cell--12'
                component     = {Searchfield}
                options       = {[{
                  id: 'CASH',
                  name: 'CASH'
                }, {
                  id: 'TRANSFER',
                  name: 'TRANSFER'
                }]}
              />
              <Field
                label         = {counterpart('entities.employee.bankInfo.name')}
                name          = 'bankInfo.name'
                className     = 'md-cell md-cell--6'
                component     = {Textfield}
              />
              <Field
                label         = {counterpart('entities.employee.bankInfo.no')}
                name          = 'bankInfo.no'
                className     = 'md-cell md-cell--6'
                component     = {Textfield}
              />
            </div>
          </Card>

          <br/>
          <Card>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle={"Info Pajak"} />
            <Divider/>
            <div className='md-grid'>

              {this.props.formData.otherInfo.tipeKaryawan == '1' && <Field
                label         = {counterpart('entities.employee.otherInfo.objekPajak')}
                name          = 'otherInfo.objekPajak'
                className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                valueField    = 'id'
                onChange={(e, v, p)=> {
                  if(v === '21-100-08') {
                    this.props.change('otherInfo.tipePenghasilan', 'BG')
                  } else if(v === '21-100-09') {
                    this.props.change('otherInfo.tipePenghasilan', 'TBG')
                  } else {
                    this.props.change('otherInfo.tipePenghasilan', 'BG')
                  }
                }}
                itemTemplate={function(d) {
                  var text = d.name+" - "+d.detail;
                  if(!d.detail) text = ""
                  return {
                    primaryText: text,
                    secondaryText: d.detail,
                    onClick: () => {this.handleItemClick(d); this.hide() }
                  }
                }}
                options={[
                  {id: '21-100-03', name:'21-100-03', detail: 'Upah Pegawai Tidak tetap atau Tenaga Kerja Lepas'},
                  {id: '21-100-04', name:'21-100-04', detail: 'Imbalan Kepada Distributor Multi Level Marketing (MLM)'},
                  {id: '21-100-05', name:'21-100-05', detail: 'Imbalan kepada Petugas Dinas Luar Asuransi'},
                  {id: '21-100-06', name:'21-100-06', detail: 'Imbalan Kepada Penjaja Barang Dadangan'},
                  {id: '21-100-07', name:'21-100-07', detail: 'Imbalan Kepada Tenaga Ahli'},
                  {id: '21-100-08', name:'21-100-08', detail: 'Imbalan Kepada Bukan Pegawai yang Menerima Penghasilan yang Bersifat Berkesinambungan'},
                  {id: '21-100-09', name:'21-100-09', detail: 'Imbalan Kepada Bukan Pegawai yang Menerima Penghasilan yang Tidak Bersifat Berkesinambungan'},
                  {id: '21-100-10', name:'21-100-10', detail: 'Honorarium atau Imbalan Kepada Anggota Dewan Komisaris atau Dewan Pengawas yang tidak Merangkap sebagai Pegawai Tetap'},
                  {id: '21-100-11', name:'21-100-11', detail: 'Jasa Produksi, Tantiem, Bonus atau Imbalan Kepada Mantan Pegawai'},
                  {id: '21-100-12', name:'21-100-12', detail: 'Penarikan Dana Pensiun oleh Pegawai'},
                  {id: '21-100-13', name:'21-100-13', detail: 'Imbalan Kepada Peserta Kegiatan'},
                  {id: '21-100-99', name:'21-100-99', detail: 'Objek PPh Pasal 21 Tidak Final Lainnya'},
                  {id: '27-100-99', name:'27-100-99', detail: 'Imbalan sehubungan dengan jasa, pekerjaan dan kegiatan, hadiah dan penghargaan, pensiun dan pembayaran berkala lainnya yang dipotong PPh Pasal 26'},
                  {id: '21-401-01', name:'21-401-01', detail: 'Uang Pesangon yang Dibayarkan Sekaligus'},
                  {id: '21-401-02', name:'21-401-02', detail: 'Uang Manfaat Pensiun, Tunjangan Hari Tua, atau Jaminan Hari Tua yang Dibayarkan Sekaligus'},
                  {id: '21-402-01', name:'21-402-01', detail: 'Honor dan Imbalan Lain yang Dibebankan kepada APBN atau APBD yang Diterima oleh PNS, Anggota TNI/POLRI, Pejabat Negara dan Pensiunannya'},
                  {id: '21-499-99', name:'21-499-99', detail: 'Objek PPh Pasal 21 Final Lainnya'},
                ]}
              />}

              {this.props.formData.otherInfo.tipeKaryawan == '0' && <Field
                label         = {counterpart('entities.employee.otherInfo.objekPajak')}
                name          = 'otherInfo.objekPajak'
                className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                valueField    = 'id'
                onChange={(e, v, p)=> {
                  if(v === '21-100-08') {
                    this.props.change('otherInfo.tipePenghasilan', 'BG')
                  } else if(v === '21-100-09') {
                    this.props.change('otherInfo.tipePenghasilan', 'TBG')
                  } else {
                    this.props.change('otherInfo.tipePenghasilan', 'BG')
                  }
                }}
                itemTemplate={function(d) {
                  var text = d.name+" - "+d.detail;
                  if(!d.detail) text = ""
                  return {
                    primaryText: text,
                    secondaryText: d.detail,
                    onClick: () => {this.handleItemClick(d); this.hide() }
                  }
                }}
                options={[
                  {id: '21-100-01', name:'21-100-01', detail: 'Pegawai Tetap'},
                  {id: '21-100-02', name:'21-100-02', detail: 'Penerima Pensiun secara teratur'}
                ]}
              />}
              <Field
                label         = {counterpart('entities.employee.otherInfo.taxType')}
                name          = 'otherInfo.taxType'
                className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                options       = {[{
                  id: 'GROSS',
                  name: 'GROSS'
                }, {
                  id: 'GROSS UP',
                  name: 'GROSS UP'

                }, {
                  id: 'NETT',
                  name: 'NETT'
                }]}
              />
              {/* <Field
                label         = {counterpart('entities.employee.otherInfo.objekPajak')}
                name          = 'otherInfo.objekPajak'
                className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                valueField    = 'code'
                service       = {ObjekPajakService}
              /> */}
              {/* <Field
                label         = {counterpart('entities.employee.jobInfo.tipePembayaran')}
                name          = 'otherInfo.tipePembayaran'
                className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                options       = {[{id: 'B', name: 'Bulanan'}, {id: 'TB', name: 'Tidak Bulanan'}]}
              /> */}

              {this.bagBConfig[this.props.formData.otherInfo.objekPajak].tipePenghasilan && <Field
                label         = {counterpart('entities.employee.otherInfo.tipePenghasilan')}
                name          = 'otherInfo.tipePenghasilan'
                className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                options       = {[{
                  id: "BG",
                  name: "BERKESINAMBUNGAN"
                },{
                  id: "TBG",
                  name: "TIDAK BERKESINAMBUNGAN"
                }]}
              />}
              {this.bagBConfig[this.props.formData.otherInfo.objekPajak].dipotongOleh && this.props.formData.otherInfo.tipePenghasilan !== 'TBG' && <Field
                label         = {counterpart('entities.employee.otherInfo.dipotongOleh')}
                name          = 'otherInfo.dipotongOleh'
                className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                options       = {[{
                  id: "SPK",
                  name: "SATU PEMBERI KERJA"
                },{
                  id: "LSPK",
                  name: "LEBIH DARI SATU PEMBERI KERJA"
                }]}
              />}

              {this.bagBConfig[this.props.formData.otherInfo.objekPajak].caraPembayaran && <Field
                label         = {counterpart('entities.employee.otherInfo.caraPembayaran')}
                name          = 'otherInfo.caraPembayaran'
                className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                options       = {[{
                  id: "B",
                  name: "BULANAN"
                },{
                  id: "TB",
                  name: "TIDAK BULANAN"
                }]}
              />}

              {(this.bagBConfig[this.props.formData.otherInfo.objekPajak].jumlahHariKerja && this.props.formData.otherInfo.caraPembayaran == 'B') && <Field
                label         = {counterpart('entities.employee.otherInfo.jumlahHariKerja')}
                name          = 'otherInfo.jumlahHariKerja'
                className     = 'md-cell md-cell--6'
                component     = {TextfieldMask}
                money         = ","
              />}
            </div>
          </Card>

          <br/>
          <Card>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle={"Info Lainnya"} />
            <Divider/>
            <div className='md-grid'>
              <Field
                label         = {counterpart('entities.employee.otherInfo.golongan')}
                name          = 'otherInfo.golongan'
                className     = 'md-cell md-cell--12'
                component     = {Searchfield}
                options       = {[{
                  id: 'Kelompok 1',
                  name: 'Kelompok 1'
                }, {
                  id: 'Kelompok 2',
                  name: 'Kelompok 2'

                }, {
                  id: 'Kelompok 3',
                  name: 'Kelompok 3'

                }, {
                  id: 'Kelompok 4',
                  name: 'Kelompok 4'

                }, {
                  id: 'Kelompok 5',
                  name: 'Kelompok 5'

                }]}
              />
              <div className='md-cell md-cell--6'>
              <Field
                label         = {counterpart('entities.employee.otherInfo.bpjsKet')}
                name          = {'otherInfo.bpjsKet'}
                // className     = 'md-cell md-cell--6'
                component     = {Switch}
                />
              <Field
                label         = {counterpart('entities.employee.otherInfo.bpjsKetDitanggung')}
                name          = {'otherInfo.bpjsKetDitanggung'}
                // className     = 'md-cell md-cell--6'
                component     = {Switch}
                />

              <Field
                label         = {"Mendapatkan Jaminan Pensiun"}
                name          = {'otherInfo.jaminanPensiun'}
                // className     = 'md-cell md-cell--6'
                component     = {Switch}
                />
              <Field
                label         = {counterpart('entities.employee.otherInfo.bpjsKetPerhitungan')}
                name          = {'otherInfo.bpjsKetPerhitungan'}
                // className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                options = {[{id: false, name: "Gaji Pokok"}, {id: true, name: "Nilai Lainnya"}]}
                />
              <Field
                label         = {counterpart('entities.employee.otherInfo.bpjsKetLainnya')}
                name          = {'otherInfo.bpjsKetLainnya'}
                // className     = 'md-cell md-cell--6'
                component     = {TextfieldMask}
                money         = ','
                />

              </div>
              <div className='md-cell md-cell--6'>
              <Field
                label         = {counterpart('entities.employee.otherInfo.bpjsKes')}
                name          = {'otherInfo.bpjsKes'}
                // className     = 'md-cell md-cell--6'
                component     = {Switch}
                />
              <Field
                label         = {counterpart('entities.employee.otherInfo.bpjsKesDitanggung')}
                name          = {'otherInfo.bpjsKesDitanggung'}
                // className     = 'md-cell md-cell--6'
                component     = {Switch}
                />
              <Field
                label         = {counterpart('entities.employee.otherInfo.bpjsKesPerhitungan')}
                name          = {'otherInfo.bpjsKesPerhitungan'}
                // className     = 'md-cell md-cell--6'
                component     = {Searchfield}
                options = {[{id: false, name: "Gaji Pokok"}, {id: true, name: "Nilai Lainnya"}]}
                />
              <Field
                label         = {counterpart('entities.employee.otherInfo.bpjsKesLainnya')}
                name          = {'otherInfo.bpjsKesLainnya'}
                // className     = 'md-cell md-cell--6'
                component     = {TextfieldMask}
                money         = ','
                />
              </div>
            </div>
          </Card>
          </Tab>
        </Tabs>
      </TabsContainer>

    </div>)
  }

  async beforeSave(value) {
    value = cloneDeep(value)
    value.periode = this.props.periode.data



    Object.keys(value.earningMap).forEach((k, i)=> {
      if(!value.earningMap[k].id) delete value.earningMap[k]
    })

    Object.keys(value.deductionMap).forEach((k, i)=> {
      if(!value.deductionMap[k].id) delete value.deductionMap[k]
    })


    value.earnings = Object.keys(value.earningMap).map((k, i) => {
      if(value.earningMap[k].id.search('NEW_DATA') >= 0) {
        delete value.earningMap[k].id
      }
      return value.earningMap[k]
    })

    value.deductions = Object.keys(value.deductionMap).map((k, i) => {
      if(value.deductionMap[k].id.search('NEW_DATA') >= 0) {
        delete value.deductionMap[k].id
      }
      return value.deductionMap[k]
    })

    return value;
  }

  addEarning = (e)=> {
    e.preventDefault()
    var earningMap = cloneDeep(this.props.formData.earningMap)
    earningMap['NEW_DATA_'+Object.keys(earningMap).length] = {
      id: 'NEW_DATA_'+Object.keys(earningMap).length,
      amount: 0,
      isEncrypted: false,
      earning: {}
    }
    this.props.dispatch(this.props.change('earningMap', earningMap))
  }

  deleteEarning = (id)=> {
    var earningMap = cloneDeep(this.props.formData.earningMap)
    delete earningMap[id]

    this.props.dispatch(this.props.change('earningMap', earningMap))
  }

  addDeduction = (e)=> {
    e.preventDefault()
    var deductionMap = cloneDeep(this.props.formData.deductionMap)
    deductionMap['NEW_DATA_'+Object.keys(deductionMap).length] = {
      id: 'NEW_DATA_'+Object.keys(deductionMap).length,
      amount: 0,
      isEncrypted: false,
      deduction: {}
    }
    this.props.dispatch(this.props.change('deductionMap', deductionMap))
  }

  deleteDeduction = (id)=> {
    var deductionMap = cloneDeep(this.props.formData.deductionMap)
    delete deductionMap[id]

    this.props.dispatch(this.props.change('deductionMap', deductionMap))
  }

  totalEarning() {
    let totalEarning = Object.keys(this.props.formData.earningMap).filter(x => !this.props.formData.earningMap[x].earning.hideSlipPrintOut).reduce((pv, cv) => {
      var d = this.props.formData.earningMap[cv]
      return pv + d.amount
    }, 0)

    let totalDeduction = Object.keys(this.props.formData.deductionMap).filter(x => !this.props.formData.deductionMap[x].deduction.hideSlipPrintOut).reduce((pv, cv) => {
      var d = this.props.formData.deductionMap[cv]
      return pv + d.amount
    }, 0)

    return totalEarning - totalDeduction
  }
}
