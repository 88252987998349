import React, { Component } from 'react';
import DialogConfirm from './DialogConfirm';
import DialogAlert from './DialogAlert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as dialogActions from '../../redux/actions/dialogActions';

class Dialog extends Component{
    onHideDialog = (type, actionType) => {
        let __action = this.props.dialog[type === 'alert' ? 'onHide' : 'onCancel']
        if(__action) __action(actionType);
        setTimeout(this.props.dialogActions.hide);
    }

    render(){
        var title = ""
        var message = ""
        var onConfirmed = ""
        var showConfirmation = ""
        var showAlert = ""
        var cancelLabel = ""
        var submitLabel = ""
        if(this.props.dialog){
            if(this.props.dialog.title) title = this.props.dialog.title
            if(this.props.dialog.message) message = this.props.dialog.message
            if(this.props.dialog.onConfirmed) onConfirmed = this.props.dialog.onConfirmed
            if(this.props.dialog.showConfirmation) showConfirmation = this.props.dialog.showConfirmation
            if(this.props.dialog.showAlert) showAlert = this.props.dialog.showAlert
            if(this.props.dialog.cancelLabel) cancelLabel = this.props.dialog.cancelLabel
            if(this.props.dialog.submitLabel) submitLabel = this.props.dialog.submitLabel
        }
        return(
            <div className="mpk-dialog-root">
                <DialogConfirm
                    visible={showConfirmation}
                    title={title}
                    message={message}
                    onSubmit={(callback) => onConfirmed(callback)}
                    onCancel={this.onHideDialog}
                    submitLabel={submitLabel}
                    cancelLabel={cancelLabel}
                    global={this.props.global}
                />
                <DialogAlert
                    visible={showAlert}
                    title={title}
                    message={message}
                    onCancel={() => this.onHideDialog('alert')}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    dialog: state.dialog,
    global:state.global
})

const mapDispatchToProps = dispatch => ({
    dialogActions:bindActionCreators(dialogActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);