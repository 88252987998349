import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import CurrencyService from './Currency.service';
import ListView from '../../../../components/entity/listView';
import CurrencyDialog from './Currency.dialog';

@connect(CurrencyService.stateConnectSetting(), CurrencyService.actionConnectSetting())
export default class CurrencyView extends ListView {
  service     = CurrencyService
  FormDialog  = CurrencyDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.currency.country",      value: "country"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.currency.currency",     value: "currency"},
    {isSortable: false,  show: false,   isSearchable:true,  label: "entities.currency.ISO4217",      value: "ISO4217"}

  ]
}
