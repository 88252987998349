import React from 'react';
import './NewsTicker.scss';
import Marquee from './Marquee/Marquee';
import qs from 'query-string';
import icon from './icon-24.png';

import mpkEnv from '../../config/env';
import { themes } from '../../config/constant';

class NewsTicker extends React.Component{
  state = {
    data: [],
    isLoading: true
  }

  componentWillMount(){
    this.fetchData();
  }

  fetchData = async () => {
    let { host, baseUrl, channel, clientId } = mpkEnv.portal;
    try {
      let res = await fetch(`${host}${baseUrl}/api/news-tickers/es/all?${qs.stringify({
          sort:1,
          sortBy:'datePublished',
          channels: channel
        })}`,
        {
          headers:{
            'x-client':clientId
          }
        }
      )

      let data = await res.json();
      this.setState({data, isLoading:false});
    } catch (error) {
      this.setState({isLoading: false})
      console.log(error);
    }
  }

  render(){
    let { data } = this.state;

    return data.length > 0 ? (
      <div className={`pr-news-ticker ${mpkEnv.theme===themes.SOBATPAJAK ? 'sp' : ''}`}>
        <Marquee
          duration={72}
          contentItems={data.map( d => (
            d.url ? 
              <a href={d.url} type="self" className="news-ticker-item">
                <div style={{width: 48, textAlign: 'center'}}>
                  <img src={icon}/>
                </div>
                <span>{d.title}</span>
              </a>
              :
              <div className="news-ticker-item">
                <div style={{width: 48, textAlign: 'center'}}>
                  { mpkEnv.theme === themes.PAJAKKU ? <img src={icon}/> : (
                    <div className="news-ticker-item-divider sp"/>
                  )}
                </div>
                <span>{d.title}</span>
              </div>
          ))}
        />
      </div>
    ) : null
  }
}

export default NewsTicker;