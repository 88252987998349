/**
 * Created by dwiargo on 11/29/17.
 */

import * as types from '../actions/actionTypes';

const initialState = {
  isLoggedIn:false,
  hasPermission:() => false,
  user:{
    name:'...',
    email:'...',
    menus:[]
  }
};

export default ((state = initialState, action = {}) => {
  switch (action.type){
    
    case types.AUTH_SET_PROPERTIES:
      return Object.assign({}, state, action.properties);

    default:
      return state;
  }
})