import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import ArchiveBp4a2Service from './ArchiveBp4a2.service';


@reduxForm({form: 'ArchiveBp4a2Form', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('ArchiveBp4a2Form')(state)
}))
export default class ArchiveBp4a2Form extends FormView {
  service     = ArchiveBp4a2Service

  formView() {
    return (
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.archiveSpt4a2.category')}
          name          = 'category'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt4a2.masa')}
          name          = 'masa'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt4a2.tahun')}
          name          = 'tahun'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt4a2.pembetulan')}
          name          = 'pembetulan'
          className     = 'md-cell md-cell--12'
          component     = {TextfieldMask}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt4a2.npwp')}
          name          = 'npwp'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt4a2.name')}
          name          = 'name'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt4a2.email')}
          name          = 'email'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt4a2.date')}
          name          = 'date'
          className     = 'md-cell md-cell--6'
          component     = {Datepicker}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt4a2.bruto')}
          name          = 'bruto'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt4a2.pph')}
          name          = 'pph'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt4a2.nomorBp')}
          name          = 'nomorBp'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt4a2.detail')}
          name          = 'detail'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt4a2.description')}
          name          = 'description'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
      </div>
    )
  }
}
