import React from 'react';
import { Route, Switch } from 'react-router-dom';

export default (props) => (
  <Switch>

    <Route path={props.match.path+'/salarySlip/:id'} exact component={require('./SalarySlip/SalarySlip.form').default}/>
    <Route path={props.match.path+'/salarySlip'} exact component={require('./SalarySlip/SalarySlip.view').default}/>
    <Route path={props.match.path+"/generateSlip"} exact component={require('./SalarySlip/GenerateSlip.form').default}/>

    <Route path={props.match.path+'/pph21Data/:id'} exact component={require('./PPh21Data/PPh21Data.form').default}/>
    <Route path={props.match.path+'/pph21Data'} exact component={require('./PPh21Data/PPh21Data.view').default}/>
    <Route path={props.match.path+'/pph21Import'} exact component={require('./PPh21Data/PPh21.import').default}/>

    <Route path={props.match.path+'/pph21DataFinal/:id'} exact component={require('./PPh21Final/PPh21Final.form').default}/>
    <Route path={props.match.path+'/pph21DataFinal'} exact component={require('./PPh21Final/PPh21Final.view').default}/>

    <Route path={props.match.path+'/pph21DataTidakFinal/:id'} exact component={require('./PPh21TidakFinal/PPh21TidakFinal.form').default}/>
    <Route path={props.match.path+'/pph21DataTidakFinal'} exact component={require('./PPh21TidakFinal/PPh21TidakFinal.view').default}/>

    <Route path={props.match.path+'/logImport'} exact component={require('../../LogImport/LogImport.view').default}/>
    <Route path={props.match.path+'/logProcess'} exact component={require('../../LogProcess/LogProcess.view').default}/>

    <Route path={props.match.path+'/pph21A1/:id'} exact component={require('./PPh21A1/PPh21A1.form').default}/>
    <Route path={props.match.path+'/pph21A1'} exact component={require('./PPh21A1/PPh21A1.view').default}/>

    <Route path={props.match.path+'/dashboard'} exact component={require('./Periode/Periode.dashboard').default}/>


    <Route path={props.match.path+'/'} component={function() {return <div></div>}} />
  </Switch>
)
