import counterpart from 'counterpart';
import React from 'react';
import { Button, Card, CardTitle, DataTable, Divider, TableBody, TableColumn, TableHeader, TableRow , TabsContainer, Tab, Tabs} from 'react-md';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import FormView from '../../../../../components/entity/form.view';
import { Datepicker, Searchfield, Textfield, TextfieldMask, validation } from '../../../../../components/form';
import DeductionService from '../../Master/Deduction/Deduction.service';
import EarningService from '../../Master/Earning/Earning.service';
import EmployeeService from '../Employee/Employee.service';
import SalarySlipService from './SalarySlip.service';
import iziToast from 'izitoast'

@reduxForm({form: 'GenerateSlipForm', destroyOnUnmount: true, initialValues: {
}})
@connect((state) => ({
  data: getFormValues('GenerateSlipForm')(state),
  periode: state.authHalona.currentSPT
}))
export default class GenerateSlipForm extends FormView {
  translate   = false;
  service     = SalarySlipService
  viewType    = 2;

  initialData = {
    // tipePembayaran: 'Bulanan',
    komponenDihitung: 'Gaji, Tunjangan & THR',
    // paymentDate: new Date(),
  }

  titleHeader() {
    return `Generate Slip`;
  }

  constructor(props) {
    super(props)
    this.state = {
      onProgress: false,
      isError: false,
      errorMessage: '',
      activeTab: 0
    }
  }

  formView() {
    return (<div>
      {/* <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.salarySlip.basicInfo.title')} />
        <Divider/>

      </Card> */}

      <TabsContainer panelClassName="md-paper md-paper--1" colored onTabChange={(i)=> this.setState({activeTab: i})} activeTabIndex={this.state.activeTab}>
        <Tabs tabId="simple-tab" mobile={false}>
          <Tab label="Karyawan Tetap"></Tab>
          <Tab label="Karyawan Tidak Tetap"></Tab>
          <Tab label="Karyawan Tidak Tetap Harian/Mingguan"></Tab>
        </Tabs>
      </TabsContainer>

      {this.karyawanTetap()}
      {this.karyawanTidakTetap()}
      {this.karyawanTidakTetapTB()}
    </div>)
  }

  karyawanTetap() {
    if(this.state.activeTab == 0) return <Card>
    <div className='md-grid'>
      {/* <Field
        label         = 'Tipe Pembayaran'
        name          = 'tipePembayaran'
        className     = 'md-cell md-cell--12'
        component     = {Searchfield}
        disabled
        options       = {[{
          id: 'Bulanan',
          name: 'Bulanan'
        }, {
          id: 'Tidak Bulanan',
          name: 'Tidak Bulanan'
        }]}
        validate      = {validation.required}
      /> */}
      <Field
        label         = 'Metode Pembuatan Slip'
        name          = 'slipMethod'
        className     = 'md-cell md-cell--12'
        component     = {Searchfield}
        // disabled
        options       = {[{
          id: 'Hanya Hitung',
          name: 'Hitung dari Daftar Slip'
        }, {
          id: 'Ambil dari master karyawan',
          name: 'Ambil dari master karyawan'
        }]}
        validate      = {validation.required}
      />
      {/* <Field
        label         = 'Komponen yang di proses'
        name          = 'komponenDihitung'
        className     = 'md-cell md-cell--12'
        component     = {Searchfield}
        // disabled
        options       = {[{
          id: 'Gaji, Tunjangan & THR',
          name: 'Gaji, Tunjangan & THR'
        }, {
          id: 'Gaji & Tunjangan',
          name: 'Gaji & Tunjangan'
        }, {
          id: 'THR',
          name: 'THR'
        }]}
        validate      = {validation.required}
      /> */}
      <Field
        label         = {counterpart('entities.salarySlip.paymentDate')}
        name          = 'paymentDate'
        className     = 'md-cell md-cell--12'
        component     = {Datepicker}
      />
    </div></Card>
  }

  karyawanTidakTetap() {
    if(this.state.activeTab == 1) return <Card>
    <div className='md-grid'>
      {/* <Field
        label         = 'Tipe Pembayaran'
        name          = 'tipePembayaran'
        className     = 'md-cell md-cell--12'
        component     = {Searchfield}
        // disabled
        options       = {[{
          id: 'Bulanan',
          name: 'Bulanan'
        }, {
          id: 'Tidak Bulanan',
          name: 'Tidak Bulanan'
        }]}
        validate      = {validation.required}
      /> */}
      <Field
        label         = 'Metode Pembuatan Slip'
        name          = 'slipMethod'
        className     = 'md-cell md-cell--12'
        component     = {Searchfield}
        // disabled
        options       = {[{
          id: 'Hanya Hitung',
          name: 'Hitung dari Daftar Slip'
        }, {
          id: 'Ambil dari master karyawan',
          name: 'Ambil dari master karyawan'
        }]}
        validate      = {validation.required}
      />
      {/* <Field
        label         = 'Komponen yang di proses'
        name          = 'komponenDihitung'
        className     = 'md-cell md-cell--12'
        component     = {Searchfield}
        // disabled
        options       = {[{
          id: 'Gaji, Tunjangan & THR',
          name: 'Gaji, Tunjangan & THR'
        }, {
          id: 'Gaji & Tunjangan',
          name: 'Gaji & Tunjangan'
        }, {
          id: 'THR',
          name: 'THR'
        }]}
        validate      = {validation.required}
      /> */}
      <Field
        label         = {counterpart('entities.salarySlip.paymentDate')}
        name          = 'paymentDate'
        className     = 'md-cell md-cell--12'
        component     = {Datepicker}
      />
    </div></Card>
  }

  karyawanTidakTetapTB() {
    if(this.state.activeTab == 2) return <Card>
    <div className='md-grid'>
      {/* <Field
        label         = 'Tipe Pembayaran'
        name          = 'tipePembayaran'
        className     = 'md-cell md-cell--12'
        component     = {Searchfield}
        // disabled
        options       = {[{
          id: 'Bulanan',
          name: 'Bulanan'
        }, {
          id: 'Tidak Bulanan',
          name: 'Tidak Bulanan'
        }]}
        validate      = {validation.required}
      /> */}
      <Field
        label         = 'Metode Pembuatan Slip'
        name          = 'slipMethod'
        className     = 'md-cell md-cell--12'
        component     = {Searchfield}
        // disabled
        options       = {[{
          id: 'Hanya Hitung',
          name: 'Hitung dari Daftar Slip'
        }, {
          id: 'Ambil dari master karyawan',
          name: 'Ambil dari master karyawan'
        }]}
        validate      = {validation.required}
      />
      {/* <Field
        label         = 'Komponen yang di proses'
        name          = 'komponenDihitung'
        className     = 'md-cell md-cell--12'
        component     = {Searchfield}
        // disabled
        options       = {[{
          id: 'Gaji, Tunjangan & THR',
          name: 'Gaji, Tunjangan & THR'
        }, {
          id: 'Gaji & Tunjangan',
          name: 'Gaji & Tunjangan'
        }, {
          id: 'THR',
          name: 'THR'
        }]}
        validate      = {validation.required}
      /> */}
      <Field
        label         = {counterpart('entities.salarySlip.paymentDate')}
        name          = 'paymentDate'
        className     = 'md-cell md-cell--12'
        component     = {Datepicker}
      />
    </div></Card>
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div>
        <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>Generate</Button>
      </div>
    )
  }

  async initData() {
    this.props.initialize(this.initialData);
  }

  async handleSave(value) {
    if(this.state.activeTab == 0) {value.employeeType = '0'} else {value.employeeType = '1'}
    if(this.state.activeTab == 2) {value.tipePembayaran = 'Tidak Bulanan'}
    value.id = this.props.periode.data.id
    var confirm = await this.context.showDialog((props, res, rej) =>({
      title: 'Konfirmasi',
      initialValue: {},
      okText: 'Yes',
      text: "Apakah anda yakin akan meng-generate slip dengan opsi ini?"
    }))

    if(confirm) {
      await this.service.api.generate(value)

      iziToast.success({
        title: 'success',
        message: 'Slip telah tergenerate'
      })
    }
  }
}
