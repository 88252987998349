import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield, Fileinput} from '../../../../../components/form';
import counterpart from 'counterpart';
import iziToast from 'izitoast';
import uploadService from '../../../Admin/upload/upload.service';
import env from 'env';

// import FormDialog from './PPh21Final.dialog.view';
import PPh21FinalService from './PPh21Final.service';
import ListView from '../../../../../components/entity/listView';

@connect((state) => ({
  ...PPh21FinalService.stateConnectSetting()(state),
  periode: state.authHalona.currentSPT,
}), PPh21FinalService.actionConnectSetting())
export default class PPh21FinalView extends ListView {
  service     = PPh21FinalService
  editDialog  = false;
  addDialog   = false;
  // FormDialog  = PPh21FinalDialog

  beforeFetch(params) {
    params.periodeId = this.props.periode.data.id
  }

  componentDidMount() {
    this.firstCall = false;
    this.rootPath = this.props.match.url;
  }

  columns=[
    // {isSortable: true,  show: true,   isSearchable:true,  label: "entities.company.title",                    value: "companyInfo.name"},
    {isSortable: true, show: true,   isSearchable:false, label: "entities.employee.otherInfo.objekPajak",          value: "taxInfo.objekPajak"},
    {isSortable: true, show: true,   isSearchable:true, label: "entities.employee.basicInfo.name",          value: "basicInfo.name"},
    {isSortable: true, show: true,   isSearchable:true, label: "entities.employee.basicInfo.npwp",          value: "basicInfo.npwp"},
    {isSortable: false, show: false,   isSearchable:true, label: "entities.employee.basicInfo.identityNo",    value: "basicInfo.identityNo"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.employee.jobInfo.employeeNo",    value: "jobInfo.employeeNo"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.employee.calculation.totalBruto",    value: "calculation.totalBruto", type: 'number'},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.employee.calculation.totalDpp",    value: "calculation.totalDpp", type: 'number'},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.employee.calculation.totalPPh",    value: "calculation.totalPPh", type: 'number'}
  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='name'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp'
          className="md-cell md-cell--12"
          component={this.ig.TextfieldMask}
          maskFormat    = "##.###.###.#-###-###"
          mask          = "_"
          length        = {15}
        />
        <this.ig.Field
          label='NIK'
          name='identityNo'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NRP'
          name='employeeNo'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />

      </this.SearchContainer>
    )
  }

  barActions = [

    // {
    //   label:'word.create',
    //   iconClassName:'mdi mdi-plus',
    //   onClick:() => this.addItem()
    // },
    // {
    //   label:'word.import',
    //   iconClassName:'mdi mdi-import',
    //   onClick:() => this.handleUpload()
    // },
    // {
    //   label:'word.delete',
    //   iconClassName:'mdi mdi-delete',
    //   onClick:() => {
    //     this.setState({showDialogConfirmDeleteSelected:true})
    //   },
    //   disabledFunc:() => this.props.table.selected.length === 0
    // },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  async handleUpload() {
    var value = await this.context.showDialog((props, res, rej) =>({
      title: 'Import Raw PPh 21',
      width: 500,
      initialValue: {},
      actions: [{
        children: 'Download Template',
        onClick: ()=> {window.open(`/images/templates/pph21_import.xlsx`,'_blank'); res(false)}
      },{
        children: 'Cancel',
        onClick: ()=> {res(false)}
      },{
        secondary: true,
        children: 'Import',
        onClick: ()=> {
          var result = props.props.formValue;
          if(!result) result = {}
          res(result)
        }
      }],
      okText: 'Import',
      text:
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.upload.file')}
            name          = 'file'
            className     = 'md-cell md-cell--12'
            component     = {Fileinput}
            // twoLines
            validate      = {validation.required}
          />
      </div>
    }));

    if(value) {
      var uploadData = new FormData();
      uploadData.append('file', value.file)
      var res = await uploadService.api.upload(uploadData);

      if(res.data.name) {
        value = {
          ...value,
          ...res.data,
          delimiter: ';'
        }

        var uploadRes = this.service.api.import(value)

        iziToast.success({
          title: 'success',
          message: counterpart('entities.upload.importSuccess')
        })
      }
    }
  }
}
