import ApiService from '../../../../services/api.service';

class ArchiveBp15Service extends ApiService {
  name  = 'archiveSpt15';
  path  = 'archive-spt/spt15';
  constructor() {
    super();
    this.init()
  }

  api = {
    download: async (d) => {
      console.log(d)
      var res = await this.http.get(`${this.apiLocation}/${this.path}-download/${d.id}`, {
        responseType: 'blob'
      })

      return res;
    },
    retry: async (d) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}-retry/${d.id}`)
      return res;
    }
  }
}


export default new ArchiveBp15Service();
