import ApiService from '../../../../../services/api.service';

class PeriodeService extends ApiService {
  name  = 'periode';
  path  = 'pph21/process/pph21Periode';
  constructor() {
    super();
    this.init()
  }
  api = {
    createBulk: async (data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-bulk`,data);
      return res;
    },

    summary: async (id)=> {
      let res = await this.http.get(`${this.apiLocation}/${this.path}-summary/${id}`);
      return res;
    },

    updateWorkflow: async (id, data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/${id}/update-workflow`, data);
      return res;
    },

    updateWorkflowBulk: async (data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/workflow-bulk`, data);
      return res;
    },

    validateAppoved: async (data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/validate-approved`, data);
      return res;
    },

    generate: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/pph21/process/pph21Periode/summary`, data, {
        responseType: 'blob'
      })
      return res;
    },

    all: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/pph21/process/pph21Periode/summary-all`, data)
      return res;
    },

    bulanan: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/pph21/process/pph21Periode/summary-bulanan`, data, {
        responseType: 'blob'
      })
      return res;
    },

    realisasi: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/pph21/process/pph21Periode/realisasi-report`, data, {
        responseType: 'blob'
      })
      return res;
    },

    slipPrintout: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/pph21/process/pph21Periode/summary-print-slip`, data, {
        responseType: 'blob'
      })
      return res;
    },

    tahunan: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/pph21/process/pph21Periode/summary-tahunan/${data.year}`, data, {
        responseType: 'blob'
      })
      return res;
    },
    sendEppt: async (data)=> {
      console.log(this.apiLocation, 'asdasd')
      let res = await this.http.post(`${this.apiLocation}/${this.path}/send-eppt`, data);
      return res;
    },
    hitungBulk: async (data)=> {
      console.log(this.apiLocation, 'asdasd')
      let res = await this.http.post(`${this.apiLocation}/${this.path}/bulk-hitung`, data);
      return res;
    },
    sendEmail: async (data)=> {
      console.log(this.apiLocation, 'asdasd')
      let res = await this.http.post(`${this.apiLocation}/email/send-bulk-email`, data);
      return res;
    },

  }
}


export default new PeriodeService();
