import ApiService from '../../../../services/api.service';

class UserRoleService extends ApiService {
  name  = 'userRole';
  path  = 'master/user-role';
  constructor() {
    super();
    this.init()
  }

  api = {
    findOneByUser: async (userId, dispatch=()=> {})=> {
      let res = await this.http.get(`${this.apiLocation}/${this.path}-by-user/${userId}`)
      dispatch({
        type: `${this.name.toUpperCase()}_FIND_ONE`,
        data: res.data
      })

      return res;
    },
  }
}


export default new UserRoleService();
