import ApiService from '../../../../../services/api.service';

class KalkulatorService extends ApiService {
  name  = 'kalkulator';
  path  = 'pph21/process/kalkulator';
  constructor() {
    super();
    this.init()
  }

  api = {
    masa: async (param)=> {
      var data = await this.http.post(`${this.apiLocation}/kalkulator/masa`, param)
      return data
    },
  }
}


export default new KalkulatorService();
